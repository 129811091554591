
import PinchZoomMixin from '~/mixins/PinchZoomMixin'
export default {
  name: 'FailableImage',
  mixins: [PinchZoomMixin],
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: [Number, String],
      default: '',
    },
    maxWidth: {
      type: [Number, String],
      default: '',
    },
    width: {
      type: [Number, String],
      default: '',
    },
    height: {
      type: [Number, String],
      default: '',
    },
    contain: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    zoomable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.zoomable) {
      this.pinchZoom(this.$refs.image.$el)
    }
  },
}
