
import { pathOr } from 'ramda'
import { mapActions } from 'vuex'
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'
import CallResetOnDialogOpenMixin from '../../../mixins/CallResetOnDialogOpenMixin'
import MapsHelperMixin from '../../../mixins/MapsHelperMixin'
import MapField from './MapField'

export default {
  name: 'SearchPlaceDialog',
  components: { MapField },
  mixins: [
    ResponsiveScreenHelperMixin,
    CallResetOnDialogOpenMixin,
    MapsHelperMixin,
  ],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    latitude: {
      type: Number,
      default: 0,
    },
    longitude: {
      type: Number,
      default: 0,
    },
    meta: {
      type: Object,
      default() {
        return {}
      },
    },
    additionalPlaces: {
      type: Array,
      default() {
        return []
      },
    },
    areasOfOperation: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      formData: {
        longitude: this.longitude,
        latitude: this.latitude,
        meta: this.meta,
      },
    }
  },
  computed: {
    formatted_address() {
      return pathOr('', ['formatted_address'], this.formData.meta)
    },
  },
  watch: {
    latitude: {
      handler: 'latLngChanged',
    },
    longitude: {
      handler: 'latLngChanged',
    },
    meta: {
      handler(meta) {
        this.formData.meta = meta
      },
    },
  },
  methods: {
    ...mapActions('app_snackbars', ['showError']),
    onLocationChange({ longitude, latitude, meta, is_out_of_area }) {
      if (is_out_of_area) {
        this.showError(this.$t('placeSelection.outOfAreaError'))
      } else {
        this.formData = {
          ...this.formData,
          longitude,
          latitude,
          meta,
          is_out_of_area,
        }
      }
    },
    onLocationApply() {
      if (this.checkSelectedLocationIsInArea()) {
        const { longitude, latitude, meta, is_out_of_area } = this.formData
        this.$emit('input', { longitude, latitude, meta, is_out_of_area })
        this.onClose()
      } else {
        this.showError(this.$t('placeSelection.outOfAreaError'))
      }
    },
    checkSelectedLocationIsInArea() {
      return !this.checkPlaceIsOutOfArea(
        new window.google.maps.LatLng({
          lat: parseFloat(this.formData.latitude),
          lng: parseFloat(this.formData.longitude),
        })
      )
    },
    resetForm() {
      this.formData = {
        longitude: this.longitude,
        latitude: this.latitude,
        meta: this.meta,
      }
    },
    onClose() {
      this.$emit('close')
    },
    latLngChanged() {
      this.resetForm()
    },
  },
}
