import { pathOr } from 'ramda'

export default {
  data() {
    return {
      showPriceDetails: false,
    }
  },
  computed: {
    details() {
      return this.lessonDetails || this.courseDetails
    },
    cancellationPolicyPath() {
      return ['coach', 'cancellation_policy']
    },
    cancellationPolicy() {
      return pathOr(
        { fee: 0, time: 0 },
        this.cancellationPolicyPath,
        this.details
      )
    },
    coachAdminFee() {
      return pathOr('', ['coach', 'admin_fee'], this.details)
    },
    hasCancellationPolicy() {
      return !!this.cancellationPolicy.time
    },
  },
  methods: {
    openPriceDetails() {
      this.showPriceDetails = true
    },
  },
}
