import { mapActions, mapState } from 'vuex'

export default {
  head() {
    return {
      script: [
        {
          once: true,
          skip: this.datatransLibLoaded,
          src: `${this.$config.datatransBaseURL}/upp/payment/js/datatrans-2.0.0.js`,
          callback: () => this.setDatatransLibLoaded(true),
        },
      ],
    }
  },
  computed: {
    ...mapState(['datatransLibLoaded']),
  },
  methods: {
    ...mapActions(['setDatatransLibLoaded']),
    openLightbox({ transactionId, onError, onClose, onLoaded, onOpened }) {
      window.Datatrans.startPayment({
        transactionId,
        opened(...args) {
          onOpened && onOpened(...args)
        },
        loaded(...args) {
          onLoaded && onLoaded(...args)
        },
        closed(...args) {
          onClose && onClose(...args)
        },
        error(...args) {
          onError && onError(...args)
        },
      })
    },
  },
}
