
import { mapGetters, mapActions } from 'vuex'
import { PushNotifications } from '@capacitor/push-notifications'
import Snackbar from '../components/shared/Snackbar'
import ResponsiveScreenHelperMixin from '../mixins/ResponsiveScreenHelperMixin'
import NativeCapabilitiesControllerMixin from '../mixins/NativeCapabilitiesControllerMixin'
import FailableImage from '~/components/shared/FailableImage.vue'

export default {
  components: { Snackbar, FailableImage },
  mixins: [NativeCapabilitiesControllerMixin, ResponsiveScreenHelperMixin],
  computed: {
    ...mapGetters('profile', ['profile_avatar', 'isCoach', 'userId']),
    ...mapGetters('coach_notifications', ['hasNotifications']),
  },
  mounted() {
    if (!this.isNativePlatform) {
      return
    }

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()
      }
    })
    PushNotifications.addListener('registration', (token) => {
      this.sendPushNotificationsToken(token.value)
    })

    PushNotifications.addListener('registrationError', (error) => {
      console.log('Error on registration: ' + JSON.stringify(error))
    })

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        if (this.isAndroidCurrentPlatform) {
          alert(notification.body)
        }
      }
    )
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        const data = notification.notification.data
        if (
          data.event === 'package' ||
          (data.event === 'slots' && JSON.parse(data.slot_ids).length > 1)
        ) {
          this.$router.push({
            name: 'coachSchedule',
          })
        } else if (data.event) {
          this.$router.push({
            name: 'coachSchedule',
            query: {
              slot:
                data.event === 'slots'
                  ? JSON.parse(data.slot_ids)[0]
                  : data.slot_id,
            },
          })
        }
      }
    )
  },
  beforeDestroy() {
    if (this.isNativePlatform) {
      PushNotifications.removeAllListeners()
    }
  },
  methods: {
    ...mapActions('push-notifications', ['sendPushNotificationsToken']),
    toSchedule() {
      this.$router.push({ name: 'coachSchedule' })
    },
    toProfile() {
      this.$router.push({ name: 'coachProfile' })
    },
    toStudents() {
      this.$router.push({ name: 'coachStudents' })
    },
    toNotifications() {
      this.$router.push({ name: 'coachNotifications' })
    },
  },
}
