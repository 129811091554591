
import Snackbar from '../components/shared/Snackbar'
export default {
  components: { Snackbar },
  layout: 'notFoundLayout',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: this.$t('errorPage.404Error'),
      otherError: this.$t('errorPage.anotherError'),
    }
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
}
