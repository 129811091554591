import { where, tap, pathOr } from 'ramda'
import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import {
  TOPIC_GRADE_GOOD,
  TOPIC_GRADE_INSUFFICIENT,
  TOPIC_GRADE_DISCUSSED,
  TOPIC_GRADE_READY,
} from '~/utils/constants'
import {
  groupSubjectTotalsForCharts,
  groupTopicsToCurriculum,
} from '~/utils/commonutils'
import { Curriculum } from '~/types/__generated__/CurriculumRoute'
import { BookingCalendar } from '~/types/__generated__/BookingCalendarRoute'
import UpdateTemplateTopic = Curriculum.CurriculumCoachesTopicsPartialUpdate
import DeleteTemplateTopic = Curriculum.CurriculumCoachesTopicsDelete
import CreateTemplateTopic = Curriculum.CurriculumCoachesTopicsCreate
import GetLessonsRatingsList = BookingCalendar.BookingCalendarLessonsRatingsList

export const TOPIC_GRADE_KEYS: Record<string, string> = {
  [TOPIC_GRADE_DISCUSSED]: 'discussed',
  [TOPIC_GRADE_INSUFFICIENT]: 'insufficient',
  [TOPIC_GRADE_GOOD]: 'good',
  [TOPIC_GRADE_READY]: 'ready',
}

// Coach role actions and state
export const state = () => ({
  curriculum_template: [],
})

export const getters = getterTree(state, {
  userId(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/userId']
  },
})
export const mutations = mutationTree(state, {
  setCurriculumTemplate(_state, template) {
    _state.curriculum_template = template
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    updateTemplateTopic(
      { getters },
      topic
    ): Promise<UpdateTemplateTopic.ResponseBody> {
      return this.$axios.patch(
        `/api/curriculum/coaches/${getters.userId}/topics/${topic.id}/`,
        topic
      )
    },
    deleteTemplateTopic(
      { getters },
      topic
    ): Promise<DeleteTemplateTopic.ResponseBody> {
      return this.$axios.delete(
        `/api/curriculum/coaches/${getters.userId}/topics/${topic.id}/`
      )
    },
    createTemplateTopic(
      { getters },
      topic
    ): Promise<CreateTemplateTopic.ResponseBody> {
      return this.$axios.post(
        `/api/curriculum/coaches/${getters.userId}/topics/`,
        topic
      )
    },
    loadSubjects() {
      return this.$axios
        .get(`/api/curriculum/subjects/`)
        .then(pathOr([], ['data']))
    },
    loadCurriculumTemplate({ commit, getters }) {
      return this.$axios
        .get(`/api/curriculum/coaches/${getters.userId}/topics/`)
        .then(pathOr([], ['data']))
        .then(groupTopicsToCurriculum)
        .then(
          tap((template) => {
            commit('setCurriculumTemplate', template)
          })
        )
    },
    loadLessonPlan(_, lessonId): Promise<GetLessonsRatingsList.ResponseBody> {
      return this.$axios
        .get(`/api/booking-calendar/lessons/${lessonId}/ratings/`)
        .then(pathOr([], ['data']))
    },
    addLessonTopics(_, { lesson_id, topics }) {
      return this.$axios.post(
        `/api/booking-calendar/lessons/${lesson_id}/ratings/`,
        { topics }
      )
    },
    updateLessonTopic(_, { lesson_id, topic }) {
      return this.$axios.patch(
        `/api/booking-calendar/lessons/${lesson_id}/ratings/${topic.id}/`,
        topic
      )
    },
    deleteLessonTopic(_, { lesson_id, topic_id }) {
      return this.$axios.delete(
        `/api/booking-calendar/lessons/${lesson_id}/ratings/${topic_id}/`
      )
    },
    copyLastLessonsRatings(_, lessonId) {
      return this.$axios.post(
        `/api/booking-calendar/lessons/${lessonId}/copy-last-ratings/`
      )
    },
    async getCurriculumForStudentWithCoachTopics(
      _,
      studentId: number
    ): Promise<any> {
      // get student's curriculum
      // get coach's template
      // add coach's template topics which are not present in student's curriculum (
      // a case where none student's topics parent_id match to template topic id
      // build curriculum
      const { student_curriculum, coach_curriculum } = this.app.$accessor
      const [studentTopics, subjectsSummary, coachTemplateCurriculum] =
        await Promise.all([
          student_curriculum.getStudentCurriculum(studentId),
          student_curriculum
            .getStudentSubjectsSummary(studentId)
            .then(groupSubjectTotalsForCharts),
          coach_curriculum.loadCurriculumTemplate(),
        ])
      const knownTemplateTopics = studentTopics
        .map((topic) => topic.parent)
        .reduce((acc, id) => {
          acc[id as number] = true
          return acc
        }, {} as Record<number, boolean>)
      const coachTemplateTopics = coachTemplateCurriculum
        .flat()
        .reduce((acc, subject) => {
          return acc.concat(
            subject.topics.map((topic) => ({
              ...topic,
              is_default_category: true,
              category_name: '',
            }))
          )
        }, [] as Unpacked<typeof coachTemplateCurriculum>['topics'][])
      // TODO: add types after swagger fix
      // @ts-ignore
      const { name } = studentTopics.find(
        // @ts-ignore
        ({ category }) => category.is_default
        // @ts-ignore
      ).category

      const newTopics = coachTemplateTopics
        .filter(
          where({
            id: (id: number) => !!id && !knownTemplateTopics[id],
          })
        )
        .map((topic) => ({
          ...topic,
          category: {
            name,
            is_default: true,
          },
        }))
      return groupTopicsToCurriculum(studentTopics.concat(...newTopics)).map(
        (category) =>
          category.map((subject) => {
            return {
              ...subject,
              // @ts-ignore
              category_name: subject.topics[0].category?.name ?? name,
              is_default_category:
                // @ts-ignore
                subject.topics[0].category?.is_default ?? true,
              subject_info: subjectsSummary[subject.id as number],
            }
          })
      )
    },
    updatePriority({ dispatch, getters }, topics) {
      return this.$axios
        .patch(
          `/api/curriculum/coaches/${getters.userId}/topics/update-priority/`,
          topics
        )
        .then((_) => dispatch('loadCurriculumTemplate'))
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
