
import Snackbar from '../components/shared/Snackbar'
import ResponsiveScreenHelperMixin from '../mixins/ResponsiveScreenHelperMixin'
import NativeCapabilitiesControllerMixin from '../mixins/NativeCapabilitiesControllerMixin'
export default {
  components: { Snackbar },
  mixins: [NativeCapabilitiesControllerMixin, ResponsiveScreenHelperMixin],
  methods: {
    toLogin() {
      this.$router.push({ name: 'universalLogin' })
    },
  },
}
