
import { debounce } from 'lodash'
import PlacesAutocompleteMixin from '../../mixins/PlacesAutocompleteMixin'
import MapsHelperMixin from '../../mixins/MapsHelperMixin'
import ResponsiveScreenHelperMixin from '../../mixins/ResponsiveScreenHelperMixin'
export default {
  name: 'LocationAutosuggestInput',
  mixins: [
    MapsHelperMixin,
    PlacesAutocompleteMixin,
    ResponsiveScreenHelperMixin,
  ],
  data() {
    return {
      loading: false,
      suggestedPlaces: [],
      search: null,
      selectedPlaceId: null,
    }
  },
  watch: {
    search(val) {
      val && this.querySelections(val)
    },
  },
  methods: {
    querySelections: debounce(async function (v) {
      this.loading = true
      let userLatLng
      try {
        userLatLng = await this.requestCurrentLocation()
      } catch (e) {}

      try {
        this.suggestedPlaces = await this.suggestPlaces3(v, userLatLng)
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }, 1000),
    onPlaceSelected(placeId) {
      this.$refs.autocomplete.blur()
      if (placeId) {
        this.getGPlaceDetails(placeId).then((place) => {
          this.$emit('select', place)
        })
      }
    },
  },
}
