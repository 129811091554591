
import {
  LESSON_STATUS_STUDENT_CANCELLED,
  LESSON_STATUS_COACH_CANCELLED,
  LESSON_STATUS_BOTH_APPROVED,
  LESSON_STATUS_COACH_APPROVED,
  LESSON_STATUS_STUDENT_APPROVED,
  LESSON_STATUS_COMPLETED,
  LESSON_STATUS_DID_NOT_TAKE_PLACE,
} from '../../../../utils/constants'

export default {
  name: 'LessonStatusWidget',
  props: {
    status: {
      type: Number,
      default: 0,
    },
    isPast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LESSON_STATUS_STUDENT_CANCELLED,
      LESSON_STATUS_COACH_CANCELLED,
      LESSON_STATUS_BOTH_APPROVED,
      LESSON_STATUS_COACH_APPROVED,
      LESSON_STATUS_STUDENT_APPROVED,
      LESSON_STATUS_COMPLETED,
      LESSON_STATUS_DID_NOT_TAKE_PLACE,
    }
  },
  computed: {
    isReviewPending() {
      return this.status === this.LESSON_STATUS_BOTH_APPROVED && this.isPast
    },
  },
}
