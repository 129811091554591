
import DialogWrapper from '../../../shared/DialogWrapper'

export default {
  name: 'StudentHasNoPaymentOptionsDialog',
  components: { DialogWrapper },
  props: {},
  methods: {
    onConfirm() {
      this.$emit('confirm')
      this.$emit('close')
    },
    onCancel() {
      this.$emit('cancel')
      this.$emit('close')
    },
  },
}
