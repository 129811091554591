
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'
import FormHelperMixin from '../../../mixins/FormHelperMixin'
import MapField from './MapField'
import SearchPlaceDialog from './SearchPlaceDialog'
import LocationLink from './LocationLink'

export default {
  name: 'PlaceForm',
  components: { LocationLink, SearchPlaceDialog, MapField },
  mixins: [ResponsiveScreenHelperMixin, FormHelperMixin],
  props: {
    isInitial: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    latitude: {
      type: Number,
      default: 0,
    },
    longitude: {
      type: Number,
      default: 0,
    },
    meta: {
      type: Object,
      default() {
        return {}
      },
    },
    additionalPlaces: {
      type: Array,
      default() {
        return []
      },
    },
    areasOfOperation: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      formRules: {
        name: [
          this.requiredCheck(
            this.$t('coachProfileAddOrEditPlace.placeNameLabel')
          ),
          this.checkPropHasErrorSet(['name']),
        ],
      },
      initialOverlayPassed: true,
      editMode: false,
      placeSearchSubDialog: false,
    }
  },
  computed: {
    showOverlay() {
      return this.fullscreen && !this.initialOverlayPassed
    },
  },
  watch: {
    isInitial: {
      handler(isInitial) {
        if (isInitial) {
          this.initialOverlayPassed = !isInitial
          this.$nextTick(() => {
            this.placeSearchSubDialog = true
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    onOverlayClick() {
      this.placeSearchSubDialog = true
    },
    onLocationChange(place) {
      const { longitude, latitude, meta, is_out_of_area } = place
      if (is_out_of_area) {
        this.$emit('out-of-area', place)
      } else {
        this.initialOverlayPassed = true
        this.$emit('update:latitude', latitude)
        this.$emit('update:longitude', longitude)
        this.$emit('update:meta', meta)
      }
    },
    validate() {
      return this.$refs.form.validate()
    },
    setFormErrors(errors) {
      this.formErrors = errors
    },
    resetValidation() {
      this.initialOverlayPassed = !this.isInitial
      this.$refs.form.resetValidation()
    },
  },
}
