
import { mapActions, mapState } from 'vuex'
import ResponsiveScreenHelperMixin from '../../../../mixins/ResponsiveScreenHelperMixin'
import SectionTitle from '../../../shared/SectionTitle'
import EditStudentDetails from '../../student_profile/EditStudentDetails'
import FormHelperMixin from '../../../../mixins/FormHelperMixin'
import { studentProfileComplete } from '../../../../utils/commonutils'
import PackagePurchaseActionButton from './payment/PackagePurchaseActionButton'

export default {
  name: 'PackageDetailsDialog',
  components: {
    EditStudentDetails,
    PackagePurchaseActionButton,
    SectionTitle,
  },
  mixins: [ResponsiveScreenHelperMixin, FormHelperMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    packageItem: {
      type: Object,
      default: () => ({}),
    },
    lessonsCoachInfo: {
      type: Object,
      default: () => ({}),
    },
    guestMode: {
      type: Boolean,
      default: false,
    },
    initialPaymentMethod: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      busy: false,
      formData: {},
      isEditPackageOpen: false,
      finishSignup: false,
      signupRequired: false,
      signupChecked: false,
      ready: false,
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
  },
  mounted() {
    this.signupRequired =
      !this.$auth.loggedIn || !studentProfileComplete(this.profile)
    this.signupChecked = true
  },
  methods: {
    ...mapActions('student_explore', ['buyLoyaltyPackage']),
    ...mapActions('app_snackbars', [
      'showSnackbar',
      'showError',
      'showWarning',
      'showSuccessNotification',
    ]),
    newMethodCreation(data) {
      this.$emit('newMethodCreation', data)
    },
    newMethodCreationCancelled() {
      this.$emit('newMethodCreationCancelled')
    },
    async onBuy({ payment_method }) {
      this.busy = true
      try {
        await this.buyLoyaltyPackage({
          package_id: this.packageItem.id,
          payment_method,
        })
        this.showSuccessNotification(
          'studentNotifications.packagePurchaseSuccessMessage'
        )
        this.busy = false
        this.$emit('close')
        this.$emit('success')
      } catch (e) {
        this.showRequestErrorMessage(e)
        this.busy = false
      }
    },
    handleAppleGooglePaymentDone() {
      this.showSuccessNotification(
        'studentNotifications.packagePurchaseSuccessMessage'
      )
      this.$emit('close')
      this.$emit('success')
    },
    handleAppleGooglePaymentError(e) {
      this.showRequestErrorMessage(e)
    },
    onSignupStart() {
      if (!this.$auth.loggedIn) {
        this.prebookPackage(this.packageItem)
        return
      }
      this.finishSignup = true
    },
    onSignupFinished() {
      this.signupRequired = false
    },
    prebookPackage(selectionData) {
      this.$emit('prebook', selectionData)
    },
  },
}
