export default {
  computed: {
    rating() {
      if (!this.value) {
        return null
      }
      const [integer, decimal = 0] = this.value.toString().split('.')
      switch (true) {
        case +decimal < 3:
          return +integer
        case +decimal > 7:
          return +integer + 1
        default:
          return +integer + 0.5
      }
    },
  },
}
