
import SectionTitle from './SectionTitle'
export default {
  name: 'TextPrompt',
  components: { SectionTitle },
  props: {
    promptTitle: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      promptText: 'https://',
    }
  },
  watch: {
    value: {
      handler(val) {
        this.promptText = val || 'https://'
      },
      immediate: true,
    },
  },
  methods: {
    onSave() {
      this.$emit('save', this.promptText)
      this.$emit('close')
    },
  },
}
