import { pathOr } from "ramda";
import { getSmallestImageURL } from "~/utils/commonutils";

export default {
   computed: {
    gearBox() {
      return this.car.gearbox === 'AT'
        ? this.$t('coachProfileCars.AutomaticValue')
        : this.$t('coachProfileCars.ManualValue')
    },
    carBrand() {
      return pathOr('', ['model', 'brand'], this.car)
    },
    carModel() {
      return pathOr('', ['model', 'model'], this.car)
    },
    carColor() {
      return pathOr('', ['color'], this.car)
    },
    carHorsepower() {
      return pathOr('', ['horsepower'], this.car)
    },
    picture() {
      return getSmallestImageURL(this.car.picture)
    },
    tagColor() {
      return pathOr('', ['color_tag'], this.car)
    },
  },
}
