
import { sanitize } from 'dompurify'
import { pathOr, isNil } from 'ramda'
import {
  TOPIC_GRADE_ADD,
  TOPIC_GRADE_DISCUSSED,
  TOPIC_GRADE_GOOD,
  TOPIC_GRADE_INSUFFICIENT,
  TOPIC_GRADE_READY,
  TOPIC_GRADE_TITLES,
} from '../../../utils/constants'
import TopicRating from '../../shared/TopicRating'
import CardWrapper from '../../shared/CardWrapper'
import RateTopicDialog from './RateTopicDialog'
import MenuOrBottomSheet from '~/components/shared/MenuOrBottomSheet.vue'
import ResponsiveScreenHelperMixin from '~/mixins/ResponsiveScreenHelperMixin'
export default {
  name: 'LessonPlanItem',
  components: { CardWrapper, RateTopicDialog, TopicRating, MenuOrBottomSheet },
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    topic: {
      type: Object,
      default() {
        return {}
      },
    },
    previousRatings: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      showRatingSelection: false,
      formData: {},
      showDescription: false,
    }
  },
  computed: {
    rating() {
      const rating = pathOr(null, ['value'], this.topic)
      if (
        [
          TOPIC_GRADE_DISCUSSED,
          TOPIC_GRADE_INSUFFICIENT,
          TOPIC_GRADE_GOOD,
          TOPIC_GRADE_READY,
        ].includes(rating)
      ) {
        return rating
      }
      if (this.editable) {
        return TOPIC_GRADE_ADD
      } else {
        // null
        return null
      }
    },
    topicName() {
      return pathOr(null, ['topic', 'title'], this.topic)
    },
    topicDescription() {
      return sanitize(pathOr(null, ['topic', 'description'], this.topic))
    },
    ratingTitle() {
      if (isNil(this.rating) || this.rating === TOPIC_GRADE_ADD) {
        return this.$t('lessonPlan.topicNotRated')
      }
      return this.$t(TOPIC_GRADE_TITLES[this.rating])
    },
    ratingButtonId() {
      return `topic_${this.topic.id}`
    },
    ratingSelector() {
      return `#${this.ratingButtonId}`
    },
    disabledTopic() {
      return !this.editable && this.rating === null
    },
  },
  watch: {
    topic: {
      handler(data) {
        this.formData = data
      },
      immediate: true,
    },
  },
  methods: {
    onRatingClick() {
      if (this.editable) {
        this.showRatingSelection = true
      }
    },
    updateRating(rating) {
      this.formData.value = rating
      this.syncTopicChanges()
    },
    updateTopicData() {
      this.$emit('update', this.formData)
    },
    syncTopicChanges() {
      this.updateTopicData()
    },
    deleteTopic() {
      this.$emit('delete', this.formData.id)
    },
  },
}
