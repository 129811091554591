
import { mapActions, mapState } from 'vuex'
import { evolve, toLower, path } from 'ramda'
import { isValid, parse, isFuture } from 'date-fns'
import AvatarPhoto from '../../shared/AvatarPhoto'
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'
import FormHelperMixin from '../../../mixins/FormHelperMixin'
import CallResetOnDialogOpenMixin from '../../../mixins/CallResetOnDialogOpenMixin'
import MediaFieldHelperMixin from '../../../mixins/MediaFieldHelperMixin'
import i18nHelperMixin from '../../../mixins/i18nHelperMixin'
import goNextFieldOnEnterMixin from '../../../mixins/goNextFieldOnEnterMixin'
import FileInputArea from '../../shared/FileInputArea'
import StudentPermitImageDialog from '../../shared/student_permit/StudentPermitImageDialog'

export default {
  name: 'EditStudentDetails',
  components: { StudentPermitImageDialog, FileInputArea, AvatarPhoto },
  mixins: [
    ResponsiveScreenHelperMixin,
    FormHelperMixin,
    CallResetOnDialogOpenMixin,
    MediaFieldHelperMixin,
    i18nHelperMixin,
    goNextFieldOnEnterMixin,
  ],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    finishSignup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateMask: [
        /[0-3]/,
        /\d/,
        '.',
        /[0-1]/,
        /\d/,
        '.',
        /[1-2]/,
        /[90]/,
        /\d/,
        /\d/,
      ],
      uploadingPermit: false,
      birthdayPicker: false,
      showPermitImage: false,
      activePicker: null,
      showPwd: false,
      formData: {
        student: {
          learners_permit: null,
          birthday: '',
        },
      },
      formRules: {
        first_name: [
          this.requiredCheck(this.$t('studentEditProfile.firstNameLabel')),
          this.checkPropHasErrorSet(['first_name']),
        ],
        last_name: [
          this.requiredCheck(this.$t('studentEditProfile.lastNamelabel')),
          this.checkPropHasErrorSet(['last_name']),
        ],
        email: [
          this.requiredCheck(this.$t('studentEditProfile.emailLabel')),
          this.checkPropHasErrorSet(['email']),
        ],
        password: [
          this.requiredCheck(this.$t('studentEditProfile.passwordLabel')),
          this.minLengthCheck(8),
          this.checkPropHasErrorSet(['password']),
        ],
        student: {
          zip_code: [this.checkPropHasErrorSet(['student', 'zip_code'])],
          city: [this.checkPropHasErrorSet(['student', 'city'])],
          street: [this.checkPropHasErrorSet(['student', 'street'])],
          birthday: [
            this.requiredCheck(this.$t('studentEditProfile.birthDateLabel')),
            this.checkPropHasErrorSet(['student', 'birthday']),
            (date) =>
              isValid(parse(date, 'dd.MM.yyyy', new Date())) ||
              this.$t('studentEditProfile.invalidDate'),
            (date) =>
              !isFuture(parse(date, 'dd.MM.yyyy', new Date())) ||
              this.$t('studentEditProfile.invalidDate'),
          ],
        },
      },
      mediaFields: ['avatar', 'learners_permit'],
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    dateInputValue: {
      get() {
        return this.formData.student.birthday?.split('-').reverse().join('.')
      },
      set(val) {
        this.formData.student.birthday = val.split('.').reverse().join('-')
      },
    },
    supportedStudentPermitFileTypes() {
      return ['image/png', 'image/jpg', 'image/jpeg'].join(',')
    },
    learnerPermit() {
      return path(['student', 'learners_permit'], this.formData)
    },
  },
  watch: {
    profile: {
      handler() {
        this.resetForm()
      },
    },
    birthdayPicker(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    dialog(v) {
      v && this.$refs.firstField?.focus()
    },
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    ...mapActions('profile', ['getProfile', 'updateProfile']),
    ...mapActions('media', ['addImageToBucket']),
    onDelete(event) {
      // workaround for backspace
      const { value } = event.target
      const char = value[value.length - 1]
      if (isNaN(parseInt(char)) || char === ' ') {
        event.target.value = value.substring(0, value.length - 1)
      }
    },
    async studentPermitAdded(file) {
      try {
        this.uploadingPermit = true
        this.formData.student.learners_permit = await this.addImageToBucket(
          file
        )
      } catch (e) {
        this.showRequestErrorMessage(e)
      } finally {
        this.uploadingPermit = false
      }
    },
    avatarAdded(file) {
      this.formData.student.avatar = file
    },
    deleteAvatar() {
      this.formData.student.avatar = null
    },
    async onSave() {
      try {
        if (this.$refs.form.validate()) {
          await this.updateProfile(
            evolve(
              { email: toLower, student: this.prepareMedia },
              this.formData
            )
          )
          this.$emit('success')
          this.$emit('close')
        }
      } catch (e) {
        if (this.responseErrorIsClientValidation(e)) {
          this.formErrors = this.getResponseValidationErrorData(e)
          this.$refs.form.validate()
        }
        this.showRequestErrorMessage(e)
      }
    },
    resetForm() {
      const formData = JSON.parse(JSON.stringify(this.profile))
      if (!formData.student) {
        formData.student = {}
      }
      this.formData = formData
      this.resetValidation()
    },
    onCancel() {
      this.$emit('cancel')
      this.$emit('close')
    },
    removeStudentPermit() {
      this.formData.student.learners_permit = null
    },
  },
}
