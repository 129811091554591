
import DialogWrapper from '../../../shared/DialogWrapper'
import ResponsiveScreenHelperMixin from '../../../../mixins/ResponsiveScreenHelperMixin'

export default {
  name: 'DeleteCarDialog',
  components: { DialogWrapper },
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    carName: {
      type: String,
      default: '',
    },
  },
  methods: {
    onDelete() {
      this.$emit('delete')
    },
  },
}
