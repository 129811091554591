
import { path } from 'ramda'
import {
  getLargeImageURL,
  getMediumImageURL,
} from '../../../../utils/commonutils'
import ResponsiveScreenHelperMixin from '../../../../mixins/ResponsiveScreenHelperMixin'
import FailableImage from '~/components/shared/FailableImage.vue'

export default {
  name: 'LessonMedia',
  components: { FailableImage },
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    details: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      dialog: false,
      mediaItemIndex: 0,
    }
  },

  computed: {
    images() {
      return this.mediaItems.filter(({ is_image }) => is_image)
    },
    mediaItems() {
      const items = []
      const pictures = path(['coach', 'additional_pictures'])(this.details)
      const video = path(['coach', 'video'])(this.details)
      if (video) {
        items.push({ is_video: true, src: video })
      }
      if (pictures?.length) {
        const urls = pictures.map(getMediumImageURL)
        items.push(...urls.map((src) => ({ is_image: true, src })))
      }
      return items
    },
    videoIndexCorrection() {
      return +!!this.mediaItems[0]?.is_video
    },
    largeCurrentSrc() {
      return getLargeImageURL(
        this.details?.coach?.additional_pictures[
          this.mediaItemIndex - this.videoIndexCorrection
        ]
      )
    },
  },
  methods: {
    handleImageClick(index) {
      this.mediaItemIndex = index
      this.dialog = true
    },
  },
}
