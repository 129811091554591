
import SectionTitle from './SectionTitle'

export default {
  name: 'ConfirmPrebookLessonDialog',
  components: { SectionTitle },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    confirmationTitleText: {
      type: String,
      default: '',
    },
    confirmationSubtitleText: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 400,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    noIconPt: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconColor() {
      if (this.icon === 'mdi-information') {
        return 'blue darken-1'
      } else if (this.icon === 'mdi-cash-multiple') {
        return 'green darken-1'
      } else {
        return 'orange lighten-2'
      }
    },
  },
}
