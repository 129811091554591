
import { mapActions, mapState } from 'vuex'
import { isArray } from 'ramda-adjunct'
import { keys } from 'ramda'
import { MESSAGES } from '../../utils/constants'
import SectionTitle from './SectionTitle'
export default {
  name: 'Snackbar',
  components: { SectionTitle },
  data() {
    return {
      snackbar: false,
      color: undefined,
      text: '',
      showSuccessNotification: false,
      successNotification: '',
      showInfoNotification: false,
      infoNotification: '',
      showWarningNotification: false,
      warningNotification: '',
      showErrorNotification: false,
      errorNotification: '',
      timeout: 2000,
    }
  },
  computed: {
    ...mapState('app_snackbars', ['isSnackbarLoaded']),
  },
  mounted() {
    this.setSnackbarLoaded(true)
    this.unsubscribe = this.$store.subscribeAction((action) => {
      if (action.type === 'app_snackbars/showSnackbar' && action.payload) {
        if (isArray(action.payload)) {
          this.text = action.payload[0]
          this.color = 'error'
        } else if (keys(MESSAGES.success).includes(action.payload)) {
          this.color = 'success'
          this.text = this.$t(`snackbars.${MESSAGES.success[action.payload]}`)
        } else {
          this.color = 'error'
          this.text = this.$t(`snackbars.${MESSAGES.errors[action.payload]}`)
        }
        this.snackbar = true
      } else if (action.type === 'app_snackbars/showError' && action.payload) {
        this.color = 'error'
        this.text = action.payload
        this.snackbar = true
      } else if (
        action.type === 'app_snackbars/showWarning' &&
        action.payload
      ) {
        this.color = 'warning'
        this.text = action.payload
        this.snackbar = true
      } else if (action.type === 'app_snackbars/showSuccessNotification') {
        this.successNotification = action.payload
        this.showSuccessNotification = true
      } else if (action.type === 'app_snackbars/showInfoNotification') {
        this.infoNotification = action.payload
        this.showInfoNotification = true
      } else if (action.type === 'app_snackbars/showWarningNotification') {
        this.warningNotification = action.payload
        this.showWarningNotification = true
      } else if (action.type === 'app_snackbars/showErrorNotification') {
        this.errorNotification = action.payload
        this.showErrorNotification = true
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  methods: {
    ...mapActions('app_snackbars', ['setSnackbarLoaded']),
  },
}
