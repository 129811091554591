
// TODO migrate to slots
export default {
  name: 'SectionTitle',
  props: {
    text: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'h6-bold',
    },
  },
}
