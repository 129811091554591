import { pathOr, pipe, filter, __, includes, whereEq } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import { mapGetters } from 'vuex'
import {
  PAYMENT_METHOD_CASH,
  ELECTRONIC_PAYMENT_METHODS,
  PAYMENT_METHOD_GOOGLE_PAY,
  GOOGLE_PAYMENT_METHOD_STUB_ID,
  APPLE_PAYMENT_METHOD_STUB_ID,
  PAYMENT_METHOD_APPLE_PAY,
} from '../utils/constants'

export default {
  computed: {
    ...mapGetters(['isNativePlatform']),
    listOfPaymentMethods() {
      return this.coachPaymentMethods || pathOr([], ['payment_methods'], this)
    },
    listOfDisabledPaymentMethods() {
      return (
        this.adminDisabledPaymentMethods ||
        pathOr([], ['disabled_payment_methods'], this)
      )
    },
    electronicMethodsRestrictedByAdmin() {
      return pipe(
        filter(includes(__, ELECTRONIC_PAYMENT_METHODS)),
        isNotEmpty
      )(this.listOfDisabledPaymentMethods)
    },
    cashMethodRestrictedByAdmin() {
      return this.listOfDisabledPaymentMethods.includes(PAYMENT_METHOD_CASH)
    },
    allPaymentOptions() {
      const options = []

      const electronicOptions = this.studentPaymentOptions.map((option) => ({
        ...option,
        type: option.payment_method,
        enabled:
          this.coachAllowsInAppWalletMethods &&
          includes(option.payment_method, this.lessonPaymentTypes),
      }))

      if (!this.isNativePlatform) {
        options.push({
          type: PAYMENT_METHOD_GOOGLE_PAY,
          id: GOOGLE_PAYMENT_METHOD_STUB_ID,
          payment_method: PAYMENT_METHOD_GOOGLE_PAY,
          enabled:
            this.browserSupportsGooglePay() && this.coachAllowsExternalWallets,
        })

        options.push({
          type: PAYMENT_METHOD_APPLE_PAY,
          id: APPLE_PAYMENT_METHOD_STUB_ID,
          payment_method: PAYMENT_METHOD_APPLE_PAY,
          enabled:
            this.browserSupportsApplePay() && this.coachAllowsExternalWallets,
        })
      }

      return electronicOptions.concat(options)
    },
  },
  methods: {
    initialPrebookRefnoSetup() {
      this.prebookedTransactionRefno = JSON.parse(localStorage.getItem('refno'))
      localStorage.removeItem('refno')
    },
    initialPaymentMethodSetup() {
      this.getMethods().then(() => {
        const paymentMethodDetails = this.availablePaymentOptions.find(
          whereEq({
            payment_method: this.initialPaymentMethod,
          })
        )
        if (!this.initialPaymentMethod) {
          this.setLastPaymentMethod()
        } else if (paymentMethodDetails) {
          this.payment_method_id = this.initialPaymentMethod
        }
      })
    },
  },
}
