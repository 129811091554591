import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
export const state = () => ({
  isSnackbarLoaded: false,
})
export const getters = getterTree(state, {
  isSnackbarLoaded(_state) {
    return _state.isSnackbarLoaded
  },
})
export const mutations = mutationTree(state, {
  setSnackbarLoaded(_state, loaded) {
    _state.isSnackbarLoaded = loaded
  },
})
const repeatActionIfSnackbarNotLoaded = (action: string) =>
  function ({ dispatch, getters }, payload) {
    if (!getters.isSnackbarLoaded) {
      setTimeout(() => {
        dispatch(action, payload)
      }, 1000)
    }
  }
export const actions = actionTree(
  { state, mutations, getters },
  {
    setSnackbarLoaded({ commit }, loaded) {
      commit('setSnackbarLoaded', loaded)
    },
    showSnackbar(_store, payload) {
      repeatActionIfSnackbarNotLoaded('showSnackbar').call(
        this,
        _store,
        payload
      )
    },
    showSuccessNotification(_store, payload) {
      repeatActionIfSnackbarNotLoaded('showSuccessNotification').call(
        this,
        _store,
        payload
      )
    },
    showInfoNotification(_store, payload) {
      repeatActionIfSnackbarNotLoaded('showInfoNotification').call(
        this,
        _store,
        payload
      )
    },
    showWarningNotification(_store, payload) {
      repeatActionIfSnackbarNotLoaded('showWarningNotification').call(
        this,
        _store,
        payload
      )
    },
    showErrorNotification(_store, payload) {
      repeatActionIfSnackbarNotLoaded('showErrorNotification').call(
        this,
        _store,
        payload
      )
    },
    showError(_store, payload) {
      repeatActionIfSnackbarNotLoaded('showError').call(this, _store, payload)
    },
    showWarning(_store, payload) {
      repeatActionIfSnackbarNotLoaded('showWarning').call(this, _store, payload)
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
