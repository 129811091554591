import { path, pathOr, tap } from 'ramda'
import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { Loyalty } from '~/types/__generated__/LoyaltyRoute'
import GetPackages = Loyalty.LoyaltyCoachesPackagesList
import GetPackageDetails = Loyalty.LoyaltyCoachesPackagesRead
import PatchPackage = Loyalty.LoyaltyCoachesPackagesPartialUpdate
export const state = () => ({
  packages: [] as GetPackages.ResponseBody,
})

export const getters = getterTree(state, {
  packages(_state) {
    return _state.packages
  },
})
export const mutations = mutationTree(state, {
  setPackages(_state, packages) {
    _state.packages = packages
  },
})
const USERID_GETTER_NAME = 'profile/userId'
export const actions = actionTree(
  { state, getters, mutations },
  {
    getPackages(
      { rootGetters, commit },
      coachId = rootGetters[USERID_GETTER_NAME]
    ): Promise<GetPackages.ResponseBody> {
      return this.$axios
        .get(`/api/loyalty/coaches/${coachId}/packages/`)
        .then(pathOr([], ['data']))
        .then(
          tap((packages) => {
            commit('setPackages', packages)
          })
        )
    },
    getPackage(
      { rootGetters },
      id,
      coachId = rootGetters[USERID_GETTER_NAME]
    ): Promise<GetPackageDetails.ResponseBody | undefined> {
      return this.$axios
        .get(`/api/loyalty/coaches/${coachId}/packages/${id}/`)
        .then(path(['data']))
    },
    createPackage({ getters, rootGetters, commit }, data) {
      return this.$axios
        .post(
          `/api/loyalty/coaches/${rootGetters[USERID_GETTER_NAME]}/packages/`,
          data
        )
        .then(path(['data']))
        .then((packageData) => {
          commit('setPackages', [...getters.packages, packageData])
        })
    },
    updatePackage({ getters, rootGetters, commit }, data) {
      return this.$axios
        .patch(
          `/api/loyalty/coaches/${rootGetters[USERID_GETTER_NAME]}/packages/${data.id}/`,
          data
        )
        .then(path(['data']))
        .then((packageData: PatchPackage.ResponseBody | undefined) => {
          if (packageData) {
            commit(
              'setPackages',
              getters.packages.map((item) =>
                item.id === packageData.id ? packageData : item
              )
            )
          }
        })
    },
    deletePackage({ getters, rootGetters, commit }, id) {
      return this.$axios
        .delete(
          `/api/loyalty/coaches/${rootGetters[USERID_GETTER_NAME]}/packages/${id}/`
        )
        .then(path(['data']))
        .then(() => {
          commit(
            'setPackages',
            getters.packages.filter((item) => item.id !== id)
          )
        })
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
