
export default {
  name: 'CardWrapper',
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    isListItem: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: String,
      default: 'rounded-xl',
    },
    iconShift: {
      type: String,
      default: 'top',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideIcons: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    outlined() {
      if (this.clickable && !this.selectable) {
        return false
      }
      return true
    },
    ripple() {
      return this.clickable || this.selectable
    },
    wrapperColor() {
      const defaultWrapperColor = 'grey lighten-4'
      if (this.selectable && this.selected) {
        return 'black'
      } else if (this.selectable && !this.selected) {
        return defaultWrapperColor
      } else if (this.clickable && !this.isListItem) {
        return defaultWrapperColor
      } else if (this.clickable && this.isListItem) {
        return defaultWrapperColor
      }
      return defaultWrapperColor
    },
    columnClasses() {
      return 'd-flex flex-column'
    },
  },
}
