
import {
  TOPIC_GRADE_DISCUSSED,
  TOPIC_GRADE_REMOVE,
  TOPIC_GRADE_TITLES,
} from '../../../utils/constants'
import TopicRating from '../../shared/TopicRating'

export default {
  name: 'RateTopicMenuItem',
  components: { TopicRating },
  props: {
    rating: {
      type: [Number, String, null],
      default: TOPIC_GRADE_DISCUSSED,
    },
  },
  computed: {
    ratingTitle() {
      if (this.rating === TOPIC_GRADE_REMOVE) {
        return this.$t('topicGradeMenu.remove')
      }
      return this.$t(TOPIC_GRADE_TITLES[this.rating])
    },
    textColorClasses() {
      if (this.rating === TOPIC_GRADE_REMOVE) {
        return 'red--text text--darken-1'
      } else {
        return 'black--text'
      }
    },
  },
}
