
/* global google */
import { mapActions, mapGetters } from 'vuex'
import { Geolocation } from '@capacitor/geolocation'
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'
import FormHelperMixin from '../../../mixins/FormHelperMixin'
import CallResetOnDialogOpenMixin from '../../../mixins/CallResetOnDialogOpenMixin'
import { DEFAULT_LOCATION } from '../../../utils/constants'
import MapsHelperMixin from '../../../mixins/MapsHelperMixin'
import PlaceForm from './PlaceForm'
const initialForm = () => ({
  latitude: 0,
  longitude: 0,
  meta: {},
  name: '',
})
export default {
  name: 'AddPlace',
  components: { PlaceForm },
  mixins: [
    FormHelperMixin,
    ResponsiveScreenHelperMixin,
    CallResetOnDialogOpenMixin,
    MapsHelperMixin,
  ],
  props: {
    contextAreasOfOperations: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      busy: false,
      formData: initialForm(),
      areasOfOperation: [],
    }
  },
  computed: {
    ...mapGetters(['isNativePlatform']),
    ...mapGetters('profile', ['profile_active_places', 'isCoach', 'userId']),
    formAreas() {
      return this.contextAreasOfOperations.concat(this.areasOfOperation)
    },
  },
  async mounted() {
    this.formData = initialForm()

    if (this.isNativePlatform) {
      try {
        const { coords } = await Geolocation.getCurrentPosition()
        this.setCoords(coords)
      } catch (e) {
        this.setDefaultCoords()
      }
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => this.setCoords(coords),
        () => this.setDefaultCoords()
      )
    } else {
      this.setDefaultCoords()
    }

    await this.loadAreasOfOperation()
  },
  methods: {
    ...mapActions('profile', ['addPlace']),
    ...mapActions('postal_codes', ['getCoachAreasOfOperation']),
    ...mapActions('app_snackbars', ['showError']),
    setCoords(coords) {
      this.formData.latitude = coords.latitude
      this.formData.longitude = coords.longitude
      this.resolveMetadata()
    },
    setDefaultCoords() {
      this.formData.latitude = DEFAULT_LOCATION.lat
      this.formData.longitude = DEFAULT_LOCATION.lng
      this.resolveMetadata()
    },
    async onSave() {
      this.busy = true
      try {
        if (this.$refs.form.validate()) {
          const createdPlace = await this.addPlace(this.formData)
          this.$emit('created', createdPlace)
          this.$emit('close')
        }
        this.busy = false
      } catch (e) {
        if (this.responseErrorIsClientValidation(e)) {
          this.$refs.form.setFormErrors(this.getResponseValidationErrorData(e))
          this.$refs.form.validate()
        }
        this.showRequestErrorMessage(e)
        this.busy = false
      }
    },
    resetForm() {
      this.formData = initialForm()
      this.resetValidation()
    },
    async loadAreasOfOperation() {
      if (this.isCoach) {
        try {
          this.areasOfOperation = await this.getCoachAreasOfOperation({
            coach_id: this.userId,
          })
        } catch (e) {}
      }
    },
    resolveMetadata() {
      if (!window.google || !this.$el) {
        setTimeout(() => {
          this.resolveMetadata()
        }, 1000)
        return
      }

      const geoCoder = new google.maps.Geocoder()
      geoCoder.geocode(
        {
          location: new google.maps.LatLng({
            lat: this.formData.latitude,
            lng: this.formData.longitude,
          }),
        },
        (results, status) => {
          if (status === 'OK') {
            this.formData.meta = results[0]
          }
        }
      )
    },
    handleOutOfArea() {
      this.showError(this.$t('placeSelection.outOfAreaError'))
    },
  },
}
