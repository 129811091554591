
import { mapActions } from 'vuex'
import SectionTitle from '../../../shared/SectionTitle'
import LessonPlanItem from '../../../coach/lesson_plan_section/LessonPlanItem'

export default {
  name: 'LessonPlanSection',
  components: { LessonPlanItem, SectionTitle },
  props: {
    lessonId: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  data() {
    return {
      plan: [],
    }
  },
  watch: {
    lessonId: {
      handler(id) {
        if (id) {
          this.loadPlan(id)
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('coach_curriculum', ['loadLessonPlan']),
    async loadPlan(id) {
      this.plan = await this.loadLessonPlan(id)
    },
  },
}
