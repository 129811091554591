import { mapGetters } from 'vuex'
import { de, enUS, fr, it } from 'date-fns/locale'
import { toLower } from 'ramda'

const vPickerLocaleCodeMap = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  it: 'it',
}
const fCalendarLocaleCodeMap = {
  en: 'en-gb',
  de: 'de',
  fr: 'fr',
  it: 'it',
}
const dateFNSLocales = {
  en: enUS,
  fr,
  de,
  it,
}
export default {
  computed: {
    ...mapGetters('profile', ['application_language']),
    dateFNSLocale() {
      return dateFNSLocales[toLower(this.application_language) || 'en']
    },
    vPickerLocale() {
      return vPickerLocaleCodeMap[toLower(this.application_language) || 'en']
    },
    fCalendarLocale() {
      return fCalendarLocaleCodeMap[toLower(this.application_language) || 'en']
    },
  },
}
