
import CardWrapper from '../../../../shared/CardWrapper'
import MapSingleSelectField from '../MapSingleSelectField'

export default {
  name: 'AreasOverviewTab',
  components: {
    CardWrapper,
    MapSingleSelectField,
  },
  props: {
    areasOfOperation: {
      type: Array,
      default() {
        return []
      },
    },
    places: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
