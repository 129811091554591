import { mapActions, mapState } from 'vuex'
import {
  PAYMENT_TRANSACTION_CANCELED,
  PAYMENT_TRANSACTION_FAILED,
  PAYMENT_TRANSACTION_SETTLED,
} from '../utils/constants'

export default {
  head() {
    return {
      script: [
        {
          once: true,
          skip: this.paymentButtonLibLoaded,
          src: `${this.$config.datatransBaseURL}/upp/payment/js/payment-button-2.0.0.js`,
          callback: () => this.setPaymentButtonLibLoaded(true),
        },
      ],
    }
  },
  computed: {
    ...mapState(['paymentButtonLibLoaded']),
  },
  methods: {
    ...mapActions(['setPaymentButtonLibLoaded']),
    initPaymentButtonLibrary({ initData, onAuthorized, onError, onCancel }) {
      window.PaymentButton.init({
        ...initData,
      })
      window.PaymentButton.on('authorization', function (response) {
        // handle transaction result
        onAuthorized && onAuthorized(response)
      })
      window.PaymentButton.on('error', function (response) {
        // handle transaction result
        onError && onError(response)
      })
      window.PaymentButton.on('abort', function (response) {
        // handle transaction result
        onCancel && onCancel(response)
      })
    },
    renderPaymentButton($el, payment) {
      window.PaymentButton.on('init', function () {
        window.PaymentButton.create($el, payment)
      })
    },
    browserSupportsApplePay() {
      return (
        !!window.ApplePaySession && window.ApplePaySession.canMakePayments()
      )
    },
    browserSupportsGooglePay() {
      return !!window.PaymentRequest
    },
    pingTransactionStatusUntilProcessed(refno) {
      setTimeout(() => {
        this.getTransactionStatus(refno).then(({ status }) => {
          if (status === PAYMENT_TRANSACTION_SETTLED) {
            this.$emit('transactionFinished')
          } else if (
            [PAYMENT_TRANSACTION_CANCELED, PAYMENT_TRANSACTION_FAILED].includes(
              status
            )
          ) {
            this.$emit('transactionFailed')
          } else {
            this.pingTransactionStatusUntilProcessed(refno)
          }
        })
      }, 1000)
    },
    async cancelPrebookingForPaymentButton() {
      if (this.prebookedTransactionRefno) {
        try {
          await this.cancelPrebooking(this.prebookedTransactionRefno)
          this.prebookedTransactionRefno = null
        } catch (e) {
          console.error('Failed to release prebooked item')
        }
      }
    },
  },
}
