
import { pipe, find, whereEq, clone } from 'ramda'
import CardWrapper from '../../../shared/CardWrapper'
import LocationLink from '../../../shared/places/LocationLink'
import { gmapsLocationURL } from '../../../../utils/commonutils'
import MapSingleSelectField from './MapSingleSelectField'
export default {
  name: 'PlaceSingleSelect',
  components: { LocationLink, CardWrapper, MapSingleSelectField },
  props: {
    hideLink: {
      type: Boolean,
      default: false,
    },
    places: {
      type: Array,
      default() {
        return []
      },
    },
    value: {
      type: [String, Number],
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    nameLabels: {
      type: Boolean,
      default: false,
    },
    areasOfOperation: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    selectedPlaceMeta() {
      return pipe(find(whereEq({ id: this.value })), (place) => {
        const copy = clone(place)
        if (!place?.meta) {
          return {}
        }
        copy.meta.geometry.location.lat = place.latitude
        copy.meta.geometry.location.lng = place.longitude
        return copy.meta
      })(this.places)
    },
  },
  methods: {
    onPlaceSelect(id) {
      if (this.editable) {
        this.$emit('input', id)
      } else if (this.hideLink) {
        const newWindow = window.open(
          gmapsLocationURL(this.selectedPlaceMeta),
          '_blank'
        )
        this.$nextTick(() => {
          newWindow.focus()
        })
      }
    },
    onOutOfAreaSelected(place) {
      if (this.editable) {
        this.$emit('out-of-area', place)
      }
    },
  },
}
