import { render, staticRenderFns } from "./AvatarPhoto.vue?vue&type=template&id=73cf5239&scoped=true&"
import script from "./AvatarPhoto.vue?vue&type=script&lang=js&"
export * from "./AvatarPhoto.vue?vue&type=script&lang=js&"
import style0 from "./AvatarPhoto.vue?vue&type=style&index=0&id=73cf5239&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73cf5239",
  null
  
)

export default component.exports