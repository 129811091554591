
import {
  differenceInCalendarDays,
  differenceInHours,
  format,
  isFuture,
  isPast,
  parseISO,
} from 'date-fns'
import { path, pathEq, pathOr } from 'ramda'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  Smartlook,
  SmartlookCustomEvent,
} from '@awesome-cordova-plugins/smartlook'
import ResponsiveScreenHelperMixin from '../../../../mixins/ResponsiveScreenHelperMixin'
import SectionTitle from '../../../shared/SectionTitle'
import CarItem from '../../../coach/coach_profile/car_details/CarItem'
import PlaceSingleSelect from '../../student_explore/booking/PlaceSingleSelect'
import {
  LESSON_DETAILS_SUMMARY_DATE_FORMAT,
  LESSON_STATUS_BOTH_APPROVED,
  LESSON_STATUS_COACH_APPROVED,
  LESSON_STATUS_COACH_CANCELLED,
  LESSON_STATUS_COMPLETED,
  LESSON_STATUS_DID_NOT_TAKE_PLACE,
  LESSON_STATUS_STUDENT_APPROVED,
  LESSON_STATUS_STUDENT_CANCELLED,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_COINS,
  PAYMENT_TRANSACTION_PARTIALLY_REFUNDED,
  PAYMENT_TRANSACTION_REFUNDED,
  PAYMENT_TRANSACTION_SETTLED,
  slotTypes,
} from '../../../../utils/constants'
import LessonPlaceSelection from '../../student_explore/booking/LessonPlaceSelection'
import PaymentActionButton from '../../student_explore/booking/payment/PaymentActionButton'
import { studentProfileComplete } from '../../../../utils/commonutils'
import FormHelperMixin from '../../../../mixins/FormHelperMixin'
import RateExamSection from '../../../shared/coach_lesson/RateExamSection'
import i18nHelperMixin from '../../../../mixins/i18nHelperMixin'
import PaymentReceiptSection from './PaymentReceiptSection'
import LessonDetailsHeader from './LessonDetailsHeader'
import LessonPlanSection from './LessonPlanSection'
import CancelLessonDialog from './CancelLessonDialog'
import PolicyInfoMenuMixin from '~/mixins/PolicyInfoMenuMixin'
export default {
  // TODO: replace student_lesson folder into student_events folder
  name: 'LessonDetailsDialog',
  components: {
    LessonPlanSection,
    RateExamSection,
    PaymentActionButton,
    LessonPlaceSelection,
    PlaceSingleSelect,
    CarItem,
    CancelLessonDialog,
    LessonDetailsHeader,
    SectionTitle,
    PaymentReceiptSection,
  },
  mixins: [
    ResponsiveScreenHelperMixin,
    FormHelperMixin,
    i18nHelperMixin,
    PolicyInfoMenuMixin,
  ],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    lessonDetails: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      ready: false,
      showCancelConfirmation: false,
      coachAreasOfOperation: [],
      // invitation with no preselected place scenario:
      selectedPlace: null,
      studentSelectedPaymentMethod: '',
      use_coins: false,
    }
  },
  computed: {
    ...mapGetters(['baseUrl', 'isNativePlatform']),
    ...mapGetters('profile', ['profile_active_places', 'isCoach', 'icsToken']),
    ...mapState('profile', ['profile']),
    signupRequired() {
      return !studentProfileComplete(this.profile)
    },
    coachDetails() {
      return pathOr({}, ['coach'], this.lessonDetails)
    },
    isCash() {
      return this.details?.order?.is_cash
    },
    hoursUntilStart() {
      const startDate = parseISO(this.lessonDetails.slot_start)
      if (isFuture(startDate)) {
        return differenceInHours(startDate, new Date())
      }
      return 0
    },
    policyApplies() {
      return this.hoursUntilStart < this.cancellationPolicy.time
    },
    isExam() {
      return pathEq(['type'], slotTypes.exam, this.lessonDetails)
    },
    isLesson() {
      return pathEq(['type'], slotTypes.lesson, this.lessonDetails)
    },
    carInfo() {
      const car = path(['car', 'model'], this.lessonDetails)
      const { model, brand } = car
      return `${brand} ${model}`
    },
    placeInfo() {
      const place = path(['pickup_place'], this.lessonDetails)
      if (place) {
        return place.name
      } else {
        const studentPlace = this.profile_active_places.find(
          ({ id }) => this.selectedPlace === id
        )
        return studentPlace.name
      }
    },
    needsApproval() {
      return this.lessonDetails.status === LESSON_STATUS_COACH_APPROVED
    },
    lessonApprovedByCoach() {
      return this.lessonDetails.status === LESSON_STATUS_COACH_APPROVED
    },
    needsPlaceSelection() {
      return this.needsApproval && !this.lessonPlaceId
    },
    showPlaceInDetails() {
      // place is shown in header at later stages
      return (
        !this.needsPlaceSelection &&
        [LESSON_STATUS_STUDENT_APPROVED, LESSON_STATUS_COACH_APPROVED].includes(
          this.lessonDetails.status
        )
      )
    },
    canDownloadReceipt() {
      return [
        PAYMENT_TRANSACTION_SETTLED,
        PAYMENT_TRANSACTION_REFUNDED,
        PAYMENT_TRANSACTION_PARTIALLY_REFUNDED,
      ].includes(this.lessonDetails?.order?.status)
    },
    lessonPlanAvailable() {
      return (
        this.isLesson &&
        [
          LESSON_STATUS_BOTH_APPROVED,
          LESSON_STATUS_STUDENT_CANCELLED,
          LESSON_STATUS_COACH_CANCELLED,
          LESSON_STATUS_COMPLETED,
        ].includes(this.lessonDetails.status)
      )
    },
    canCancelLesson() {
      return (
        [
          LESSON_STATUS_COACH_APPROVED,
          LESSON_STATUS_BOTH_APPROVED,
          LESSON_STATUS_STUDENT_APPROVED,
        ].includes(this.lessonDetails.status) &&
        !isPast(parseISO(this.lessonDetails.slot_start))
      )
    },
    timeSummary() {
      const end = parseISO(this.lessonDetails.slot_end)
      const start = parseISO(this.lessonDetails.slot_start)

      const dayDiff = differenceInCalendarDays(end, start)
      if (!dayDiff) {
        return `${format(start, LESSON_DETAILS_SUMMARY_DATE_FORMAT, {
          locale: this.dateFNSLocale,
        })} - ${format(end, 'HH:mm', { locale: this.dateFNSLocale })}`
      } else {
        return `${format(start, LESSON_DETAILS_SUMMARY_DATE_FORMAT, {
          locale: this.dateFNSLocale,
        })} - ${format(end, LESSON_DETAILS_SUMMARY_DATE_FORMAT, {
          locale: this.dateFNSLocale,
        })}`
      }
    },
    lessonPlace() {
      return this.lessonDetails.pickup_place
    },
    lessonPlaceId() {
      return path(['pickup_place', 'id'])(this.lessonDetails)
    },
    studentPlaces() {
      return this.profile_active_places
    },
    isPaymentRequired() {
      return pathOr(false, ['is_electronic_payment_required'])(
        this.lessonDetails
      )
    },
    dataForConfirmation() {
      const { lesson_id, coach, car } = this.lessonDetails
      const placeId = this.needsPlaceSelection
        ? this.selectedPlace
        : pathOr(this.selectedPlace, ['pickup_place', 'id'])(this.lessonDetails)
      const data = {
        id: lesson_id,
        coach_id: coach.id,
        pickup_place: placeId,
        car: car.id,
      }
      if (this.isPaymentRequired) {
        data.payment_method = this.studentSelectedPaymentMethod
        data.is_package = this.use_coins
      }
      return data
    },
    examRatingSectionAvailable() {
      return (
        this.isExam &&
        [
          LESSON_STATUS_BOTH_APPROVED,
          LESSON_STATUS_STUDENT_CANCELLED,
          LESSON_STATUS_COACH_CANCELLED,
          LESSON_STATUS_COMPLETED,
        ].includes(this.lessonDetails.status)
      )
    },
    isExamPassed() {
      return path(['passed'], this.lessonDetails)
    },
    confirmedLessonView() {
      return [
        LESSON_STATUS_COACH_CANCELLED,
        LESSON_STATUS_STUDENT_CANCELLED,
        LESSON_STATUS_COMPLETED,
        LESSON_STATUS_BOTH_APPROVED,
        LESSON_STATUS_DID_NOT_TAKE_PLACE,
      ].includes(this.lessonDetails.status)
    },
    icsUrl() {
      return `${this.baseUrl}/api/ics/slots/${this.lessonDetails.slot_id}/calendar.ics?token=${this.icsToken}`
    },
  },
  watch: {
    lessonDetails: {
      handler() {
        this.loadAreasOfOperation()
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$nuxt.$emit('signup-finished', true)
    this.$nextTick(() =>
      this.$nuxt.$emit('signup-finished', !this.signupRequired)
    )
    // Hack to reset z-index of
  },
  methods: {
    ...mapActions('postal_codes', ['getCoachAreasOfOperation']),
    ...mapActions('app_snackbars', ['showError', 'showSuccessNotification']),
    ...mapActions('student_drives', [
      'getEventPaymentReceipt',
      'confirmLesson',
      'cancelLesson',
      'confirmExam',
      'cancelExam',
      'getEventIcs',
    ]),
    ...mapActions('student_explore', [
      'storeUnfinishedBookingData',
      'clearUnfinishedBookingData',
    ]),
    downloadSlotIcs() {
      try {
        this.getEventIcs({
          slotId: this.lessonDetails?.slot_id,
          token: this.icsToken,
        })
      } catch (e) {
        this.showRequestErrorMessage(e)
      }
    },
    downloadReceipt() {
      try {
        this.getEventPaymentReceipt({
          lesson_id: this.lessonDetails?.lesson_id,
          student_id: this.profile?.id,
        })
      } catch (e) {}
    },
    saveBookingData({ payment_method, payment_method_type, use_coins }) {
      const data = {
        lessonDetails: {
          ...this.lessonDetails,
        },
        selectedPlace: this.selectedPlace,
        studentSelectedPaymentMethod: this.studentSelectedPaymentMethod,
        use_coins,
        payment_method,
        payment_method_type,
      }
      sessionStorage.setItem('storedLessonDetails', JSON.stringify(data))
    },
    clearSavedBookingData() {
      sessionStorage.clearItem('storedLessonDetails')
    },
    onAccept({ payment_method, payment_method_type, use_coins }) {
      this.studentSelectedPaymentMethod = [
        PAYMENT_METHOD_CASH,
        PAYMENT_METHOD_COINS,
      ].includes(payment_method_type)
        ? ''
        : payment_method
      this.use_coins = use_coins
      if (!studentProfileComplete(this.profile)) {
        this.finishSignup = true
      } else {
        this.onLessonAccept()
      }
    },
    onPaymentSucceed() {
      this.showSuccessNotification(
        'studentNotifications.lessonAcceptSuccessMessage'
      )
      this.$emit('close')
    },
    onLessonAccept() {
      const confirmMethod = this.isExam ? this.confirmExam : this.confirmLesson
      confirmMethod(this.dataForConfirmation)
        .then(
          () => {
            this.onPaymentSucceed()
          },
          (e) =>
            this.showRequestErrorMessage(e, [
              ['response', 'data', 'payment_method'],
            ])
        )
        .then(() => {
          this.studentSelectedPlace = false
        })
    },
    handleAppleGooglePaymentDone() {
      this.onPaymentSucceed()
    },
    handleAppleGooglePaymentError(e) {
      this.showRequestErrorMessage(e)
    },
    onCancelLesson() {
      this.showCancelConfirmation = false
      const { lesson_id, coach } = this.lessonDetails
      if (this.isExam) {
        this.cancelExam({
          id: lesson_id,
          coach_id: coach.id,
        }).then(
          () => {
            this.showSuccessNotification(
              'studentNotifications.examCancelSuccessMessage'
            )
            const name = this.needsApproval ? 'declined_exam' : 'cancelled_exam'
            this.$fa.logEvent({
              name,
            })
            Smartlook.trackCustomEvent(new SmartlookCustomEvent(name))
            this.$emit('close')
          },
          (e) => this.showRequestErrorMessage(e)
        )
      } else {
        this.cancelLesson({
          id: lesson_id,
          coach_id: coach.id,
        }).then(
          () => {
            if (this.lessonApprovedByCoach) {
              this.showSuccessNotification(
                'studentNotifications.lessonDeclineSuccessMessage'
              )
            } else {
              this.showSuccessNotification(
                'studentNotifications.lessonCancelSuccessMessage'
              )
            }
            const name = this.needsApproval
              ? 'declined_lesson'
              : 'cancelled_lesson'
            this.$fa.logEvent({
              name,
            })
            Smartlook.trackCustomEvent(new SmartlookCustomEvent(name))
            this.$emit('close')
          },
          (e) => this.showRequestErrorMessage(e)
        )
      }
    },
    async loadAreasOfOperation() {
      const coach_id = pathOr('', ['coach', 'id'], this.lessonDetails)
      if (coach_id) {
        try {
          this.coachAreasOfOperation = await this.getCoachAreasOfOperation({
            coach_id,
          })
        } catch (e) {}
      }
    },
    onOutOfAreaSelected() {
      this.$nextTick(() => {
        this.selectedPlace = null
        this.showError(this.$t('placeSelection.outOfAreaError'))
      })
    },
    onSignupStart() {
      this.$nuxt.$emit('signup-finished', false)
    },
    onSignupFinished() {
      this.$nuxt.$emit('signup-finished', !this.signupRequired)
    },
  },
}
