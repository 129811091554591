
import MenuOrBottomSheet from '../../../shared/MenuOrBottomSheet'

import ResponsiveScreenHelperMixin from '../../../../mixins/ResponsiveScreenHelperMixin'
import SectionTitle from '../../../shared/SectionTitle'
import { getSmallestImageURL } from '~/utils/commonutils'
import FailableImage from '~/components/shared/FailableImage.vue'
export default {
  name: 'CoachesListMenu',
  components: { SectionTitle, MenuOrBottomSheet, FailableImage },
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    coaches: {
      type: Array,
      default: () => [],
    },
    selectedCoachId: {
      type: [Number, String],
      default: null,
    },
  },
  methods: {
    selectCoach(payload) {
      if (this.selectedCoachId !== payload) {
        this.$emit('coach-select', payload)
      }
    },
    getAvatarURL(avatarData) {
      return getSmallestImageURL(avatarData)
    },
  },
}
