const createHTMLMapMarker = ({ google, ...args }) => {
  class HTMLMapMarker extends google.maps.OverlayView {
    constructor() {
      super()
      this.latlng = args.latlng
      this.html = args.html
      this.setMap(args.map)
    }

    createDiv() {
      this.div = document.createElement('div')
      this.div.style.position = 'absolute'
      if (this.html) {
        this.div.innerHTML = this.html
      }
      google.maps.event.addDomListener(this.div, 'click', () => {
        google.maps.event.trigger(this, 'click', this)
      })
    }

    appendDivToOverlay() {
      const panes = this.getPanes()
      panes.overlayMouseTarget.appendChild(this.div)
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng)
      if (point) {
        this.div.style.left = `${point.x + (args.anchor?.left || 0)}px`
        this.div.style.top = `${point.y + (args.anchor?.top || 0)}px`
      }
    }

    draw() {
      if (!this.div) {
        this.createDiv()
        this.appendDivToOverlay()
      }
      this.positionDiv()
    }

    remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div)
        this.div = null
      }
    }

    getPosition() {
      return this.latlng
    }

    getDraggable() {
      return false
    }

    getVisible() {
      return true
    }
  }

  return new HTMLMapMarker()
}

export { createHTMLMapMarker }
