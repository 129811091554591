import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {
  detectBrowserLanguage,
  getLastUsedLanguage,
} from '../utils/commonutils'
import en from '~/locales/en'
import de from '~/locales/de'
import fr from '~/locales/fr'
import it from '~/locales/it'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: getLastUsedLanguage() || detectBrowserLanguage(),
  fallbackLocale: 'en',
  messages: {
    en,
    de,
    fr,
    it,
  },
})
export default (context, inject) => {
  context.app.i18n = i18n
}
