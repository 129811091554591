import {
  Smartlook,
  SmartlookSetupConfig,
} from '@awesome-cordova-plugins/smartlook'

export default function () {
  Smartlook.setupAndStartRecording(
    new SmartlookSetupConfig(process.env.SMARTLOOK_KEY)
  )
  console.log('smartlook enabled')
}
