
import { mapActions } from 'vuex'
import MenuOrBottomSheet from '../../shared/MenuOrBottomSheet'
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'
import SectionTitle from '../../shared/SectionTitle'
import FormHelperMixin from '../../../mixins/FormHelperMixin'

export default {
  name: 'RateCoachMenu',
  components: { SectionTitle, MenuOrBottomSheet },
  mixins: [ResponsiveScreenHelperMixin, FormHelperMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    coachId: {
      type: [Number, String],
      default: null,
    },
    review: {
      type: Object,
      default() {
        return {
          id: 0,
          value: 0,
          comment: '',
        }
      },
    },
  },
  data() {
    return {
      formData: {
        id: 0,
        value: 0,
        comment: '',
      },
    }
  },
  computed: {
    commentRules() {
      return this.isCommentRequired && !this.formData.comment
        ? [() => this.$t('coachRating.commentIsRequired')]
        : []
    },
    isCommentRequired() {
      return this.formData.value <= 3
    },
    isSendDisabled() {
      return (
        !this.formData.value ||
        (this.isCommentRequired && !this.formData.comment)
      )
    },
  },
  watch: {
    'formData.value': {
      handler() {
        if (this.$refs.form) {
          this.$refs.form.validate()
        }
      },
    },
    open(open) {
      if (open) {
        this.refreshForm()
      }
    },
  },
  methods: {
    ...mapActions('student_drives', ['sendCoachRating', 'updateCoachRating']),
    close() {
      this.$emit('close')
    },
    refreshForm() {
      const { value, id, comment } = this.review
      this.formData.value = value
      this.formData.id = id
      this.formData.comment = comment
    },
    async sendRating() {
      try {
        if (this.formData.id) {
          await this.updateCoachRating({
            id: this.formData.id,
            coach: this.coachId,
            comment: this.formData.comment,
            value: this.formData.value,
          })
        } else {
          await this.sendCoachRating({
            coach: this.coachId,
            comment: this.formData.comment,
            value: this.formData.value,
          })
        }
        this.$emit('update')
        this.close()
      } catch (error) {
        this.showRequestErrorMessage(error)
      }
    },
  },
}
