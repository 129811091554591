
import ResponsiveScreenHelperMixin from '../../mixins/ResponsiveScreenHelperMixin'

export default {
  name: 'DialogOrBottomSheet',
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
}
