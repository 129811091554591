
// Overrides VTab value to support custom name on VTab === value on VTabItem
import { VTab } from 'vuetify/lib'
export default {
  name: 'VTabWithName',
  extends: VTab,
  props: {
    name: { type: [String, Number], default: 0 },
  },
  computed: {
    value() {
      return this.name
    },
  },
}
