
import RatingCalculationMixin from '../../../../mixins/RatingCalculationMixin'

export default {
  name: 'RatingReviewsSection',
  mixins: [RatingCalculationMixin],
  props: {
    isCard: { type: Boolean, default: false },
    coachId: { type: [String, Number], default: null },
    value: { type: [String, Number], default: 0 },
    reviewsCount: { type: [String, Number], default: 0 },
    showReviews: { type: Boolean, default: true },
  },
  computed: {
    navigateTo() {
      return this.isCard
        ? {
            path: '',
            query: { coach_id: this.coachId, tab: 'reviews' },
          }
        : { query: { tab: 'reviews' } }
    },
  },
}
