const FLEX_BASIS_PROP = 'flex-basis'
export default {
  mounted() {
    // forces reflow of list items several times
    // TODO: find official fix
    const TIMES = 10
    const prevBasis = this.$el && this.$el.style[FLEX_BASIS_PROP]
    for (let i = 0; i < TIMES; i++) {
      setTimeout(() => {
        this.$el && (this.$el.style[FLEX_BASIS_PROP] = 0)
        setTimeout(() => {
          this.$el && (this.$el.style[FLEX_BASIS_PROP] = prevBasis)
        }, 500 * i + 10)
      }, 500 * i)
    }
  },
}
