
/* global google */
/* eslint no-undef: "error" */
import MapsHelperMixin from '../../../mixins/MapsHelperMixin'
import LocationAutosuggestInput from '../LocationAutosuggestInput'
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'
export default {
  name: 'MapField',
  components: { LocationAutosuggestInput },
  mixins: [MapsHelperMixin, ResponsiveScreenHelperMixin],
  props: {
    latitude: { type: Number, default: 0 },
    longitude: { type: Number, default: 0 },
    overlayMessage: { type: [String, Boolean], default: '' },
    editable: {
      type: Boolean,
      default: true,
    },
    moveSearchField: {
      type: Boolean,
      default: false,
    },
    meta: {
      type: Object,
      default() {
        return {}
      },
    },
    additionalPlaces: {
      type: Array,
      default() {
        return []
      },
    },
    areasOfOperation: {
      type: Array,
      default() {
        return []
      },
    },
    mapOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      map: null,
      geoCoder: null,
      markers: [],
      extraMarkers: [],
      polygons: [],
    }
  },

  computed: {
    latLng() {
      return {
        lat: this.latitude,
        lng: this.longitude,
      }
    },
  },
  watch: {
    latitude: {
      handler: 'latLngChanged',
    },
    longitude: {
      handler: 'latLngChanged',
    },
    mapsScriptLoaded: {
      handler(loaded) {
        if (loaded) {
          this.initMap()
        }
      },
      immediate: true,
    },
    additionalPlaces: {
      handler: 'updateAdditionalPlaces',
      immediate: true,
    },
    areasOfOperation: {
      handler: 'renderAreasOfOperation',
      immediate: true,
    },
  },
  mounted() {
    // required delay to let map calculate its visible tiles correctly
    setTimeout(() => this.initMap(), 800)
  },
  methods: {
    initMap() {
      if (!window.google || !this.$el) {
        return
      }
      this.map = new google.maps.Map(this.$el.querySelector('.mapWidget'), {
        center: this.latLng,
        clickableIcons: false,
        zoom: 15,
        ...this.mapOptions,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        // disableDefaultUI: true,
      })
      this.geoCoder = new google.maps.Geocoder()
      this.placeMarker(this.latLng)
      this.map.panTo(this.latLng)

      google.maps.event.addListener(this.map, 'click', (e) => {
        if (this.editable) {
          this.geoCoder.geocode({ location: e.latLng }, (results, status) => {
            const is_out_of_area = this.checkPlaceIsOutOfArea(e.latLng)
            if (!is_out_of_area) {
              this.placeMarker(e.latLng)
            }
            this.$emit('input', {
              meta: status === 'OK' ? results[0] : {},
              latitude: e.latLng.lat(),
              longitude: e.latLng.lng(),
              is_out_of_area,
            })
          })
        }
      })

      this.$once('hook:beforeDestroy', () => {
        this.map = null
      })

      this.updateAdditionalPlaces()
      this.renderAreasOfOperation()
    },
    placeMarker(position) {
      this.markers.forEach((m) => m.setMap(null))
      const marker = new google.maps.Marker({
        position,
        map: this.map,
        icon: require(`~/assets/icons/pin_logo_active.svg`),
      })
      this.markers.push(marker)
      this.map.panTo(position)
    },

    latLngChanged() {
      if (!this.canRerender()) {
        return
      }
      this.placeMarker(this.latLng)
      this.map.panTo(this.latLng)
    },
    updateAdditionalPlaces() {
      if (!this.canRerender()) {
        return
      }
      this.extraMarkers.forEach((m) => m.setMap(null))
      this.extraMarkers = []
      this.additionalPlaces.forEach(({ latitude, longitude }) => {
        const marker = new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          icon: require(`~/assets/icons/pin_logo.svg`),

          map: this.map,
        })
        this.extraMarkers.push(marker)
      })
    },
    onPlaceSelected(place) {
      const { location } = place.geometry
      const is_out_of_area = this.checkPlaceIsOutOfArea(location)
      if (is_out_of_area) {
        this.map.panTo(location)
      } else {
        this.$emit('input', {
          meta: place,
          latitude: location.lat(),
          longitude: location.lng(),
          is_out_of_area,
        })
      }
    },

    renderAreasOfOperation() {
      if (!this.canRerender()) {
        return
      }
      if (this.validAreas.length) {
        this.polygons.forEach((p) => p.setMap(null))
        this.polygons = []
        const polygon = this.getWorldPolygonWithHoles(this.validAreas)
        polygon.setMap(this.map)
        this.polygons.push(polygon)
        this.setMapBoundsToFitPlaces()
      }
    },
    setMapBoundsToFitPlaces() {
      if (!this.canRerender()) {
        return
      }
      if (this.validAreas.length) {
        const bounds = new google.maps.LatLngBounds()
        this.validAreas.forEach(({ coordinates }) => {
          if (coordinates?.coordinates[0][0]) {
            coordinates.coordinates[0][0]
              .map(([lng, lat]) => ({ lat, lng }))
              .map((latLng) => bounds.extend(latLng))
          }
        })
        this.map.fitBounds(bounds, 3)
      }
    },
  },
}
