
import { parse, format, isToday } from 'date-fns'
import i18nHelperMixin from '../../mixins/i18nHelperMixin'
import { BASE_DATE_FORMAT } from '../../utils/constants'

export default {
  name: 'DayContainer',
  mixins: [i18nHelperMixin],
  props: {
    date: {
      type: String,
      default: '',
    },
  },
  computed: {
    weekday() {
      const dateObj = parse(this.date, BASE_DATE_FORMAT, new Date())
      return format(dateObj, 'EEE', { locale: this.dateFNSLocale })
    },
    monthDay() {
      const dateObj = parse(this.date, BASE_DATE_FORMAT, new Date())
      return format(dateObj, 'dd')
    },
    isToday() {
      const dateObj = parse(this.date, BASE_DATE_FORMAT, new Date())
      return isToday(dateObj)
    },
  },
}
