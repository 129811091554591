import { Directory, Filesystem } from '@capacitor/filesystem'
import { FileOpener } from '@ionic-native/file-opener'
import { Clipboard } from '@capacitor/clipboard'
import {
  __,
  adjust,
  cond,
  defaultTo,
  equals,
  filter,
  head,
  includes,
  join,
  map,
  path,
  pathEq,
  pathOr,
  pipe,
  prop,
  T,
  toLower,
  toPairs,
  where,
  last,
  sortWith,
  descend,
  isNil,
  negate,
  groupBy,
  sortBy,
  propOr,
  ascend,
} from 'ramda'
/* eslint-disable import/named */
import {
  isArray,
  isFunction,
  isNilOrEmpty,
  isNotNilOrEmpty,
  isPlainObj,
  isString,
  omitBy,
} from 'ramda-adjunct'
/* eslint-enable */
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  formatDuration,
  formatISO,
  getMinutes,
  intervalToDuration,
  isWithinInterval,
  parse,
  parseISO,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  getUnixTime,
  format,
  isPast,
} from 'date-fns'
import { isPossiblePhoneNumber } from 'libphonenumber-js/mobile'
import {
  LESSON_STATUS_BOTH_APPROVED,
  LESSON_STATUS_COACH_APPROVED,
  LESSON_STATUS_COACH_CANCELLED,
  LESSON_STATUS_COMPLETED,
  LESSON_STATUS_STUDENT_APPROVED,
  LESSON_STATUS_STUDENT_CANCELLED,
  TOPIC_GRADE_GOOD,
  TOPIC_GRADE_INSUFFICIENT,
  TOPIC_GRADE_DISCUSSED,
  TOPIC_GRADE_READY,
  BASE_DATE_FORMAT,
  slotTypes,
  RATING_FILTER_OFFSET,
  COACH_GENDER_ALL,
  LESSON_STATUS_DID_NOT_TAKE_PLACE,
} from './constants'
import { TOPIC_GRADE_KEYS } from '~/store/coach_curriculum'
import { BookingCalendar } from '~/types/__generated__/BookingCalendarRoute'
import { StudentExploreState } from '~/store/student_explore'
import { Curriculum } from '~/types/__generated__/CurriculumRoute'
import GetCoachTopics = Curriculum.CurriculumCoachesTopicsList
import { GuestStateType } from '~/store/guest'
import { GetStudentsSummary } from '~/store/student_curriculum'

export const addToFormData = (formData, data, context = '') => {
  toPairs(data).forEach(([key, value]) => {
    if (isArray(value)) {
      value.forEach((arrValue, index) => {
        if (isPlainObj(arrValue)) {
          addToFormData(formData, arrValue, `${context}${key}[${index}].`)
        } else {
          formData.append(`${context}${key}[${index}]`, arrValue)
        }
      })
    } else if (isPlainObj(value)) {
      addToFormData(formData, value, `${context}${key}.`)
    } else {
      formData.append(`${context}${key}`, value)
    }
  })
  return formData
}
export const roundDateUpToMinutesStep = (step: number, rawDate: Date) => {
  const currentMinutes = getMinutes(rawDate)
  const remainder = currentMinutes % step
  const minutes = currentMinutes - remainder + (remainder ? step : 0)
  return pipe(
    (date) => setMinutes(date, minutes),
    (date) => setSeconds(date, 0),
    (date) => setMilliseconds(date, 0)
  )(rawDate)
}

export const adjustPriceRegardingDuration = (
  previousStart: string,
  previousEnd: string,
  currentStart: string,
  currentEnd: string,
  price: number
) => {
  const step = 0.05
  const previousDifference = differenceInMinutes(
    parseISO(previousEnd),
    parseISO(previousStart)
  )
  const currentDifference = differenceInMinutes(
    parseISO(currentEnd),
    parseISO(currentStart)
  )
  if (previousDifference !== currentDifference) {
    const newPrice = (price * currentDifference) / previousDifference
    return (Math.round(newPrice / step) * step).toFixed(2)
  } else {
    return price
  }
}
const IMAGE_SIZE_SMALL = 'small'
const IMAGE_SIZE_MEDIUM = 'medium'
const IMAGE_SIZE_LARGE = 'large'
const IMAGE_PRIO = {
  [IMAGE_SIZE_LARGE]: 1,
  [IMAGE_SIZE_MEDIUM]: 2,
  [IMAGE_SIZE_SMALL]: 3,
}
// enum ImageSizes {IMAGE_SIZE_SMALL, IMAGE_SIZE_MEDIUM , IMAGE_SIZE_LARGE}
// type CarziImageData = {url: string, thumbnails: [{size: , name?:string, url: string}, ...]}
const getImageURL = (size, fieldData) => {
  /*
   * expected {url, thumbnails: [{size, url}, ...]} or string url
   * */
  if (isString(fieldData)) {
    return fieldData
  }
  if (isNotNilOrEmpty(path(['thumbnails'], fieldData))) {
    const thumbMap = fieldData.thumbnails.reduce((acc, imageData) => {
      acc[imageData.size] = imageData
      return acc
    }, {})

    const allSizes = []
    const originalURL = pathOr('', ['url'], fieldData)
    if (originalURL) {
      allSizes.push(originalURL)
    }
    if (thumbMap[IMAGE_SIZE_LARGE]) {
      allSizes.push(thumbMap[IMAGE_SIZE_LARGE].url)
    }
    if (thumbMap[IMAGE_SIZE_MEDIUM]) {
      allSizes.push(thumbMap[IMAGE_SIZE_MEDIUM].url)
    }
    if (thumbMap[IMAGE_SIZE_SMALL]) {
      allSizes.push(thumbMap[IMAGE_SIZE_SMALL].url)
    }
    return allSizes[IMAGE_PRIO[size]] || last(allSizes)
  } else {
    return pathOr('', ['url'], fieldData)
  }
}

export const getSmallestImageURL = (fieldData) =>
  getImageURL(IMAGE_SIZE_SMALL, fieldData)
export const getMediumImageURL = (fieldData) =>
  getImageURL(IMAGE_SIZE_MEDIUM, fieldData)
export const getLargeImageURL = (fieldData) =>
  getImageURL(IMAGE_SIZE_LARGE, fieldData)

export const detectBrowserLanguage = () => {
  const detectedLang = toLower(navigator.language || 'en')
  if (/^en\b/.test(detectedLang)) {
    return 'en'
  } else if (/^it\b/.test(detectedLang)) {
    return 'it'
  } else if (/^fr\b/.test(detectedLang)) {
    return 'fr'
  } else if (/^de\b/.test(detectedLang)) {
    return 'de'
  }
  return 'en'
}
export const getLastUsedLanguage = () => {
  return window.localStorage && window.localStorage.getItem('lastUsedLanguage')
}
export const setLastUsedLanguage = (locale) => {
  window.localStorage && window.localStorage.setItem('lastUsedLanguage', locale)
}
export const getLastUsedCalendarView = () => {
  return (
    window.localStorage && window.localStorage.getItem('lastUsedCalendarView')
  )
}
export const setLastUsedCalendarView = (view: string) => {
  window.localStorage &&
    window.localStorage.setItem('lastUsedCalendarView', view)
}
const getSearchTimeBounds = ({
  date,
  time,
}: {
  date: string
  time: string
}) => {
  let startDate
  if (date) {
    startDate = parse(date, 'yyyy-MM-dd', new Date())
  } else {
    startDate = new Date()
  }
  if (time) {
    const [hours, minutes] = time.split(':')
    startDate = setHours(startDate, +hours)
    startDate = setMinutes(startDate, +minutes)
  }
  // restrict searching in past
  if (isPast(startDate)) {
    startDate = new Date()
  }
  if (date && time) {
    return {
      start__gte: formatISO(addMinutes(startDate, -30)),
      start__lte: formatISO(addMinutes(startDate, 30)),
    }
  } else if (date) {
    return {
      start__gte: formatISO(startDate),
      start__lte: formatISO(addDays(startDate, 1)),
    }
  } else {
    return {
      start__gte: formatISO(startDate),
      start__lte: formatISO(addDays(startDate, 30)),
    }
  }
}
export const getRatingForFilter = (value) => {
  return +(!!value && value - RATING_FILTER_OFFSET)
}

export const makeLessonFilter = (
  filterState:
    | GuestStateType['filterState']
    | StudentExploreState['filterState']
): BookingCalendar.BookingCalendarSlotsList.RequestQuery => {
  const {
    gearbox,
    lesson_languages,
    payment_methods,
    user,
    place,
    ordering,
    average_rating,
    gender,
  } = filterState
  const filterData = {
    gearbox,
    available_languages: Array.isArray(lesson_languages)
      ? lesson_languages.join(',')
      : lesson_languages || [],
    payment_methods: payment_methods.join(','),
    coach: path(['id'], user),
    ordering,
    ...getLocationFilter(place),
    ...getSearchTimeBounds(filterState),
    ...(gender === COACH_GENDER_ALL ? {} : { gender }),
  }
  if (average_rating) {
    filterData.average_rating = getRatingForFilter(average_rating)
  }
  return omitBy(isNilOrEmpty, filterData)
}
const getLocationFilter = (place) => {
  if (place && place.is_city) {
    return {
      city: place.city,
    }
  } else if (place && place.postal_code) {
    return {
      postal_code: place.postal_code,
    }
  }
  return {}
}
export const formatFullName = ({ first_name = '', last_name = '' }) => {
  return `${first_name} ${last_name}`
}
export const formatLessonDuration = (locale, { start, end }) => {
  if (!start || !end) {
    return ''
  }
  return formatDuration(
    intervalToDuration({
      start: parseISO(start),
      end: parseISO(end),
    }),
    { locale }
  )
}

export const getErrorMessageFromRules = (rules, value) =>
  pipe(
    map((rule) => rule(value)),
    filter((m) => m !== true),
    head
  )(rules)

export const lowerCaseFirstLetter = pipe(
  defaultTo(''),
  adjust(0, toLower),
  join('')
)
export const lessonTitle = cond([
  [
    pipe(
      propOr(0, 'status'),
      includes(__, [
        LESSON_STATUS_STUDENT_APPROVED,
        LESSON_STATUS_COACH_APPROVED,
        LESSON_STATUS_BOTH_APPROVED,
        LESSON_STATUS_COMPLETED,
      ])
    ),
    (lesson) => {
      const student = pathOr({}, ['student'])(lesson)
      if (student.first_name || student.last_name) {
        return [
          'slotTitle.booked_student_has_name',
          { fullName: formatFullName(student) },
        ]
      } else {
        return ['slotTitle.booked_untitled_student', { phone: student.phone }]
      }
    },
  ],
  [
    pathEq(['status'], LESSON_STATUS_COACH_CANCELLED),
    () => ['slotTitle.canceled'],
  ],
  [
    pathEq(['status'], LESSON_STATUS_STUDENT_CANCELLED),
    () => ['slotTitle.canceled'],
  ],
  [
    pathEq(['status'], LESSON_STATUS_DID_NOT_TAKE_PLACE),
    () => ['slotTitle.lessonDidNotTakePlace'],
  ],
  [T, () => ['']],
])
export const slotTitle = (slot) => {
  if (slot.is_booked) {
    if (pathEq(['lesson', 'type'], slotTypes.exam, slot)) {
      if (
        pathEq(['lesson', 'status'], LESSON_STATUS_DID_NOT_TAKE_PLACE, slot)
      ) {
        return ['slotTitle.examDidNotTakePlace']
      }
      const examTitle = pathOr('', ['lesson', 'title'], slot)
      const name = formatFullName(pathOr({}, ['lesson', 'student'], slot))
      return examTitle ? [examTitle] : ['coachLesson.examWithTitle', { name }]
    }
    return lessonTitle(slot.lesson)
  } else {
    return ['slotTitle.open']
  }
}
export const studentProfileComplete = where({
  first_name: isNotNilOrEmpty,
  last_name: isNotNilOrEmpty,
  email: isNotNilOrEmpty,
  is_password_set: equals(true),
})

export const gmapsLocationURL = (meta) => {
  const location = path(['geometry', 'location'])(meta)
  const lat = isFunction(location.lat) ? location.lat() : location.lat
  const lng = isFunction(location.lng) ? location.lng() : location.lng
  return `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`
}

export const dateStrInRange = ({ date, start, end }) => {
  return isWithinInterval(parseISO(date), {
    end: parseISO(end),
    start: parseISO(start),
  })
}

export const calcRatingTitleColor = (rating) => {
  if (rating === TOPIC_GRADE_INSUFFICIENT) {
    return 'red--text text--darken-2'
  } else if (rating === TOPIC_GRADE_GOOD) {
    return 'yellow--text text--darken-3'
  } else if (rating === TOPIC_GRADE_READY) {
    return 'green--text text--darken-2'
  } else if (rating === TOPIC_GRADE_DISCUSSED) {
    return 'black--text'
  } else {
    return 'grey--text'
  }
}
export const groupTopicsToCurriculum = (
  results: GetCoachTopics.ResponseBody
) => {
  const categories = groupBy(path(['category', 'name']) as any, results)
  const result = Object.keys(categories).map((category) => {
    const correctCurriculum = categories[category].reduce((accObj, topic) => {
      const subjectId = pathOr(0, ['subject', 'id'], topic)
      if (!accObj[subjectId]) {
        accObj[subjectId + ''] = {
          ...(topic.subject || {}),
          topics: [topic],
        }
      } else {
        accObj[subjectId].topics = [...accObj[subjectId].topics, topic]
      }
      return accObj
    }, {} as Record<string, Partial<Unpacked<GetCoachTopics.ResponseBody>['subject']> & { topics: GetCoachTopics.ResponseBody }>)
    const sortedSubjectsCurriculum = sortWith(
      [descend(propOr(0, 'priority'))],
      Object.values(correctCurriculum)
    )
    return sortedSubjectsCurriculum.map((subject) => {
      subject.topics = sortWith(
        [
          ascend(definePriorityValueForSortingTopics),
          descend(
            pipe(propOr('', 'created_at'), parseISO, getUnixTime, negate)
          ),
        ],
        subject.topics
      )
      return subject
    })
  })
  const isDefault = path(['0', 'topics', '0', 'category', 'is_default']) as any
  return sortWith([descend(isDefault)], result)
}

export const groupSubjectTotalsForCharts = (res: any[]) =>
  res.reduce((acc, category) => {
    acc[category.category_id] = category.subjects.reduce((accObj, subject) => {
      accObj[subject.subject_id] = subject.ratings.reduce(
        (accRatObj, rat) => {
          const ratingKey =
            TOPIC_GRADE_KEYS[rat.value] ||
            TOPIC_GRADE_KEYS[TOPIC_GRADE_DISCUSSED]
          accRatObj[ratingKey] = rat.total
          return accRatObj
        },
        {
          [TOPIC_GRADE_KEYS[TOPIC_GRADE_DISCUSSED]]: 0,
          [TOPIC_GRADE_KEYS[TOPIC_GRADE_INSUFFICIENT]]: 0,
          [TOPIC_GRADE_KEYS[TOPIC_GRADE_GOOD]]: 0,
          [TOPIC_GRADE_KEYS[TOPIC_GRADE_READY]]: 0,
        }
      )

      return accObj
    }, {} as Record<string, Record<string, number>>)
    return acc
  }, {} as Record<string, any>)

export const checkPhoneLengthByDialCode = (phone = '') => {
  return isPossiblePhoneNumber(phone) || 'errorsMessages.phoneFormatValidation'
}

export const definePriorityValueForSortingTopics = (topic) => {
  if (isNil(topic.parent_priority)) {
    return topic.priority
  }
  return topic.parent_priority
}

export const groupEventsByDays = (events) => {
  const getDateStr = pipe(parseISO, (date) => format(date, 'yyyy-MM-dd'))
  return pipe(
    groupBy(pipe(prop('start'), getDateStr)),
    toPairs,
    sortBy(([dateStr]) =>
      parse(dateStr, BASE_DATE_FORMAT, new Date()).getTime()
    ),
    map(([dateStr, dayEvents]) => [
      dateStr,
      sortBy(({ start }) => parseISO(start).getTime())(dayEvents),
    ])
  )(events)
}

export const htmlToText = (html: string) => {
  const tempDivElement = document.createElement('div')
  tempDivElement.innerHTML = html
  return tempDivElement.textContent || tempDivElement.innerText || ''
}

export const toBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.readAsDataURL(blob)
  })

export const openFileOnNativePlatform = async (
  blob: Blob,
  filePath: string,
  type: string
) => {
  const data = await toBase64(blob)
  const file = await Filesystem.writeFile({
    path: filePath,
    data,
    directory: Directory.Data,
  })
  FileOpener.open(file.uri, type).catch((error) => {
    if (error.status === 9) {
      FileOpener.showOpenWithDialog(file.uri, type)
    } else {
      throw new Error(error)
    }
  })
}

export const copyToClipBoard = (isNative: boolean, string: string) => {
  return isNative
    ? Clipboard.write({
      string,
    })
    : navigator.clipboard.writeText(string)
}
export const mapCoachToRefSlot = (coach) => ({
  ...coach,
  coach: {
    ...coach,
  },
  price: pathOr('', ['price_per_lesson'], coach),
})
