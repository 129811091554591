import { isNotNilOrEmpty } from 'ramda-adjunct'
import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import {
  path,
  keys,
  map,
  omit,
  pick,
  pathOr,
  pipe,
  concat,
  __,
  tap,
  uniqBy,
  prop,
  filter,
} from 'ramda'
import { makeLessonFilter } from '~/utils/commonutils'
import {
  paymentMethods,
  AT_GEARBOX,
  COACH_GENDER_ALL,
  RATING_SORT_PROP,
  REVIEWS_COUNT_SORT_PROP,
} from '~/utils/constants'
import { Users } from '~/types/__generated__/UsersRoute'
import { Locations } from '~/types/__generated__/LocationsRoute'
import { BookingCalendar } from '~/types/__generated__/BookingCalendarRoute'
import { Schools } from '~/types/__generated__/SchoolsRoute'
import SearchCoach = Users.UsersCoachesList
import SearchLocationByPostalCode = Locations.LocationsByPostalCodeList
import SearchLocationByCity = Locations.LocationsByCityList
import SearchLessons = BookingCalendar.BookingCalendarSlotsList
import GetCoachDetails = Users.UsersCoachesRead
import GetSchools = Schools.SchoolsCoachesList
type CitySearchResult = Unpacked<SearchLocationByCity.ResponseBody> & {
  is_city?: boolean
  formatted_address?: string
}
type CityByCodeSearchResult =
  Unpacked<SearchLocationByPostalCode.ResponseBody> & {
    formatted_address?: string
  }

export function requestCurrentLocation(): Promise<GeolocationCoordinates> {
  return new Promise(function (resolve, reject) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          resolve(coords)
        },
        () => {
          reject(new Error('REQUEST_REJECTED'))
        }
      )
    } else {
      reject(new Error('NO_GEOLOCATION'))
    }
  })
}
export const state = () => ({
  filterState: {
    user: '',
    place: '',
    date: '',
    time: '',
    lesson_languages: [],
    payment_methods: [],
    gearbox: '',
    ordering: `-${RATING_SORT_PROP}`,
    average_rating: 0,
    is_direct_booking: false,
    gender: COACH_GENDER_ALL,
    price_per_hour__gte: 0,
    price_per_hour__lte: 0,
  },
  lessonSearch: [],
  popularCoaches: {
    next: null,
    previous: null,
    count: 0,
    results: [],
  } as SearchCoach.ResponseBody,
  closePopularCoaches: {
    next: null,
    previous: null,
    count: 0,
    results: [],
  } as SearchCoach.ResponseBody,
  coachesForPickupPlaces: {
    next: null,
    previous: null,
    count: 0,
    results: [],
  } as SearchCoach.ResponseBody,
  minPrice: 0,
  maxPrice: 0,
})
export type GuestStateType = ReturnType<typeof state>
export const getters = getterTree(state, {
  popularCoaches(_state) {
    return _state?.popularCoaches?.results || []
  },
  closePopularCoaches(_state) {
    return _state?.closePopularCoaches?.results || []
  },
  coachesForPickupPlaces(_state) {
    return _state?.coachesForPickupPlaces?.results || []
  },
  coachesForPickupPlacesCount(_state) {
    return _state?.coachesForPickupPlaces?.count
  },
  moreCoachesUrl(_state) {
    return _state?.popularCoaches?.next || ''
  },
  moreCloseCoachesUrl(_state) {
    return _state?.closePopularCoaches?.next || ''
  },
  moreCoachesForPickupPlacesUrl(_state) {
    return _state?.coachesForPickupPlaces?.next || ''
  },
  filtersState(_state) {
    return omit(['ordering'], _state.filterState)
  },
  sortsState(_state) {
    return pick(['ordering'], _state.filterState)
  },
  filterData(_state) {
    return makeLessonFilter(_state.filterState)
  },
  defaultFilter(_state) {
    return {
      payment_methods: keys(paymentMethods).map((methodId) => +methodId),
      gearbox: AT_GEARBOX,
      gender: COACH_GENDER_ALL,
      average_rating: 0,
      lesson_languages: [],
      is_direct_booking: false,
      price_per_hour__gte: _state.minPrice,
      price_per_hour__lte: _state.maxPrice,
    }
  },
})

export const mutations = mutationTree(state, {
  updateFilter(_state, filter) {
    _state.filterState = {
      ..._state.filterState,
      ...filter,
    }
  },
  setSearchResults(_state, results) {
    _state.lessonSearch = results
  },
  setPopularCoaches(_state, results) {
    _state.popularCoaches = results
  },
  setClosePopularCoaches(_state, results) {
    _state.closePopularCoaches = results
  },
  setCoachesForPickupPlaces(_state, results) {
    _state.coachesForPickupPlaces = results
  },
  addCoachesForPickupPlaces(_state, nextPage: SearchCoach.ResponseBody) {
    const { next = null, previous = null, count = 0, results = [] } = nextPage
    _state.coachesForPickupPlaces = {
      next,
      previous,
      count,
      results: pipe(
        pathOr([], ['coachesForPickupPlaces', 'results']),
        concat(__, results),
        uniqBy(prop('id'))
      )(_state),
    }
  },
  addPopularCoaches(_state, nextPage: SearchCoach.ResponseBody) {
    const { next = null, previous = null, count = 0, results = [] } = nextPage
    _state.popularCoaches = {
      next,
      previous,
      count,
      results: pipe(
        pathOr([], ['popularCoaches', 'results']),
        concat(__, results)
      )(_state),
    }
  },
  addClosePopularCoaches(_state, nextPage: SearchCoach.ResponseBody) {
    const { next = null, previous = null, count = 0, results = [] } = nextPage
    _state.closePopularCoaches = {
      next,
      previous,
      count,
      results: pipe(
        pathOr([], ['closePopularCoaches', 'results']),
        concat(__, results)
      )(_state),
    }
  },
  setPriceMinMax(_state, { min, max }) {
    _state.minPrice = min
    _state.maxPrice = max
  },
})
export const actions = actionTree(
  { state, getters, mutations },
  {
    getPriceRangeData({ commit }, id): Promise<any> {
      return this.$axios
        .get('api/users/coaches/prices/', {
          params: id ? { id } : {},
        })
        .then(pathOr([], ['data']))
        .then(filter(isNotNilOrEmpty))
        .then((data) => {
          const min = Math.min(...data)
          const max = Math.max(...data)
          commit('setPriceMinMax', { max, min })
          return data
        })
    },
    updateFilter({ commit, dispatch }, filter) {
      commit('updateFilter', filter)
      dispatch('searchLessons')
    },
    suggestCoach(_, search): Promise<SearchCoach.ResponseBody['results']> {
      return this.$axios
        .get('api/users/coaches/', { params: { search } })
        .then(pathOr([], ['data', 'results']))
    },
    suggestPostalCode(_, search): Promise<CityByCodeSearchResult[]> {
      return this.$axios
        .get('api/locations/by-postal-code/', { params: { search } })
        .then(pathOr([], ['data']))
        .then(
          map((item: CityByCodeSearchResult) => {
            item.formatted_address = `${item.city} ${item.postal_code}`
            return item
          })
        )
    },
    suggestCity(_, search): Promise<CitySearchResult[]> {
      return this.$axios
        .get('api/locations/by-city/', { params: { search } })
        .then(pathOr([], ['data']))
        .then(
          map((item: CitySearchResult) => {
            item.formatted_address = `${item.city}`
            item.is_city = true
            return item
          })
        )
    },
    searchLessons({ getters, commit }): Promise<SearchLessons.ResponseBody> {
      return this.$axios
        .get('api/booking-calendar/slots/', { params: getters.filterData })
        .then(pathOr([], ['data']))
        .then(tap((results) => commit('setSearchResults', results)))
    },
    getPopularCoaches({
      rootGetters,
      commit,
    }): Promise<SearchCoach.ResponseBody> {
      return this.$axios
        .get('api/users/coaches/', {
          params: {
            ...rootGetters['student_explore/popularCoachesSearchPeriod'],
            limit: 5,
          },
        })
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(tap((results) => commit('setPopularCoaches', results)))
    },
    getMorePopularCoaches({
      commit,
      getters,
    }): Promise<SearchCoach.ResponseBody> {
      return this.$axios
        .get(getters.moreCoachesUrl)
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(tap((results) => commit('addPopularCoaches', results)))
    },
    getClosePopularCoaches(
      { rootGetters, commit },
      coords = {}
    ): Promise<SearchCoach.ResponseBody> {
      return this.$axios
        .get('api/users/coaches/', {
          params: {
            ...coords,
            ...rootGetters['student_explore/popularCoachesSearchPeriod'],
            limit: 5,
          },
        })
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(tap((results) => commit('setClosePopularCoaches', results)))
    },
    getMoreClosePopularCoaches({
      commit,
      getters,
    }): Promise<SearchCoach.ResponseBody> {
      return this.$axios
        .get(getters.moreCloseCoachesUrl)
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(tap((results) => commit('addClosePopularCoaches', results)))
    },
    getCoachDetailsById(
      _,
      id
    ): Promise<GetCoachDetails.ResponseBody | undefined> {
      return this.$axios.get(`api/users/coaches/${id}/`).then(path(['data']))
    },
    getCoachesForPickupPlaces(
      { commit, getters },
      ids: string[]
    ): Promise<SearchCoach.ResponseBody | undefined> {
      let { ordering } = getters.sortsState
      const annex =
        ordering === `-${RATING_SORT_PROP}`
          ? `,-${REVIEWS_COUNT_SORT_PROP}`
          : `,-${RATING_SORT_PROP},-${REVIEWS_COUNT_SORT_PROP}`
      ordering += annex
      // https://m-p.atlassian.net/browse/CAR-2158?focusedCommentId=42981
      return this.$axios
        .get(
          `api/users/coaches-for-pickup-places/?id=${ids.join(
            ','
          )}&limit=5&offset=0`,
          {
            params: { ...getters.sortsState, ordering },
          }
        )
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(tap((results) => commit('setCoachesForPickupPlaces', results)))
    },
    getMoreCoachesForPickupPlaces({
      commit,
      getters,
    }): Promise<SearchCoach.ResponseBody> {
      return this.$axios
        .get(getters.moreCoachesForPickupPlacesUrl)
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(tap((results) => commit('addCoachesForPickupPlaces', results)))
    },
    getCoachesBySchoolId(
      _state,
      id
    ): Promise<GetSchools.ResponseBody['results']> {
      return this.$axios
        .get(`api/schools/${id}/coaches/`)
        .then(pathOr([], ['data', 'results']))
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
