import { allPass, any, anyPass, pathOr, tap, pipe, where, whereEq } from 'ramda'
import { isPast, parseISO } from 'date-fns'
import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import {
  LESSON_STATUS_BOTH_APPROVED,
  LESSON_STATUS_STUDENT_APPROVED,
  LESSON_STATUS_STUDENT_CANCELLED,
} from '../utils/constants'
import { BookingCalendar } from '~/types/__generated__/BookingCalendarRoute'
import GetCourseNotifications = BookingCalendar.BookingCalendarCoachesCourseNotificationsList
import GetNotifications = BookingCalendar.BookingCalendarCoachesNotificationsList
export const state = () => ({
  notifications: [],
  contactsNotifications: [],
  courseNotifications: [],
  dataFetching: undefined,
})

export const mutations = mutationTree(state, {
  setNotifications(_state, notifications) {
    _state.notifications = notifications
  },
  setContactsNotifications(_state, notifications) {
    _state.contactsNotifications = notifications
  },
  setCourseNotifications(_state, notifications) {
    _state.courseNotifications = notifications
  },
  setDataFetchingId(_state, intervalId) {
    _state.dataFetching = intervalId
  },
})

export const getters = getterTree(state, {
  userId(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/userId']
  },
  hasNotifications(_state) {
    const needsConfirmation = whereEq({
      status: LESSON_STATUS_STUDENT_APPROVED,
    })
    const canceledByStudent = whereEq({
      status: LESSON_STATUS_STUDENT_CANCELLED,
    })
    const needsReview = allPass([
      where({
        slot_end: pipe(parseISO, isPast),
      }),
      whereEq({
        status: LESSON_STATUS_BOTH_APPROVED,
      }),
    ])

    return (
      any(
        anyPass([needsConfirmation, canceledByStudent, needsReview]),
        _state.notifications
      ) || !!_state.courseNotifications.length
    )
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    getNotifications({ dispatch }) {
      return Promise.all([
        dispatch('getLessonAndExamNotifications'),
        dispatch('getCourseNotifications'),
        dispatch('getContactsNotifications'),
      ])
    },
    getContactsNotifications({
      commit,
    }): Promise<GetNotifications.ResponseBody> {
      return this.$axios
        .get(`api/notifications/contacts/`)
        .then(pathOr([], ['data']))
        .then(tap((results) => commit('setContactsNotifications', results)))
    },
    getContactNotification(_, id): Promise<GetNotifications.ResponseBody> {
      return this.$axios
        .get(`api/notifications/contact/${id}/`)
        .then(pathOr([], ['data']))
    },
    deleteContactNotification(
      { dispatch },
      id
    ): Promise<GetNotifications.ResponseBody> {
      return this.$axios
        .delete(`api/notifications/contact/${id}/`)
        .then(() => dispatch('getContactsNotifications'))
    },
    getLessonAndExamNotifications({
      commit,
      getters,
    }): Promise<GetNotifications.ResponseBody> {
      return this.$axios
        .get(`api/booking-calendar/coaches/${getters.userId}/notifications/`)
        .then(pathOr([], ['data']))
        .then(tap((results) => commit('setNotifications', results)))
    },
    getCourseNotifications({
      commit,
      getters,
    }): Promise<GetCourseNotifications.ResponseBody> {
      return this.$axios
        .get(
          `api/booking-calendar/coaches/${getters.userId}/course-notifications/`
        )
        .then(pathOr([], ['data']))
        .then(tap((results) => commit('setCourseNotifications', results)))
    },
    startDataFetching({ dispatch, commit, state }) {
      dispatch('stopDataFetching')
      if (!state.dataFetching) {
        dispatch('getNotifications')
      }
      const intervalId = setInterval(() => {
        dispatch('getNotifications')
      }, 30 * 1000)
      commit('setDataFetchingId', intervalId)
    },
    stopDataFetching({ commit, state }) {
      clearInterval(state.dataFetching)
      commit('setDataFetchingId', undefined)
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
