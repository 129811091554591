
import { assocPath } from 'ramda'

export default {
  name: 'WeekTimeSelection',
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
    label: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    days() {
      return [
        this.$t('weekTimeSelectionWidget.monday'),
        this.$t('weekTimeSelectionWidget.tuesday'),
        this.$t('weekTimeSelectionWidget.wednesday'),
        this.$t('weekTimeSelectionWidget.thursday'),
        this.$t('weekTimeSelectionWidget.friday'),
        this.$t('weekTimeSelectionWidget.saturday'),
        this.$t('weekTimeSelectionWidget.sunday'),
      ]
    },
    dayTimes() {
      return [
        this.$t('weekTimeSelectionWidget.morning'),
        this.$t('weekTimeSelectionWidget.afternoon'),
        this.$t('weekTimeSelectionWidget.evening'),
      ]
    },
  },
  methods: {
    updateValue(timeIndex, dayIndex, v) {
      return this.$emit(
        'input',
        assocPath([timeIndex, dayIndex], v, this.value)
      )
    },
  },
}
