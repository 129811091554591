import { toLower } from 'ramda'
import { Capacitor } from '@capacitor/core'
import {
  actionTree,
  getAccessorType,
  getterTree,
  mutationTree,
} from 'typed-vuex'
import { getLastUsedLanguage, setLastUsedLanguage } from '~/utils/commonutils'
import { DEFAULT_LANGUAGE } from '~/utils/constants'
import * as app_snackbars from '~/store/app_snackbars'
import * as coach_bank_account from '~/store/coach_bank_account'
import * as coach_cancellation_policy from '~/store/coach_cancellation_policy'
import * as coach_curriculum from '~/store/coach_curriculum'
import * as coach_loyalty from '~/store/coach_loyalty'
import * as coach_notifications from '~/store/coach_notifications'
import * as coach_profile_media from '~/store/coach_profile_media'
import * as coach_schedule from '~/store/coach_schedule'
import * as coach_students from '~/store/coach_students'
import * as guest from '~/store/guest'
import * as login from '~/store/login'
import * as media from '~/store/media'
import * as postal_codes from '~/store/postal_codes'
import * as profile from '~/store/profile'
import * as push_notifications from '~/store/push-notifications'
import * as student_curriculum from '~/store/student_curriculum'
import * as student_drives from '~/store/student_drives'
import * as student_explore from '~/store/student_explore'
import * as student_wallet from '~/store/student_wallet'

export const state = () => ({
  mapsScriptLoaded: false,
  lastUsedLanguage:
    getLastUsedLanguage() ||
    navigator.language.split('-')[0] ||
    DEFAULT_LANGUAGE,
  isSummarySearchPreferenceOpen: false,
  datatransLibLoaded: false,
  paymentButtonLibLoaded: false,
})

export const getters = getterTree(state, {
  lastUsedLanguageGetter: () => (app: Vue) => {
    const browserLocale = navigator.language.split('-')[0]
    return getLastUsedLanguage() ||
      app.$i18n.availableLocales.includes(browserLocale)
      ? browserLocale
      : DEFAULT_LANGUAGE
  },
  /** Check whether the currently running platform is native (ios, android).
   * @returns {Boolean} */
  isNativePlatform: () => Capacitor.isNativePlatform(),
  /** Get the name of the platform the app is currently running on: web, ios, android.
   * @returns {String} */
  getPlatform: () => Capacitor.getPlatform(),
  /** Returns getter as a function, which can be used to execute code on a specific platform
   * for a platformName argument should be used properties of PLATFORMS Object from constants.js file
   * @returns {Function} */
  isCurrentPlatform:
    (_, { getPlatform }) =>
    (platformName: string) =>
      getPlatform === platformName,
  baseUrl: (_state, getters) =>
    // in native devices window.location.origin is wrong
    getters.isNativePlatform
      ? new URL(process.env.BROWSER_BASE_URL as string).origin // remove slash in the end, full URL expected
      : window.location.origin,
})
export const mutations = mutationTree(state, {
  setMapsScriptLoaded(_state, loaded) {
    _state.mapsScriptLoaded = loaded
  },
  setDatatransLibLoaded(_state, loaded) {
    _state.datatransLibLoaded = loaded
  },
  setPaymentButtonLibLoaded(_state, loaded) {
    _state.paymentButtonLibLoaded = loaded
  },
  setLastUsedLanguage(_state, language) {
    _state.lastUsedLanguage = language
    setLastUsedLanguage(language)
    // @ts-ignore
    this.$axios.setHeader('Accept-Language', language)
  },
  setIsSummarySearchPreferenceOpen(_state, collapsed) {
    _state.isSummarySearchPreferenceOpen = collapsed
  },
})
export const actions = actionTree(
  { state, mutations, getters },
  {
    setMapsScriptLoaded({ commit }, loaded) {
      commit('setMapsScriptLoaded', loaded)
    },
    setDatatransLibLoaded({ commit }, loaded) {
      commit('setDatatransLibLoaded', loaded)
    },
    setPaymentButtonLibLoaded({ commit }, loaded) {
      commit('setPaymentButtonLibLoaded', loaded)
    },

    applyAppLanguage({ commit }, lang) {
      const language = toLower(lang || 'en')
      // @ts-ignore
      if (this.app.i18n.locale) {
        // @ts-ignore
        this.app.i18n.locale = language
      }
      commit('setLastUsedLanguage', language)
    },
    openSummarySearchPreference({ commit }) {
      commit('setIsSummarySearchPreferenceOpen', true)
    },
    closeSummarySearchPreference({ commit }) {
      commit('setIsSummarySearchPreferenceOpen', false)
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
  modules: {
    app_snackbars,
    coach_bank_account,
    coach_cancellation_policy,
    coach_curriculum,
    coach_loyalty,
    coach_notifications,
    coach_profile_media,
    coach_schedule,
    coach_students,
    guest,
    login,
    media,
    postal_codes,
    profile,
    push_notifications,
    student_curriculum,
    student_drives,
    student_explore,
    student_wallet,
  },
})
