import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _efd29650 = () => interopDefault(import('../pages/coachNotifications.vue' /* webpackChunkName: "pages/coachNotifications" */))
const _b10379ce = () => interopDefault(import('../pages/coachProfile.vue' /* webpackChunkName: "pages/coachProfile" */))
const _473c8957 = () => interopDefault(import('../pages/coachSchedule.vue' /* webpackChunkName: "pages/coachSchedule" */))
const _01e453d8 = () => interopDefault(import('../pages/coachStudents.vue' /* webpackChunkName: "pages/coachStudents" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _55de4558 = () => interopDefault(import('../pages/redirectStudentProfile.vue' /* webpackChunkName: "pages/redirectStudentProfile" */))
const _3343766c = () => interopDefault(import('../pages/studentDrives.vue' /* webpackChunkName: "pages/studentDrives" */))
const _454ac642 = () => interopDefault(import('../pages/studentExplore.vue' /* webpackChunkName: "pages/studentExplore" */))
const _b3904b50 = () => interopDefault(import('../pages/studentProfile.vue' /* webpackChunkName: "pages/studentProfile" */))
const _05364e8e = () => interopDefault(import('../pages/studentProgress.vue' /* webpackChunkName: "pages/studentProgress" */))
const _4e273970 = () => interopDefault(import('../pages/universalLogin.vue' /* webpackChunkName: "pages/universalLogin" */))
const _0287c9e7 = () => interopDefault(import('../pages/invitation/_invitationId.vue' /* webpackChunkName: "pages/invitation/_invitationId" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/coachNotifications",
    component: _efd29650,
    name: "coachNotifications"
  }, {
    path: "/coachProfile",
    component: _b10379ce,
    name: "coachProfile"
  }, {
    path: "/coachSchedule",
    component: _473c8957,
    name: "coachSchedule"
  }, {
    path: "/coachStudents",
    component: _01e453d8,
    name: "coachStudents"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/redirectStudentProfile",
    component: _55de4558,
    name: "redirectStudentProfile"
  }, {
    path: "/studentDrives",
    component: _3343766c,
    name: "studentDrives"
  }, {
    path: "/studentExplore",
    component: _454ac642,
    name: "studentExplore"
  }, {
    path: "/studentProfile",
    component: _b3904b50,
    name: "studentProfile"
  }, {
    path: "/studentProgress",
    component: _05364e8e,
    name: "studentProgress"
  }, {
    path: "/universalLogin",
    component: _4e273970,
    name: "universalLogin"
  }, {
    path: "/invitation/:invitationId?",
    component: _0287c9e7,
    name: "invitation-invitationId"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
