
import { mapActions } from 'vuex'
import { getSmallestImageURL } from '../../utils/commonutils'
import FailableImage from './FailableImage.vue'
import FileInputArea from './FileInputArea'

export default {
  name: 'AvatarPhoto',
  components: { FileInputArea, FailableImage },
  props: {
    value: { type: [Object, String], default: null },
    hideLabel: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    avatarURL() {
      return getSmallestImageURL(this.value)
    },
    supportedAvatarFileTypes() {
      return ['image/png', 'image/jpg', 'image/jpeg'].join(',')
    },
  },
  methods: {
    ...mapActions('media', ['addImageToBucket']),
    async avatarAdded(file) {
      if (this.loading) {
        return
      }
      try {
        this.loading = true
        const fileData = await this.addImageToBucket(file)
        this.loading = false
        return this.$emit('added', fileData)
      } catch (e) {
        // TODO errors render
        this.loading = false
      }
    },
  },
}
