import { mapActions, mapGetters, mapState } from 'vuex'
import { pathOr, pathSatisfies, pipe, where, any } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'

const outerCoordsCoverTheWorld = [
  {
    lat: 180,
    lng: 180,
  },
  {
    lat: 90,
    lng: 180,
  },
  {
    lat: 0,
    lng: 180,
  },
  {
    lat: -90,
    lng: 180,
  },
  {
    lat: -180,
    lng: 180,
  },
  {
    lat: -180,
    lng: 0,
  },
  {
    lat: -180,
    lng: -180,
  },
  {
    lat: -90,
    lng: -180,
  },
  {
    lat: 0,
    lng: -180,
  },
  {
    lat: 90,
    lng: -180,
  },
  {
    lat: 180,
    lng: -180,
  },
  {
    lat: 180,
    lng: 0,
  },
  {
    lat: 180,
    lng: 180,
  },
]
export default {
  head() {
    return {
      script: [
        {
          once: true,
          skip: this.mapsScriptLoaded,
          src: this.googleMapsURI,
          callback: () => this.setMapsScriptLoaded(true),
        },
      ],
    }
  },
  created() {
    // workaround to make sure that google maps script is loaded before we try to use it
    window.setMapLoaded = this.setMapLoaded
  },
  computed: {
    ...mapState(['mapsScriptLoaded']),
    ...mapGetters(['isNativePlatform']),
    googleMapsURI() {
      // TODO PlaceSingleSelect.vue initialization does not work as expected since 3.48 API version. Will req`uire careful upgrade/investigation.
      return `https://maps.googleapis.com/maps/api/js?key=${this.$config.google.API_KEY}&libraries=places,geometry,marker&language=${this.$i18n.locale}`
    },
    validAreas() {
      const areas = this.additionalAreas || this.areasOfOperation || []
      return areas.filter(this.areaHasValidCoordinates)
    },
    validAreasInBoundingBox() {
      return this.validAreas.filter(this.areaIsInBoundingBox)
    },
  },
  methods: {
    ...mapActions(['setMapsScriptLoaded']),
    getWorldPolygonWithHoles(holes) {
      const holePolygons = holes
        .map(pathOr([], ['coordinates', 'coordinates', '0', '0']))
        .map((coords) => coords.map(([lng, lat]) => ({ lat, lng })))
      return new window.google.maps.Polygon({
        paths: [outerCoordsCoverTheWorld, ...holePolygons],
        strokeColor: '#000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#000',
        fillOpacity: 0.35,
        clickable: false,
      })
    },
    canRerender() {
      return !!window.google && !!this.$el && !!this.map
    },
    areaHasValidCoordinates(area) {
      return where({
        coordinates: pathSatisfies(isNotNilOrEmpty, ['coordinates', '0', '0']),
      })(area)
    },
    polygonHasPoint(polygon, latLngClassInstance) {
      return window.google.maps.geometry.poly.containsLocation(
        latLngClassInstance,
        polygon
      )
    },
    checkPlaceIsOutOfArea(latLng) {
      let is_out_of_area = false
      try {
        if (this.validAreas.length) {
          is_out_of_area = !this.polygonHasPoint(
            this.getWorldPolygonWithHoles(this.validAreas),
            latLng
          )
        }
      } catch (e) {}
      return is_out_of_area
    },
    areaIsInBoundingBox(area) {
      if (this.boundingBox && this.boundingBox.contains) {
        return pipe(
          pathOr([], ['coordinates', '0']),
          any(([lng, lat]) => this.boundingBox.contains({ lat, lng }))
        )(area)
      } else {
        return true
      }
    },
  },
}
