
/* global google */
/* eslint no-undef: "error" */
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { createHTMLMapMarker } from '../../../../utils/HTMLMapMarker'
import MapsHelperMixin from '../../../../mixins/MapsHelperMixin'

export default {
  mixins: [MapsHelperMixin],
  props: {
    overlayMessage: { type: [String, Boolean], default: '' },
    places: {
      type: Array,
      default() {
        return []
      },
    },
    mapOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    value: {
      type: [String, Number],
      default: '',
    },
    nameLabels: {
      type: Boolean,
      default: false,
    },
    areasOfOperation: {
      type: Array,
      default() {
        return []
      },
    },
    hideLink: {
      type: Boolean,
      default: false,
    },
    clustering: {
      type: Boolean,
      default: false,
    },
    fitAreas: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      geoCoder: null,
      markers: [],
      polygons: [],
      clusterer: null,
    }
  },
  watch: {
    mapsScriptLoaded: {
      handler(loaded) {
        if (loaded) {
          this.initMap()
        }
      },
      immediate: true,
    },
    places: {
      handler: 'updatePlaces',
      deep: true,
      immediate: true,
    },
    value: {
      handler: 'updatePlaces',
      immediate: true,
    },
    areasOfOperation: {
      handler: 'renderAreasOfOperation',
      immediate: true,
    },
  },
  mounted() {
    // required delay to let map calculate its visible tiles correctly
    setTimeout(() => this.initMap(), 800)
  },
  methods: {
    initMap() {
      if (!window.google || !this.$el) {
        return
      }
      this.map = new google.maps.Map(this.$el.querySelector('.mapWidget'), {
        ...this.mapOptions,
        center: { lat: 0, lng: 0 },
        clickableIcons: false,
        maxZoom: Math.max(this.mapOptions.maxZoom, 16),
        disableDefaultUI: true,
      })
      this.$once('hook:beforeDestroy', () => {
        this.map = null
      })
      this.updatePlaces()
    },

    updatePlaces() {
      if (!window.google || !this.map) {
        return
      }
      this.markers.forEach((m) => m.setMap(null))
      this.markers = []
      this.places.forEach((place) => {
        const { latitude, longitude, name, id } = place
        let marker
        if (this.nameLabels) {
          marker = createHTMLMapMarker({
            google: window.google,
            latlng: new window.google.maps.LatLng({
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            }),
            map: this.map,
            html: `<div class="mapSelectionMarker rounded-lg pa-2 cursor-pointer ${
              id === this.value
                ? 'selected amber darken-2'
                : 'white--text black'
            } ">${name}</div>`,
          })
        } else {
          marker = new google.maps.Marker({
            position: { lat: latitude, lng: longitude },
            icon: require(`~/assets/icons/pin_logo${
              id === this.value ? '_active' : ''
            }.svg`),
            map: this.map,
          })
        }

        marker.addListener('click', () => {
          this.$emit('input', id)
          const is_out_of_area = this.checkPlaceIsOutOfArea(
            new window.google.maps.LatLng({
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            })
          )
          if (is_out_of_area) {
            this.$emit('out-of-area', place)
          }
        })
        this.markers.push(marker)
      })
      if (this.clustering) {
        if (this.clusterer) {
          this.clusterer.setMap(null)
          this.clusterer = null
        }
        this.clusterer = new MarkerClusterer({
          markers: this.markers,
          map: this.map,
          renderer: {
            render: ({ position, count }) => {
              return new google.maps.Marker({
                icon: {
                  url: require(`~/assets/icons/circle.svg`),
                  scaledSize: new google.maps.Size(45, 45),
                },
                label: {
                  text: String(count),
                  color: 'white',
                  fontSize: '10px',
                },
                position,
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
              })
            },
          },
        })
      }
      this.renderAreasOfOperation()
      this.setMapBoundsToFitPlaces()
    },
    setMapBoundsToFitPlaces() {
      if (!this.canRerender()) {
        return
      }
      const bounds = new google.maps.LatLngBounds()
      if (this.markers.length && !this.fitAreas) {
        this.markers.forEach((marker) => {
          bounds.extend(marker.getPosition())
        })
        this.map.fitBounds(bounds, 20)
      } else if (this.validAreas.length) {
        this.validAreas.forEach(({ coordinates }) => {
          if (coordinates?.coordinates[0][0]) {
            coordinates?.coordinates[0][0]
              .map(([lng, lat]) => ({ lat, lng }))
              .map((latLng) => bounds.extend(latLng))
          }
        })
        this.map.fitBounds(bounds, 20)
      }
    },
    renderAreasOfOperation() {
      if (!this.canRerender()) {
        return
      }
      if (this.validAreas.length) {
        this.polygons.forEach((p) => p.setMap(null))
        this.polygons = []
        const polygon = this.getWorldPolygonWithHoles(this.validAreas)
        polygon.setMap(this.map)
        this.polygons.push(polygon)
      }
    },
  },
}
