import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { path } from 'ramda'

import axios from 'axios'

export const state = () => ({})
export const getters = getterTree(state, {})
export const mutations = mutationTree(state, {})
export const actions = actionTree(
  { state, getters, mutations },
  {
    addImageToBucket(_, image) {
      const data = new FormData()
      data.append('image', image)
      return this.$axios.post(`api/preload/images/`, data).then(path(['data']))
    },
    addImageToPublicBucket(_, image) {
      const data = new FormData()
      data.append('image', image)
      return this.$axios
        .post(`api/preload/public-images/`, data)
        .then(path(['data']))
    },
    addVideoToBucket(_, video) {
      const data = {
        action: 'PUT',
        content_type: video.type,
      }
      return this.$axios
        .post(`api/preload/signed-url/`, data)
        .then(async (obj) => {
          // in this case we use new axios instance because gcloud does not handle other's headers
          await axios.put(obj.data.url, video, {
            headers: { 'Content-Type': data.content_type },
          })
          return this.$axios
            .post(`api/preload/from-storage/`, { name: obj.data.name })
            .then(path(['data']))
        })
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
