import {
  filter,
  fromPairs,
  has,
  map,
  pipe,
  toPairs,
  when,
  isEmpty,
} from 'ramda'
import { isString } from 'ramda-adjunct'
export const mediaDataIsCorrupted = (value) => {
  return has('name', value) && isEmpty(value.name)
}
export const prepareMedia = (formData, mediaFields) => {
  // sensible media values for patch - null and fileName
  // string means not updated
  return pipe(
    toPairs,
    filter(
      ([key, value]) =>
        !(
          mediaFields.includes(key) &&
          (isString(value) || mediaDataIsCorrupted(value))
        )
    ),
    map(
      when(
        ([key, value]) => mediaFields.includes(key) && has('name', value),
        ([key, value]) => [key, value.name]
      )
    ),
    fromPairs
  )(formData)
}
export default {
  methods: {
    prepareMedia(formData) {
      return prepareMedia(formData, this.mediaFields)
    },
  },
}
