
import { Camera } from '@capacitor/camera'
import { includes, isEmpty, last } from 'ramda'
import { mapGetters } from 'vuex'
import ResponsiveScreenHelperMixin from '~/mixins/ResponsiveScreenHelperMixin'
/**
 * @description
 * Handles file drop or click scenario. <br>
 * Can be used for creating styled droppable areas. <br>
 * See LogoComponent for example. <br>
 * Beware that nested buttons consume click event.
 */
export default {
  name: 'FileInputArea',
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    /**
     * Native input accept attribute. <br>
     * A comma-separated list of mime types or file extensions is expected.
     */
    accept: {
      required: false,
      default: '',
      type: String,
    },
    ratio: {
      type: Number,
      default: null,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      inputId: `id_${Math.random() + Date.now()}`,
      imgURL: '',
      showClipperDialog: false,
    }
  },
  computed: {
    ...mapGetters(['isNativePlatform']),
    isImageInput() {
      return ['image/png', 'image/jpg', 'image/jpeg'].join(',') === this.accept
    },
  },
  methods: {
    onFileChange(e) {
      this.handleFileChanged(e.target.files[0])
    },
    allowDrop(e) {
      // by default drop is turned off
      e.preventDefault()
    },
    onFileDrop(e) {
      this.handleFileChanged(e.dataTransfer.files[0])
      // prevent opening dropped image in new tab
      e.preventDefault()
    },
    handleFileChanged(file) {
      const fileTypes = this.accept.split(',')
      const mimeTypeMatches = includes(file.type, fileTypes)
      const fileExtensionMatches = includes(
        last(file.name.split('.')),
        fileTypes
      )
      if (
        this.accept === '' ||
        isEmpty(fileTypes) ||
        mimeTypeMatches ||
        fileExtensionMatches
      ) {
        this.$emit('file', file)
      }
      // Clear input, so that event will be re-triggered on file upload with the same name
      this.$refs.fileInput.value = ''
    },
    onClose() {
      this.showClipperDialog = false
      window.URL.revokeObjectURL(this.imgURL)
      this.imgURL = ''
    },
    async onInput(e) {
      if (this.isNativePlatform) {
        await Camera.requestPermissions()
      }
      if (!this.isImageInput) {
        this.onFileChange(e)
        return
      }
      this.showClipperDialog = true
      if (this.imgURL) window.URL.revokeObjectURL(this.imgURL)
      this.imgURL = URL.createObjectURL(this.$refs.fileInput.files[0])
    },
    async clipFile() {
      await this.$refs.clipper.clip()
      const canvas = this.$refs.clipper.clip()
      const url = canvas.toDataURL('image/jpeg', 1)
      const blob = await (await fetch(url)).blob()
      const file = new File([blob], 'image.jpeg', { type: blob.type })
      this.$emit('file', file)
      this.onClose()
    },
  },
}
