import { render, staticRenderFns } from "./BookingSummarySection.vue?vue&type=template&id=8404ecee&scoped=true&"
import script from "./BookingSummarySection.vue?vue&type=script&lang=js&"
export * from "./BookingSummarySection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8404ecee",
  null
  
)

export default component.exports