
import CardWrapper from '../../../shared/CardWrapper'
import CarDetailsMixin from '../../../../mixins/CarDetailsMixin'
import DeleteDialog from './DeleteCarDialog'
import FailableImage from '~/components/shared/FailableImage.vue'
import WidgetTagBar from '~/components/coach/coach_schedule/event_content/WidgetTagBar.vue'

export default {
  name: 'CarItem',
  components: { CardWrapper, DeleteDialog, FailableImage, WidgetTagBar },
  mixins: [CarDetailsMixin],
  props: {
    car: {
      type: Object,
      default() {
        return {}
      },
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDeleteConfirmation: false,
    }
  },
  computed: {},
  methods: {
    onDelete() {
      this.showDeleteConfirmation = false
      this.$emit('delete')
    },
  },
}
