export const PLATFORMS = {
  WEB: 'web',
  IOS: 'ios',
  ANDROID: 'android',
}
export const COACH_ROLE = 2
export const STUDENT_ROLE = 1
export const MIN_LENGTH_PASSWORD = 8
export const DEFAULT_LANGUAGE = 'en'
export const EN_LANGUAGE = 'en'
export const DEFAULT_TEMPLATE_LESSON_DURATION = 45
export const DEFAULT_TEMPLATE_BREAK_TIME = 15
export const DEFAULT_LOCATION = {
  lat: 47.20665010018134,
  lng: 7.533733957918529,
}
export const AT_GEARBOX = 'AT'
export const MT_GEARBOX = 'MT'
export const gearboxes = {
  [AT_GEARBOX]: 'gearboxes.automatic',
  [MT_GEARBOX]: 'gearboxes.manual',
}

export const languages = {
  en: 'languages.en',
  de: 'languages.de',
  fr: 'languages.fr',
  it: 'languages.it',
  sq: 'languages.sq',
  tr: 'languages.tr',
  es: 'languages.es',
  pt: 'languages.pt',
  sr: 'languages.sr',
  hr: 'languages.hr',
  ku: 'languages.ku',
  ta: 'languages.ta',
  ar: 'languages.ar',
  bn: 'languages.bn',
  bg: 'languages.bg',
  cs: 'languages.cs',
  da: 'languages.da',
  nl: 'languages.nl',
  et: 'languages.et',
  fi: 'languages.fi',
  gr: 'languages.gr',
  hi: 'languages.hi',
  hu: 'languages.hu',
  id: 'languages.id',
  lv: 'languages.lv',
  lt: 'languages.lt',
  mt: 'languages.mt',
  zh: 'languages.zh',
  fa: 'languages.fa',
  pl: 'languages.pl',
  ro: 'languages.ro',
  ru: 'languages.ru',
  sk: 'languages.sk',
  si: 'languages.si',
  se: 'languages.se',
  ua: 'languages.ua',
}
export const PAYMENT_METHOD_TWINT = 1
export const PAYMENT_METHOD_VISA = 2
export const PAYMENT_METHOD_MASTERCARD = 3
export const PAYMENT_METHOD_APPLE_PAY = 4
export const PAYMENT_METHOD_GOOGLE_PAY = 5
export const PAYMENT_METHOD_CASH = 7
// indicates a group of all electronic payments on FE
export const PAYMENT_METHOD_ELECTRONIC = 99
// indicates virtual carzi currency
export const PAYMENT_METHOD_COINS = 100
export const PAYMENT_METHOD_COINS_PARTIAL = 101

export const ELECTRONIC_PAYMENT_METHODS = [
  PAYMENT_METHOD_TWINT,
  PAYMENT_METHOD_VISA,
  PAYMENT_METHOD_MASTERCARD,
  PAYMENT_METHOD_APPLE_PAY,
  PAYMENT_METHOD_GOOGLE_PAY,
]
export const externalPaymentMethods = {
  [PAYMENT_METHOD_TWINT]: 'TWI',
  [PAYMENT_METHOD_VISA]: 'VIS',
  [PAYMENT_METHOD_MASTERCARD]: 'ECA',
  [PAYMENT_METHOD_APPLE_PAY]: 'APL',
  [PAYMENT_METHOD_GOOGLE_PAY]: 'PAY',
  [PAYMENT_METHOD_CASH]: 'CASH',
}
export const paymentMethods = {
  [PAYMENT_METHOD_TWINT]: 'paymentMethods.twint',
  [PAYMENT_METHOD_VISA]: 'paymentMethods.visa',
  [PAYMENT_METHOD_MASTERCARD]: 'paymentMethods.masterCard',
  [PAYMENT_METHOD_APPLE_PAY]: 'paymentMethods.apple',
  [PAYMENT_METHOD_GOOGLE_PAY]: 'paymentMethods.google',
  [PAYMENT_METHOD_CASH]: 'paymentMethods.cash',
}
export const paymentMethodLabels = {
  [PAYMENT_METHOD_TWINT]: 'paymentMethods.twint',
  [PAYMENT_METHOD_VISA]: 'paymentMethods.visa',
  [PAYMENT_METHOD_MASTERCARD]: 'paymentMethods.masterCard',
  [PAYMENT_METHOD_APPLE_PAY]: 'paymentMethods.apple',
  [PAYMENT_METHOD_GOOGLE_PAY]: 'paymentMethods.google',
  [PAYMENT_METHOD_CASH]: 'paymentMethods.cash',
  [PAYMENT_METHOD_ELECTRONIC]: 'paymentMethods.electronic',
  [PAYMENT_METHOD_COINS]: 'paymentMethods.coins',
  [PAYMENT_METHOD_COINS_PARTIAL]: 'paymentMethods.coinsPartial',
}
export const paymentMethodIcons = {
  [PAYMENT_METHOD_TWINT]: require('~/assets/icons/twint.svg'),
  [PAYMENT_METHOD_VISA]: require('~/assets/icons/visa.svg'),
  [PAYMENT_METHOD_MASTERCARD]: require('~/assets/icons/mastercard.svg'),
  [PAYMENT_METHOD_APPLE_PAY]: require('~/assets/icons/apple.svg'),
  [PAYMENT_METHOD_GOOGLE_PAY]: require('~/assets/icons/google.svg'),
  [PAYMENT_METHOD_CASH]: require('~/assets/icons/cash.svg'),
  [PAYMENT_METHOD_ELECTRONIC]: require('~/assets/icons/epayments.svg'),
  [PAYMENT_METHOD_COINS]: require('~/assets/icons/carzi_coins.svg'),
  [PAYMENT_METHOD_COINS_PARTIAL]: require('~/assets/icons/carzi_coins.svg'),
}

export const COINS_PAYMENT_METHOD_STUB_ID = 'COINS'
export const CASH_PAYMENT_METHOD_STUB_ID = 'CASH'
export const GOOGLE_PAYMENT_METHOD_STUB_ID = 'GOOGLE'
export const APPLE_PAYMENT_METHOD_STUB_ID = 'APPLE'
export const APL_GGL_CASH_STUB_IDS = {
  [PAYMENT_METHOD_APPLE_PAY]: APPLE_PAYMENT_METHOD_STUB_ID,
  [PAYMENT_METHOD_GOOGLE_PAY]: GOOGLE_PAYMENT_METHOD_STUB_ID,
  [PAYMENT_METHOD_CASH]: CASH_PAYMENT_METHOD_STUB_ID,
}
export const PAYMENT_TRANSACTION_SETTLED = 'settled'
export const PAYMENT_TRANSACTION_REFUNDED = 'refunded'
export const PAYMENT_TRANSACTION_PARTIALLY_REFUNDED = 'partially refunded'
export const PAYMENT_TRANSACTION_CANCELED = 'canceled'
export const PAYMENT_TRANSACTION_FAILED = 'failed'
export const calendarView = {
  timeGridDay: 'timeGridDay',
  timeGridWeek: 'timeGridWeek',
  dayGridMonth: 'dayGridMonth',
  schedule: 'schedule',
  scheduleDeletable: 'schedule:deletable',
}
export const RATING_SORT_PROP = 'average_rating'
export const PRICE_SORT_PROP = 'price_per_lesson'
export const PRICE_PER_HOUR_SORT_PROP = 'price_per_hour'
export const REVIEWS_COUNT_SORT_PROP = 'review_count_value'
export const LESSON_STATUS_STUDENT_APPROVED = 1
export const LESSON_STATUS_COACH_APPROVED = 2
export const LESSON_STATUS_BOTH_APPROVED = 3
export const LESSON_STATUS_STUDENT_CANCELLED = 4
export const LESSON_STATUS_COACH_CANCELLED = 5
export const LESSON_STATUS_COMPLETED = 6
export const LESSON_STATUS_DID_NOT_TAKE_PLACE = 7

export const STUDENT_COURSE_STATUS_INVITED = 1
export const STUDENT_COURSE_STATUS_CONFIRMED = 2
export const STUDENT_COURSE_STATUS_CANCELED = 3

export const COACH_COURSE_STATUS_OPENED = 1
export const COACH_COURSE_STATUS_CANCELED = 2
export const COACH_COURSE_STATUS_COMPLETED = 3

export const NO_BOOKING_DATA = 'no booking data'
export const MESSAGES = {
  errors: {
    bookingError: 'bookingError',
  },
  success: {
    lessonIsBooked: 'studentNotifications.lessonBookSuccessMessage',
  },
}

export const TIME_SELECTION_SUMMARY_DATE_FORMAT = 'EEEE, d LLL • HH:mm'
export const LESSON_DETAILS_SUMMARY_DATE_FORMAT = 'EEEE d LLL, HH:mm'
export const LESSON_CARD_SUMMARY_DATE_FORMAT = 'EEE d LLL, HH:mm'
export const BASE_DATE_FORMAT = 'yyyy-MM-dd'

export const TOPIC_GRADE_DISCUSSED = 0
export const TOPIC_GRADE_INSUFFICIENT = 1
export const TOPIC_GRADE_GOOD = 2
export const TOPIC_GRADE_READY = 3
export const TOPIC_GRADE_REMOVE = 'REMOVE'
export const TOPIC_GRADE_ADD = 'ADD'
export const TOPIC_GRADE_TITLES = {
  [TOPIC_GRADE_DISCUSSED]: 'topicGradeMenu.discussed',
  [TOPIC_GRADE_INSUFFICIENT]: 'topicGradeMenu.insufficient',
  [TOPIC_GRADE_GOOD]: 'topicGradeMenu.good',
  [TOPIC_GRADE_READY]: 'topicGradeMenu.ready',
}

export const calendarZoomLevel = {
  small: 1,
  medium: 2,
  large: 3,
}

export const exportFileTypes = {
  XLSX: 'xlsx',
  PDF: 'pdf',
  VCF: 'text/x-vcard',
}

export const slotTypes = {
  lesson: 'LESSON',
  exam: 'EXAM',
}

export const MAX_PACKAGES_COUNT = 5

export const EXAM_STATUS = {
  NOT_RATED: 0,
  PASSED: 1,
  NOT_PASSED: 2,
}
export const MAX_COURSE_CAPACITY = 99
export const CANCEL_REASON_MAX_LENGTH = 255
export const RATING_FILTER_OFFSET = 0.2 // is used to keep filtering and displaying logic consistant (i.e. 3 stars filter should show 2.8+ average rating)

export const COACH_GENDER_MALE = 1
export const COACH_GENDER_FEMALE = 2
export const COACH_GENDER_ALL = 'all'
export const GENDER_LABELS = {
  [COACH_GENDER_MALE]: 'coachGender.male',
  [COACH_GENDER_FEMALE]: 'coachGender.female',
  [COACH_GENDER_ALL]: 'coachGender.all',
}

export const ALLOWED_WIZARD_TIMEFRAME = 84

export const removableItemTypes = {
  COURSE: 'course',
  LESSON: 'lesson',
  EXAM: 'exam',
  EMPTY_SLOT: 'empty_slot',
}

export const studentStatus = {
  ACTIVE: 1,
  INACTIVE: 2,
}
