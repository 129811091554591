
import DialogWrapper from '~/components/shared/DialogWrapper'
export default {
  name: 'ConfirmDeleteFormNotificationDialog',
  components: { DialogWrapper },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
}
