
import numeral from 'numeral'
import { pathOr, pathEq, pipe } from 'ramda'
import { isFuture, differenceInHours, parseISO } from 'date-fns'
import DialogWrapper from '../../../shared/DialogWrapper'
import CardWrapper from '../../../shared/CardWrapper'
import { formatFullName } from '../../../../utils/commonutils'
import { slotTypes } from '../../../../utils/constants'
import PolicyInfoMenuMixin from '../../../../mixins/PolicyInfoMenuMixin'

export default {
  name: 'CancelLessonDialog',
  components: { DialogWrapper, CardWrapper },
  mixins: [PolicyInfoMenuMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    approvedByCoach: {
      type: Boolean,
      default: false,
    },
    lessonDetails: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    cancellationPolicyPath() {
      return ['cancellation_policy']
    },
    refundable() {
      return pathEq(['order', 'is_cash'], false, this.lessonDetails)
    },
    purchased() {
      return pathEq(
        ['is_electronic_payment_required'],
        false,
        this.lessonDetails
      )
    },
    purchasedAndRefundable() {
      // These predicates make sense only when checked together
      // and before lesson is finished
      return this.purchased && this.refundable
    },
    hoursUntilStart() {
      const startDate = parseISO(this.lessonDetails.slot_start)
      if (isFuture(startDate)) {
        return differenceInHours(startDate, new Date())
      }
      return 0
    },
    policyApplies() {
      return this.hoursUntilStart < this.cancellationPolicy.time
    },
    refundSum() {
      const lessonPrice = pathOr('', ['price'], this.lessonDetails)
      if (this.showPolicy) {
        return numeral(+lessonPrice)
          .divide(100)
          .multiply(100 - this.cancellationPolicy.fee)
          .format('0.00')
      }
      return lessonPrice
    },
    coachName() {
      return pipe(pathOr({}, ['coach']), formatFullName)(this.lessonDetails)
    },
    showPolicy() {
      return (
        this.purchasedAndRefundable &&
        this.hasCancellationPolicy &&
        this.policyApplies
      )
    },
    isExam() {
      return pathEq(['type'], slotTypes.exam, this.lessonDetails)
    },
  },
  methods: {
    onCancel() {
      this.$emit('confirm')
      this.dialog = false
    },
  },
}
