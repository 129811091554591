
import {
  filter,
  path,
  pathOr,
  pipe,
  map,
  whereEq,
  pick,
  includes,
  where,
  __,
  prop,
  reduce,
  clone,
  equals,
  sortBy,
} from 'ramda'
import {
  Smartlook,
  SmartlookCustomEvent,
} from '@awesome-cordova-plugins/smartlook'
import numeral from 'numeral'
// eslint-disable-next-line
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { mapActions, mapGetters, mapState } from 'vuex'
import { parseISO, formatISO, addDays } from 'date-fns'
import ResponsiveScreenHelperMixin from '../../../../mixins/ResponsiveScreenHelperMixin'
import FormHelperMixin from '../../../../mixins/FormHelperMixin'
import SectionTitle from '../../../shared/SectionTitle'
import {
  AT_GEARBOX,
  MT_GEARBOX,
  PAYMENT_METHOD_APPLE_PAY,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_COINS,
  PAYMENT_METHOD_GOOGLE_PAY,
  PAYMENT_METHOD_MASTERCARD,
  PAYMENT_METHOD_TWINT,
  PAYMENT_METHOD_VISA,
} from '../../../../utils/constants'
import CardWrapper from '../../../shared/CardWrapper'
import {
  copyToClipBoard,
  mapCoachToRefSlot,
  studentProfileComplete,
} from '../../../../utils/commonutils'
import EditStudentDetails from '../../student_profile/EditStudentDetails'
import PackageItem from '../../../shared/coach_loyalty_packages/PackageItem'
import CourseDetailsDialog from '../../student_drives/course_details_dialog/CourseDetailsDialog'
import CoachReview from '../../../shared/CoachReview'
import CarItem from '../../../../components/student/student_explore/booking/cars_tab/CarItem.vue'
import VTabWithName from '../../../../utils/VTabWithName'
import CourseOffersList from './offers/CourseOffersList'
import CoachesListMenu from './CoachesListMenu'
import PackageDetailsDialog from './PackageDetailsDialog'
import LessonDetailsHeader from './LessonDetailsHeader'
import CarSingleSelect from './CarSingleSelect'
import LessonSelection from './selection_list/LessonSelection'
import ShortLessonDetails from './ShortLessonDetails'
import LessonPlaceSelection from './LessonPlaceSelection'
import PaymentActionButton from './payment/PaymentActionButton'
import BookingSummarySection from './BookingSummarySection'
import ConfirmPrebookLessonDialog from './ConfirmPrebookLessonDialog'
import ContactDialog from './contact_dialog/ContactDialog'
import AreasOverviewTab from './areas_tab/AreasOverviewTab'
import TextEditor from '~/components/shared/TextEditor.vue'
export default {
  name: 'BookCoachLessonsDialog',
  components: {
    AreasOverviewTab,
    VTabWithName,
    ContactDialog,
    CarItem,
    CourseDetailsDialog,
    CourseOffersList,
    EditStudentDetails,
    BookingSummarySection,
    PaymentActionButton,
    CardWrapper,
    LessonPlaceSelection,
    ShortLessonDetails,
    LessonSelection,
    CarSingleSelect,
    SectionTitle,
    LessonDetailsHeader,
    PackageItem,
    PackageDetailsDialog,
    CoachesListMenu,
    ConfirmPrebookLessonDialog,
    CoachReview,
    TextEditor,
  },
  mixins: [ResponsiveScreenHelperMixin, FormHelperMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    initialReferenceSlot: {
      type: Object,
      default() {
        return {}
      },
    },
    initialFormState: {
      type: Object,
      default() {
        return {}
      },
    },
    initialPackageSelection: {
      type: Object,
      default() {
        return {}
      },
    },
    initialPaymentMethod: {
      type: [Number, String],
      default: '',
    },
    guestMode: {
      type: Boolean,
      default: false,
    },
    filterState: {
      type: Object,
      default() {
        return {}
      },
    },
    canShowAlternateGearbox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nextPageUri: '',
      lessonsTotalCount: 0,
      busy: false,
      ready: false,
      finishSignup: false,
      signupRequired: false,
      AT_GEARBOX,
      MT_GEARBOX,
      referenceSlot: {},
      formData: {
        slots_ids: [],
      },
      availableSlots: [],
      availableCourses: [],
      showBookingStep: false,
      currentTab: 'cars',
      coachAreasOfOperation: [],
      isPackageDetailsOpen: false,
      isCourseDetailsOpen: false,
      selectedCourse: {},
      selectedPackage: {},
      isCoachesListOpen: false,
      coachesList: [],
      showBookConfirm: false,
      reviewsData: {
        results: [],
        count: 0,
        next: null,
      },
      showContactDialog: false,
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapGetters('profile', ['profile_active_places']),
    ...mapGetters('student_explore', ['popularCoachesSearchPeriod']),
    ...mapGetters('coach_loyalty', ['packages']),
    ...mapGetters(['isNativePlatform', 'baseUrl']),
    about() {
      return this.lessonsCoachInfo.about
    },
    reviews() {
      return pathOr([], ['results'], this.reviewsData)
    },
    reviewsCount() {
      return this.reviewsData.count
    },
    nextReviewsPage() {
      return pathOr(null, ['next'], this.reviewsData)
    },
    lessonsCoachInfo() {
      return pathOr({}, ['coach'], this.referenceSlot)
    },
    availableCars() {
      // allow user to select offered place even if it is marked inactive
      if (this.filterState.gearbox) {
        return pipe(
          pathOr([], ['cars']),
          filter(whereEq({ gearbox: this.filterState.gearbox }))
        )(this.referenceSlot)
      } else {
        return pathOr([], ['cars'], this.referenceSlot)
      }
    },
    allCoachCars() {
      return pathOr([], ['cars'], this.referenceSlot)
    },
    isNoLessons() {
      return this.availableSlots.length === 0
    },
    isNoValidReferenceSlot() {
      return this.referenceSlot && this.referenceSlot.start === undefined
    },
    availablePlaces() {
      // allow user to select offered place even if it is marked inactive
      return pathOr([], ['pickup_places'], this.referenceSlot)
    },
    allowedCustomPlaces() {
      return (
        !this.guestMode &&
        pathOr(
          false,
          ['coach', 'allow_custom_pickup_places'],
          this.referenceSlot
        )
      )
    },
    studentPlaces() {
      return this.profile_active_places
    },
    allAvailablePlaces() {
      return this.availablePlaces.concat(this.studentPlaces)
    },
    bookLessonActionTitle() {
      if (this.formData.slots_ids.length > 1) {
        return this.$t('searchBooKLesson.bookMultipleLessonsButton', {
          totalPrice: this.totalPrice,
        })
      }
      return this.$t('searchBooKLesson.bookLessonButton', {
        totalPrice: this.totalPrice,
      })
    },
    totalLessonsPrice() {
      return pipe(
        filter(where({ id: includes(__, this.formData.slots_ids) })),
        map(prop('price')),
        reduce((acc, val) => acc.add(val), numeral(0)),
        (total) => total.format('0.00')
      )(this.availableSlots)
    },
    totalPrice() {
      return `CHF ${this.totalLessonsPrice}`
    },
    isBulkBooking() {
      return this.formData.slots_ids.length > 1
    },
    firstLessonPrice() {
      return pipe(
        filter(where({ id: includes(__, this.formData.slots_ids) })),
        sortBy(prop('start')),
        path([0, 'price'])
      )(this.availableSlots)
    },
    showGearboxFilter() {
      return this.canShowAlternateGearbox
    },
    gearboxFilter() {
      return this.filterState.gearbox
    },
    paymentButtonBookingData() {
      return this.formData
    },
    commonLessonsCars() {
      return pipe(
        filter(where({ id: includes(__, this.formData.slots_ids) })),
        map(prop('cars')),
        (carsLists) => {
          return carsLists.reduce((cars, nextCars) => {
            if (!cars.length) {
              cars = [...nextCars]
            }
            return cars.filter(
              where({
                id: includes(__, nextCars.map(prop('id'))),
                gearbox: equals(this.filterState.gearbox),
              })
            )
          }, [])
        }
      )(this.availableSlots)
    },
    commonLessonsPlaces() {
      return pipe(
        filter(where({ id: includes(__, this.formData.slots_ids) })),
        map(prop('pickup_places')),
        (placesList) =>
          placesList.length
            ? placesList.reduce((places, nextPlaces) => {
                return places.filter(
                  where({
                    id: includes(__, nextPlaces.map(prop('id'))),
                  })
                )
              })
            : []
      )(this.availableSlots)
    },
    coachAllowsElectronicMethods() {
      const lessonPaymentTypes = pathOr(
        [],
        ['payment_methods'],
        this.lessonsCoachInfo
      )
      return lessonPaymentTypes.some(
        includes(__, [
          PAYMENT_METHOD_TWINT,
          PAYMENT_METHOD_VISA,
          PAYMENT_METHOD_MASTERCARD,
          PAYMENT_METHOD_APPLE_PAY,
          PAYMENT_METHOD_GOOGLE_PAY,
        ])
      )
    },
    showPackages() {
      return isNotNilOrEmpty(this.packages) && this.coachAllowsElectronicMethods
    },
  },
  watch: {
    '$route.query.tab': {
      handler(tab) {
        if (tab) {
          this.switchTabTo(tab)
        }
      },
      immediate: true,
    },
    currentTab(tab) {
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, tab },
      })
    },
    initialReferenceSlot: {
      handler() {
        if (isNotNilOrEmpty(this.initialReferenceSlot)) {
          this.referenceSlot = clone(this.initialReferenceSlot)
          this.resetForm()
        }
        this.loadAreasOfOperation()
      },
      deep: true,
      immediate: true,
    },
    initialFormState: {
      handler() {
        if (isNotNilOrEmpty(this.initialFormState)) {
          this.resetForm()
        }
      },
      deep: true,
      immediate: true,
    },
    initialPackageSelection: {
      handler() {
        if (isNotNilOrEmpty(this.initialPackageSelection)) {
          this.$nextTick(() => {
            this.openPackageDetails(this.initialPackageSelection)
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.signupRequired = !studentProfileComplete(this.profile)
    this.currentTab =
      this.$route.query.tab || this.signupRequired ? 'cars' : 'lessons'
    await this.reloadReviews(this.referenceSlot?.coach?.id)
    const id = this.referenceSlot.coach.school?.id
    if (!id) {
      return
    }
    this.coachesList = await this.getCoachesBySchoolId(id)
  },
  destroyed() {
    this.$router.replace({ query: {} })
  },
  methods: {
    ...mapActions('student_explore', [
      'localLessonSearch',
      'getBookableLessonsStartPage',
      'getBookableLessonsNextPage',
      'bookMultipleLessons',
      'setupBookingOnLogin',
      'storeUnfinishedBookingData',
      'clearUnfinishedBookingData',
      'getCoachCourses',
    ]),
    ...mapActions('app_snackbars', [
      'showSnackbar',
      'showError',
      'showWarning',
      'showSuccessNotification',
    ]),
    ...mapActions('guest', {
      updateGuestFilter: 'updateFilter',
      getCoachesBySchoolId: 'getCoachesBySchoolId',
      getCoachDetailsById: 'getCoachDetailsById',
    }),
    ...mapActions('student_explore', {
      updateStudentFilter: 'updateFilter',
    }),
    ...mapActions('postal_codes', ['getCoachAreasOfOperation']),
    ...mapActions('coach_loyalty', ['getPackages']),
    ...mapActions('student_explore', ['getReviews', 'getCoachDetailsById']),
    bookNow() {
      this.$fa.logEvent({ name: 'coach_profile_click_select_date' })
      this.currentTab = 'lessons'
      setTimeout(
        () =>
          this.$refs.dialogTabs.$el.scrollIntoView({
            block: 'start',
            behaviour: 'smooth',
          }),
        300
      )
    },
    async reloadReviews(coachId) {
      if (coachId) {
        try {
          this.reviewsData = await this.getReviews({ coachId })
        } catch (e) {}
      }
    },
    async getMoreReviews() {
      if (this.nextReviewsPage) {
        try {
          const { next, count, results } = await this.getReviews({
            url: this.nextReviewsPage,
          })
          this.reviewsData.count = count
          this.reviewsData.next = next
          this.reviewsData.results = this.reviewsData.results.concat(results)
        } catch (e) {}
      }
    },
    isLastReviewIndex(index) {
      return index === this.reviews.length - 1
    },
    switchTabTo(tab) {
      // $nextTick is used to switch tabs after complete render and avoid positioning issues
      this.$nextTick(() => {
        this.currentTab = tab
      })
    },
    showCoachesDialog() {
      this.isCoachesListOpen = true
    },
    closeCoachesDialog() {
      this.isCoachesListOpen = false
    },
    async selectCoach(coach) {
      await (this.guestMode
        ? this.updateGuestFilter({ coach })
        : this.updateStudentFilter({ coach }))
      const refSlot = await this.localLessonSearch({
        filter: this.getSearchFilterForRecentCoachLessons(coach),
      }).then(path(['0']))
      if (refSlot) {
        this.referenceSlot = refSlot
      } else {
        const coachDetails = await this.getCoachDetailsById(coach)
        this.referenceSlot = mapCoachToRefSlot(coachDetails)
      }
      this.reviewsData = await this.getReviews({ coachId: coach })
      this.$nextTick(() => {
        this.resetForm({ hard: true })
      })
    },
    openPackageDetails(packageItem) {
      this.selectedPackage = packageItem
      this.isPackageDetailsOpen = true
    },
    closePackageDetails() {
      this.isPackageDetailsOpen = false
      this.selectedPackage = {}
    },
    resetForm(options = {}) {
      this.formData = {
        slots_ids: [],
        car: '',
        pickup_place: '',
      }
      this.showBookingStep = false

      if (!this.isNoValidReferenceSlot) {
        this.availableSlots = [this.referenceSlot]
        if (this.initialFormState && !options.hard) {
          this.formData = {
            ...this.formData,
            ...this.initialFormState,
          }
        }
        this.loadRelevantSlots()
      } else {
        this.availableSlots = []
        this.nextPageUri = ''
        this.lessonsTotalCount = 0
      }
      const coachId = pathOr('', ['coach', 'id'])(this.referenceSlot)
      if (coachId) {
        this.loadRelevantCourses()
        this.getPackages(coachId)
      }
    },
    async loadRelevantSlots() {
      try {
        const { results, next, count } = await this.getBookableLessonsStartPage(
          {
            coach_id: this.referenceSlot.coach.id,
            filter: this.getSearchFilterForSimilarLessons(),
          }
        )
        this.availableSlots = results.map((item) => {
          return {
            ...this.referenceSlot,
            ...item,
          }
        })
        this.lessonsTotalCount = count
        this.nextPageUri = next
      } catch (e) {
        this.showRequestErrorMessage(e)
      }
    },
    async loadMoreLessons() {
      try {
        const { results, next } = await this.getBookableLessonsNextPage(
          this.nextPageUri
        )
        this.availableSlots = this.availableSlots.concat(
          results.map((item) => {
            return {
              ...this.referenceSlot,
              ...item,
            }
          })
        )
        this.nextPageUri = next
      } catch (e) {
        this.showRequestErrorMessage(e)
      }
    },
    async loadRelevantCourses() {
      try {
        this.availableCourses = await this.getCoachCourses(
          this.referenceSlot.coach.id
        )
      } catch (e) {
        this.showRequestErrorMessage(e)
      }
    },
    getSearchFilterForSimilarLessons() {
      const rangeStart = parseISO(this.referenceSlot.start)
      return {
        ...pick(['gearbox'], this.filterState),
        start__gte: this.referenceSlot.start,
        end__lte: pipe((date) => addDays(date, 90), formatISO)(rangeStart),
      }
    },
    getSearchFilterForRecentCoachLessons(coachId, gearbox) {
      return {
        gearbox: gearbox || this.filterState.gearbox,
        coach: coachId,
        ...this.popularCoachesSearchPeriod,
      }
    },
    checkGuestAndPrebookCourse(course) {
      if (this.guestMode) {
        this.activityToPrebook = course
          ? {
              course,
              coach: this.lessonsCoachInfo,
              id: '',
              status: '',
              type: 'course',
            }
          : {}
        this.showBookConfirm = true
        return
      }
      this.goToCourseDetails(course)
    },
    checkGuestAndPrebookLesson() {
      if (!this.guestMode) {
        this.forwardToDetailsStep()
        return
      }
      this.showBookConfirm = true
    },
    prebookLesson(payload) {
      const { payment_method, payment_method_type, type } = payload
      // TODO prebooking will be changed soon
      const data = {
        courseDetails: payload,
        formData: {
          ...this.formData,
        },
        relatedFilter: this.filterState,
        payment_method: [PAYMENT_METHOD_CASH, PAYMENT_METHOD_COINS].includes(
          payment_method_type
        )
          ? ''
          : payment_method,
        referenceSlot: this.referenceSlot,
        isPackageDetailsOpen: this.isPackageDetailsOpen,
        selectedPackage: this.selectedPackage,
        type,
      }
      this.setupBookingOnLogin(data).then(() => {
        this.$router.push({ name: 'universalLogin' })
      })
    },
    saveBookingData({ payment_method, payment_method_type, use_coins }) {
      const data = {
        formData: {
          ...this.formData,
        },
        relatedFilter: this.filterState,
        payment_method: [PAYMENT_METHOD_CASH, PAYMENT_METHOD_COINS].includes(
          payment_method_type
        )
          ? ''
          : payment_method,
        is_package: use_coins,
        referenceSlot: this.referenceSlot,
        isPackageDetailsOpen: this.isPackageDetailsOpen,
        selectedPackage: this.selectedPackage,
      }
      this.storeUnfinishedBookingData(data)
    },
    clearSavedBookingData() {
      this.clearUnfinishedBookingData()
    },
    async onBook({ payment_method, payment_method_type, use_coins }) {
      this.busy = true
      try {
        await this.bookMultipleLessons({
          ...this.formData,
          coach_id: pathOr('', ['coach', 'id'], this.referenceSlot),
          id: this.referenceSlot.id,
          is_package: use_coins,
          payment_method: [PAYMENT_METHOD_CASH, PAYMENT_METHOD_COINS].includes(
            payment_method_type
          )
            ? ''
            : payment_method,
        })
        this.showSuccessNotification(
          'studentNotifications.lessonBookSuccessMessage'
        )
        this.busy = false
        this.$emit('close')
        this.$emit('success')
      } catch (e) {
        this.showRequestErrorMessage(e, [
          ['response', 'data', 'payment_method'],
        ])
        this.busy = false
      }
    },
    handleAppleGooglePaymentDone() {
      this.showSuccessNotification(
        'studentNotifications.lessonBookSuccessMessage'
      )
      this.$emit('close')
      this.$emit('success')
    },
    handleAppleGooglePaymentError(e) {
      this.showRequestErrorMessage(e)
    },
    onPrebookingError(e) {
      this.showRequestErrorMessage(e)
    },
    async loadAreasOfOperation() {
      const coach_id = pathOr('', ['coach', 'id'], this.referenceSlot)
      if (coach_id) {
        try {
          this.coachAreasOfOperation = await this.getCoachAreasOfOperation({
            coach_id,
          })
        } catch (e) {}
      }
    },
    onOutOfAreaSelected() {
      this.$nextTick(() => {
        this.formData.pickup_place = null
        this.showError(this.$t('placeSelection.outOfAreaError'))
      })
    },
    async loadLessonsForAnotherGearbox(gearbox) {
      await (this.guestMode
        ? this.updateGuestFilter({ gearbox })
        : this.updateStudentFilter({ gearbox }))
      const refSlot = await this.searchReferenceSlot(gearbox)
      if (refSlot) {
        this.referenceSlot = refSlot
      }
      this.$nextTick(() => {
        this.resetForm({ hard: true })
      })
    },
    searchReferenceSlot(gearbox) {
      const coachId = pathOr('', ['coach', 'id'])(this.referenceSlot)
      return this.localLessonSearch({
        filter: this.getSearchFilterForRecentCoachLessons(coachId, gearbox),
      }).then(path(['0']))
    },
    backToFirstStep() {
      this.showBookingStep = false
    },
    forwardToDetailsStep() {
      this.ready = false
      if (this.commonLessonsCars.length === 1) {
        this.formData.car = pathOr('', ['0', 'id'], this.commonLessonsCars)
      }
      if (this.commonLessonsPlaces.length === 1) {
        this.formData.pickup_place = pathOr(
          '',
          ['0', 'id'],
          this.commonLessonsPlaces
        )
      }
      this.showBookingStep = true
      this.$fa.logEvent({
        name: 'chose_lesson_time',
      })
      Smartlook.trackCustomEvent(new SmartlookCustomEvent('chose_lesson_time'))
    },
    closeDialog() {
      this.$emit('cancel')
      this.$emit('close')
    },
    copyLinkToClipboard() {
      const { id, alias } = this.lessonsCoachInfo
      const link = `${this.baseUrl}/${
        alias ? 'fahrschule/' + alias : '?coach_id=' + id
      }${alias ? '?' : '&'}tab=${this.currentTab}`
      copyToClipBoard(this.isNativePlatform, link).then(() => {
        this.showSuccessNotification('profileCalendarSubscription.linkCopied')
      })
    },
    onSignupStart() {
      this.finishSignup = true
    },
    onSignupFinished() {
      this.signupRequired = false
    },
    goToCourseDetails(course) {
      this.selectedCourse = {
        course: {
          coach: this.lessonsCoachInfo,
          ...course,
        },
        // booking data stub (no booking yet)
        id: '',
        status: '',
      }
      this.isCourseDetailsOpen = true
    },
    async onReviewUpdate() {
      try {
        const coachId = this.referenceSlot.coach.id
        const coachUserDetails = await this.getCoachDetailsById(coachId)
        this.referenceSlot.coach.review_count = pathOr(
          0,
          ['coach', 'review_count'],
          coachUserDetails
        )
        this.referenceSlot.coach.average_rating = pathOr(
          0,
          ['coach', 'average_rating'],
          coachUserDetails
        )
        await this.reloadReviews(coachId)
      } catch (e) {}
    },
    onPackagePurchaseSuccess() {
      this.$emit('close')
      this.$emit('success')
    },
    onCourseDetailsClose() {
      this.isCourseDetailsOpen = false
      this.loadRelevantCourses()
    },
    showContactForm() {
      this.showContactDialog = true
      this.$fa.logEvent({ name: 'coach_profile_click_contact' })
    },
    closeContactForm() {
      this.showContactDialog = false
    },
  },
}
