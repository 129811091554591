
import { differenceInHours, isFuture, parseISO } from 'date-fns'
import { pathOr, pipe } from 'ramda'
import numeral from 'numeral'
import {
  Smartlook,
  SmartlookCustomEvent,
} from '@awesome-cordova-plugins/smartlook'
import DialogWrapper from '../../../shared/DialogWrapper'
import CardWrapper from '../../../shared/CardWrapper'
import { STUDENT_COURSE_STATUS_CONFIRMED } from '../../../../utils/constants'
import { formatFullName } from '../../../../utils/commonutils'
import PolicyInfoMenuMixin from '../../../../mixins/PolicyInfoMenuMixin'

export default {
  name: 'CancelCourseDialog',
  components: { DialogWrapper, CardWrapper },
  mixins: [PolicyInfoMenuMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    needsApproval: {
      type: Boolean,
      default: false,
    },
    courseDetails: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    cancellationPolicyPath() {
      return ['cancellation_policy']
    },
    purchasedAndRefundable() {
      // courses don't use cash, so they are always refundable
      return this.courseDetails.status === STUDENT_COURSE_STATUS_CONFIRMED
    },
    hoursUntilStart() {
      const startDate = parseISO(this.courseDetails.start)
      if (isFuture(startDate)) {
        return differenceInHours(startDate, new Date())
      }
      return 0
    },
    policyApplies() {
      return this.hoursUntilStart < this.cancellationPolicy.time
    },
    refundSum() {
      const lessonPrice = pathOr('', ['price'], this.courseDetails)
      if (this.showPolicy) {
        return numeral(+lessonPrice)
          .divide(100)
          .multiply(100 - this.cancellationPolicy.fee)
          .format('0.00')
      }
      return lessonPrice
    },
    coachName() {
      return pipe(pathOr({}, ['coach']), formatFullName)(this.courseDetails)
    },
    showPolicy() {
      return (
        this.purchasedAndRefundable &&
        this.hasCancellationPolicy &&
        this.policyApplies
      )
    },
  },
  methods: {
    onCancel() {
      const name = this.needsApproval ? 'declined_course' : 'cancelled_course'
      this.$fa.logEvent({
        name,
      })
      Smartlook.trackCustomEvent(new SmartlookCustomEvent(name))
      this.$emit('confirm')
      this.dialog = false
    },
  },
}
