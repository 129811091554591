
import DialogWrapper from '../../../shared/DialogWrapper'

export default {
  name: 'ConfirmBookLessonDialog',
  components: { DialogWrapper },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onConfirm() {
      this.$emit('confirm')
    },
  },
}
