
import MenuOrBottomSheet from '../../shared/MenuOrBottomSheet'
import {
  TOPIC_GRADE_DISCUSSED,
  TOPIC_GRADE_GOOD,
  TOPIC_GRADE_READY,
  TOPIC_GRADE_INSUFFICIENT,
  TOPIC_GRADE_REMOVE,
} from '../../../utils/constants'
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'
import RateTopicMenuItem from './RateTopicMenuItem'

export default {
  name: 'RateTopicDialog',
  components: { RateTopicMenuItem, MenuOrBottomSheet },
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, null],
      default: TOPIC_GRADE_DISCUSSED,
    },
  },
  data() {
    return {
      TOPIC_GRADE_INSUFFICIENT,
      TOPIC_GRADE_READY,
      TOPIC_GRADE_GOOD,
      TOPIC_GRADE_DISCUSSED,
      TOPIC_GRADE_REMOVE,
    }
  },
  methods: {
    onGradeSelect(rating) {
      this.$emit('select', rating)
      this.$emit('close')
    },
    onGradeRemove() {
      this.$emit('remove')
      this.$emit('close')
    },
  },
}
