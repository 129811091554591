import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import {
  anyPass,
  path,
  pathOr,
  filter,
  where,
  includes,
  both,
  __,
  tap,
} from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'
import lightboxStyles from '../utils/DatatransLightboxTheme'
import {
  PAYMENT_METHOD_APPLE_PAY,
  PAYMENT_METHOD_GOOGLE_PAY,
} from '~/utils/constants'
import { Payments } from '~/types/__generated__/PaymentsRoute'
import { Loyalty } from '~/types/__generated__/LoyaltyRoute'
import { BookingCalendar } from '~/types/__generated__/BookingCalendarRoute'
import GetPaymentMethods = Payments.PaymentsPaymentMethodsList
import GetRecentPaymentMethods = Payments.PaymentsRecentlyPaymentMethodsList
import GetTransactionDetails = Payments.PaymentsTransactionsRead
import GetLoyaltyTotal = Loyalty.LoyaltyCoachesTotalCoinsLeftList
import PaymentButtonSettingsRequest = BookingCalendar.BookingCalendarSlotsBulkBookingBeforePayment
import ConfirmInvitationSettingsRequest = BookingCalendar.BookingCalendarLessonsConfirmInvitationCreate
import ConfirmExamInvitationSettingsRequest = BookingCalendar.BookingCalendarExamsConfirmInvitationCreate
import CoursePaymentSettingsRequest = BookingCalendar.BookingCalendarCoursesBuyViaWalletCreate
import CourseInvitePaymentSettingsRequest = BookingCalendar.BookingCalendarStudentsCoursesAcceptViaWalletCreate
import PackagePaymentSettingsRequest = Loyalty.LoyaltyPackagesBuyViaWalletCreate
const USERID_GETTER_NAME = 'profile/userId'
export const state = () => ({
  methods: [],
})

export const getters = getterTree(state, {})
export const mutations = mutationTree(state, {
  setMethods(_state, methods) {
    _state.methods = methods
  },
})
const generateButtonConfigs = ({
  amount,
  auto_settle,
  carzi_merchant_id,
  country_code,
  currency,
  full_name,
  google_pay_merchant_id,
  refno,
}:
  | PaymentButtonSettingsRequest.ResponseBody
  | ConfirmInvitationSettingsRequest.ResponseBody
  | ConfirmExamInvitationSettingsRequest.ResponseBody
  | CoursePaymentSettingsRequest.ResponseBody
  | CourseInvitePaymentSettingsRequest.ResponseBody
  | PackagePaymentSettingsRequest.ResponseBody) => {
  return {
    init: {
      merchantId: carzi_merchant_id,
      merchantName: full_name,
      tokenOnly: false,
      autoSettle: auto_settle,
      allowedCardNetworks: ['MASTERCARD', 'VISA'],
      googlePayConfiguration: {
        buttonType: 'long',
        buttonStyle: 'black',
        merchantId: google_pay_merchant_id,
      },
      applePayConfiguration: {
        buttonType: 'plain',
        buttonStyle: 'black',
      },
    },
    payment: {
      details: {
        total: {
          label: full_name,
          amount: { value: amount, currency },
        },
        displayItems: [],
      },
      // W3C spec
      options: {
        requestPayerEmail: false,
        requestPayerName: false,
        requestPayerPhone: false,
      },
      // Datatrans specific
      transaction: {
        countryCode: country_code,
        refno,
      },
    },
    refno,
    full_name: '',
    country_code: '',
  }
}
const paymentCreationRedirectQuery: { [key: string]: string } = {
  // TODO check where to put the route info for maintainability
  success_uri: 'paymentCreation=success',
  error_uri: 'paymentCreation=error',
  cancel_uri: 'paymentCreation=canceled',
}
const paymentCreationOnBookingRedirectQuery: { [key: string]: string } = {
  // TODO check where to put the route info for maintainability
  success_uri: 'paymentCreation=success&scenario=booking',
  error_uri: 'paymentCreation=error',
  cancel_uri: 'paymentCreation=canceled',
}
export const actions = actionTree(
  { state, getters, mutations },
  {
    getMethods({
      commit,
      rootGetters,
    }): Promise<GetPaymentMethods.ResponseBody> {
      return this.$axios
        .get(
          `/api/payments/${rootGetters[USERID_GETTER_NAME]}/payment-methods/`
        )
        .then(pathOr([], ['data']))
        .then(
          tap((methods) => {
            commit('setMethods', methods)
          })
        )
    },
    getPaymentButtonSettings(
      _,
      { coach_id, data }
    ): Promise<PaymentButtonSettingsRequest.ResponseBody | undefined> {
      // locks lesson to keep payment button in sync.
      // must be immediately released for negative cases (cancelPrebooking)
      return this.$axios
        .post(
          `api/booking-calendar/${coach_id}/slots/bulk-booking-before-payment/`,
          data
        )
        .then(path(['data']))
        .then((config) => config && generateButtonConfigs(config))
    },
    getPaymentButtonSettingsForLessonInvite(_, { coach_id, lesson_id, data }) {
      // locks lesson to keep payment button in sync.
      // must be immediately released for negative cases (cancelPrebooking)
      return this.$axios
        .post(
          `api/booking-calendar/${coach_id}/lessons/confirm-invitation/${lesson_id}/`,
          data
        )
        .then(path(['data']))
        .then((config) => config && generateButtonConfigs(config))
    },
    getPaymentButtonSettingsForExamInvite(_, { coach_id, lesson_id, data }) {
      // locks exam to keep payment button in sync.
      // must be immediately released for negative cases (cancelPrebooking)
      return this.$axios
        .post(
          `api/booking-calendar/${coach_id}/exams/confirm-invitation/${lesson_id}/`,
          data
        )
        .then(path(['data']))
        .then((config) => config && generateButtonConfigs(config))
    },
    getPaymentButtonSettingsForPackage(_, { package_id, data }) {
      return this.$axios
        .post(`api/loyalty/packages/${package_id}/buy-via-wallet/`, data)
        .then(path(['data']))
        .then((config) => config && generateButtonConfigs(config))
    },
    getPaymentButtonSettingsForCourse(
      _,
      { course_id, data }
    ): Promise<CoursePaymentSettingsRequest.ResponseBody | undefined> {
      return this.$axios
        .post(`api/booking-calendar/courses/${course_id}/buy-via-wallet/`, data)
        .then(path(['data']))
        .then((config) => config && generateButtonConfigs(config))
    },
    getPaymentButtonSettingsForCourseInvite(
      { rootGetters },
      { course_id, data }
    ): Promise<CourseInvitePaymentSettingsRequest.ResponseBody | undefined> {
      return this.$axios
        .post(
          `api/booking-calendar/students/${rootGetters[USERID_GETTER_NAME]}/courses/${course_id}/accept-via-wallet/`,
          data
        )
        .then(path(['data']))
        .then((config) => config && generateButtonConfigs(config))
    },
    getLastPaymentMethod({
      rootGetters,
    }): Promise<GetRecentPaymentMethods.ResponseBody> {
      return this.$axios
        .get(
          `/api/payments/${rootGetters[USERID_GETTER_NAME]}/recently-payment-methods/`
        )
        .then(pathOr([], ['data']))
        .then(
          filter(
            where({
              student_payment_method: both(
                isNotNilOrEmpty,
                anyPass([
                  where({
                    payment_method: includes(__, [
                      PAYMENT_METHOD_GOOGLE_PAY,
                      PAYMENT_METHOD_APPLE_PAY,
                    ]),
                  }),
                  where({ id: isNotNilOrEmpty }),
                ])
              ),
            })
          )
        )
    },
    addMethodPlaceholder({ rootGetters }, { data, return_to_booking }) {
      const urlBase = rootGetters.isNativePlatform
        ? '/redirectStudentProfile'
        : '/studentProfile'
      const redirectURLs = return_to_booking
        ? paymentCreationOnBookingRedirectQuery
        : paymentCreationRedirectQuery
      const combinedURLs = Object.keys(redirectURLs).reduce(
        (result, key) => ({
          ...result,
          [key]: [urlBase, redirectURLs[key]].join('?'),
        }),
        {}
      )
      return this.$axios
        .post(
          `/api/payments/${rootGetters[USERID_GETTER_NAME]}/payment-methods/`,
          {
            ...lightboxStyles,
            ...combinedURLs,
            ...data,
          }
        )
        .then(path(['data']))
    },
    removeMethod({ rootGetters, dispatch }, id) {
      return this.$axios
        .delete(
          `/api/payments/${rootGetters[USERID_GETTER_NAME]}/payment-methods/${id}/`
        )
        .then(() => {
          return dispatch('getMethods')
        })
    },
    getTransactionStatus(
      _,
      refno
    ): Promise<GetTransactionDetails.ResponseBody | undefined> {
      // for Google / Apple
      return this.$axios
        .get(`/api/payments/transactions/${refno}/`)
        .then(path(['data']))
    },
    cancelPrebooking(_, refno) {
      // for Google / Apple
      return this.$axios.delete(`/api/payments/transactions/${refno}/`)
    },
    notifyReadyToFinishTheBooking() {
      // placeholder
    },
    getLoyaltyBalance(_, coachId): Promise<GetLoyaltyTotal.ResponseBody> {
      return this.$axios
        .get(`api/loyalty/coaches/${coachId}/total-coins-left/`)
        .then(pathOr(0, ['data']))
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
