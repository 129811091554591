
import { mapActions, mapState, mapGetters } from 'vuex'
import { path, pathOr, pick } from 'ramda'
import { formatISO, isPast, parseISO } from 'date-fns'
import ResponsiveScreenHelperMixin from '../../../../mixins/ResponsiveScreenHelperMixin'
import {
  PAYMENT_TRANSACTION_PARTIALLY_REFUNDED,
  PAYMENT_TRANSACTION_REFUNDED,
  PAYMENT_TRANSACTION_SETTLED,
  STUDENT_COURSE_STATUS_CONFIRMED,
  STUDENT_COURSE_STATUS_INVITED,
} from '../../../../utils/constants'
import SectionTitle from '../../../shared/SectionTitle'
import TextEditor from '../../../shared/TextEditor'
import EditStudentDetails from '../../student_profile/EditStudentDetails'
import FormHelperMixin from '../../../../mixins/FormHelperMixin'
import { studentProfileComplete } from '../../../../utils/commonutils'
import CoursePurchaseActionButton from '../../student_explore/booking/payment/CoursePurchaseActionButton'
import PaymentReceiptSection from '../lesson_details_dialog/PaymentReceiptSection'
import CancelCourseDialog from './CancelCourseDialog'
import CourseDetailsHeader from './CourseDetailsHeader'

export default {
  name: 'CourseDetailsDialog',
  components: {
    CoursePurchaseActionButton,
    EditStudentDetails,
    TextEditor,
    SectionTitle,
    CourseDetailsHeader,
    CancelCourseDialog,
    PaymentReceiptSection,
  },
  mixins: [ResponsiveScreenHelperMixin, FormHelperMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    courseBookingId: {
      type: [Number, String],
      default: '',
    },
    openWithCourse: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showCancelConfirmation: false,
      courseDetails: this.getInitialForm(),
      ready: false,
      studentSelectedPaymentMethod: '',
      finishSignup: false,
      signupRequired: false,
      signupChecked: false,
      busy: false,
      detailsReady: false,
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapGetters(['baseUrl', 'isNativePlatform']),
    ...mapGetters('profile', ['isCoach', 'icsToken']),
    icsUrl() {
      return `${this.baseUrl}/api/ics/slots/${this.lessonDetails?.slot_id}/calendar.ics?token=${this.icsToken}`
    },
    courseStatus() {
      return path(['status'])(this.courseDetails)
    },
    canCancelCourse() {
      return (
        this.courseStatus === STUDENT_COURSE_STATUS_CONFIRMED &&
        !this.courseStarted
      )
    },
    courseStarted() {
      return isPast(parseISO(this.courseDetails.start))
    },
    needsApproval() {
      return this.courseStatus === STUDENT_COURSE_STATUS_INVITED
    },
    isInvitation() {
      return this.needsApproval
    },
    canBuyCourse() {
      return !this.courseStatus
    },
    courseDescription() {
      return pathOr('', ['description'])(this.courseDetails)
    },
    coachDetails() {
      return path(['coach'])(this.courseDetails) || this.openWithCourse?.coach
    },
    courseId() {
      return this.courseDetails?.course_id || this.courseDetails?.course?.id
    },
    canDownloadReceipt() {
      return [
        PAYMENT_TRANSACTION_SETTLED,
        PAYMENT_TRANSACTION_REFUNDED,
        PAYMENT_TRANSACTION_PARTIALLY_REFUNDED,
      ].includes(this.courseDetails?.order?.status)
    },
  },
  watch: {
    open: {
      handler(open) {
        if (open) {
          this.loadCourseData()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadCourseData()
    this.signupRequired = !studentProfileComplete(this.profile)
    this.signupChecked = true
  },
  methods: {
    ...mapActions('student_drives', [
      'getCourseDetails',
      'getCoursePaymentReceipt',
      'cancelCourse',
      'confirmCourse',
      'getEventIcs',
    ]),
    ...mapActions('app_snackbars', ['showSuccessNotification']),
    ...mapActions('student_explore', [
      'buyCourse',
      'storeUnfinishedBookingData',
    ]),
    downloadSlotIcs() {
      try {
        this.getEventIcs({
          slotId: this.courseDetails?.slot_id,
          token: this.icsToken,
        })
      } catch (e) {
        this.showRequestErrorMessage(e)
      }
    },
    getInitialForm() {
      return {
        course_id: '',
        title: '',
        description: '',
        capacity: 1,
        language: this.firstLessonLanguage,
        students: [],
        start: formatISO(new Date()),
        end: formatISO(new Date()),
        price: +this.price || 0,
        place: '',
        place_comment: '',
        is_attended: false,
      }
    },
    downloadReceipt() {
      try {
        this.getCoursePaymentReceipt({
          course_id: this.courseId,
          student_id: this.profile?.id,
        })
      } catch (e) {
        this.showRequestErrorMessage(e)
      }
    },
    async loadCourseData() {
      let courseBookingDetails
      if (this.openWithCourse?.course || this.openwithCourse?.course_id) {
        courseBookingDetails = {
          course: this.openWithCourse,
          ...this.openWithCourse,
        }
      } else {
        courseBookingDetails = await this.getCourseDetails(
          this.courseBookingId || this.openWithCourse.id
        )
      }

      this.courseDetails = {
        ...courseBookingDetails.course,
        ...pick(
          ['status', 'id', 'is_attended', 'order', 'cancellation_policy'],
          courseBookingDetails
        ),
        course_status: courseBookingDetails.course.status,
        course_id:
          courseBookingDetails.course.id ||
          courseBookingDetails.course.course_id,
      }
      this.detailsReady = true
    },
    onBuy({ payment_method }) {
      this.studentSelectedPaymentMethod = payment_method
      if (!studentProfileComplete(this.profile)) {
        this.finishSignup = true
      } else if (this.isInvitation) {
        this.onCourseAccept()
      } else {
        this.onCourseBuy()
      }
    },
    onCourseAccept() {
      this.busy = true
      this.confirmCourse({
        id: this.courseId,
        payment_method: this.studentSelectedPaymentMethod,
      })
        .then(
          () => {
            this.onPaymentSucceed()
          },
          (e) => this.showRequestErrorMessage(e)
        )
        .finally(() => {
          this.busy = false
        })
    },
    onCourseBuy() {
      this.busy = true
      this.buyCourse({
        course_id: this.courseId,
        payment_method: this.studentSelectedPaymentMethod,
      })
        .then(
          () => {
            this.onPaymentSucceed()
          },
          (e) => this.showRequestErrorMessage(e)
        )
        .finally(() => {
          this.busy = false
        })
    },
    onCancelCourse() {
      this.busy = true
      this.showCancelConfirmation = false
      this.cancelCourse(this.courseId)
        .then(
          () => {
            if (this.needsApproval) {
              this.showSuccessNotification(
                'studentNotifications.courseDeclineSuccessMessage'
              )
            } else {
              this.showSuccessNotification(
                'studentNotifications.courseCancelSuccessMessage'
              )
            }
            this.$emit('close')
          },
          (e) => this.showRequestErrorMessage(e)
        )
        .finally(() => {
          this.busy = false
        })
    },
    onSignupStart() {
      this.finishSignup = true
    },
    onSignupFinished() {
      this.signupRequired = false
    },
    onPaymentSucceed() {
      this.showSuccessNotification(
        'studentNotifications.courseAcceptSuccessMessage'
      )
      this.$emit('close')
    },
    handleAppleGooglePaymentDone() {
      this.onPaymentSucceed()
    },
    handleAppleGooglePaymentError(e) {
      this.showRequestErrorMessage(e)
    },
    saveBookingData({ payment_method }) {
      const data = {
        courseDetails: this.courseDetails,
        payment_method,
        type: 'course',
      }
      this.storeUnfinishedBookingData(data)
    },
  },
}
