
import { __, includes, pathOr, whereEq, path, pathEq } from 'ramda'
import { debounce, throttle } from 'lodash'
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  Smartlook,
  SmartlookCustomEvent,
} from '@awesome-cordova-plugins/smartlook'
import {
  APPLE_PAYMENT_METHOD_STUB_ID,
  CASH_PAYMENT_METHOD_STUB_ID,
  GOOGLE_PAYMENT_METHOD_STUB_ID,
  PAYMENT_METHOD_APPLE_PAY,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_GOOGLE_PAY,
  paymentMethodIcons,
  PAYMENT_METHOD_TWINT,
  PAYMENT_METHOD_VISA,
  PAYMENT_METHOD_MASTERCARD,
  APL_GGL_CASH_STUB_IDS,
  PAYMENT_METHOD_COINS,
  COINS_PAYMENT_METHOD_STUB_ID,
  slotTypes,
} from '../../../../../utils/constants'
import ConfirmBookLessonDialog from '../ConfirmBookLessonDialog'
import ResponsiveScreenHelperMixin from '../../../../../mixins/ResponsiveScreenHelperMixin'
import DatatransPaymentButtonMixin from '../../../../../mixins/DatatransPaymentButtonMixin'
import MethodSelectionDialog from '../../../../shared/student_wallet/MethodSelectionDialog'
import PaymentMethodAdminControlMixin from '../../../../../mixins/PaymentMethodAdminControlMixin'
import PaymentSelectDialog from './PaymentSelectDialog'
import CashConfirmationDialog from './CashConfirmationDialog'
import NativeCapabilitiesControllerMixin from '~/mixins/NativeCapabilitiesControllerMixin'
import FailableImage from '~/components/shared/FailableImage.vue'

export default {
  name: 'PaymentActionButton',
  components: {
    MethodSelectionDialog,
    ConfirmBookLessonDialog,
    PaymentSelectDialog,
    CashConfirmationDialog,
    FailableImage,
  },
  mixins: [
    ResponsiveScreenHelperMixin,
    DatatransPaymentButtonMixin,
    PaymentMethodAdminControlMixin,
    NativeCapabilitiesControllerMixin,
  ],
  props: {
    lessonsCoachInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    guestMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ready: {
      type: Boolean,
      default: false,
    },
    isInvitation: {
      type: Boolean,
      default: false,
    },
    actionTitle: {
      type: String,
      default: '',
    },
    bookingData: {
      type: Object,
      default() {
        return {}
      },
    },
    referenceSlot: {
      type: Object,
      default() {
        return {}
      },
    },
    selectedSlotIds: {
      type: Array,
      default() {
        return []
      },
    },
    carId: {
      type: Number,
      default: undefined,
    },
    placeId: {
      type: Number,
      default: undefined,
    },
    initialPaymentMethod: {
      type: [Number, String],
      default: undefined,
    },
    lessonPrice: {
      type: String,
      default: '',
    },
    isBulkBooking: {
      type: Boolean,
      default: false,
    },
    signupFinished: {
      type: Boolean,
      default: true,
    },
    totalPrice: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      showSelection: false,
      showBookConfirm: false,
      addMethodDialog: false,
      showCashConfirm: false,
      payment_method_id: CASH_PAYMENT_METHOD_STUB_ID,
      use_coins: false,
      prebookedTransactionRefno: null,
      coachLoyaltyBalance: 0,
    }
  },
  computed: {
    ...mapState('student_wallet', {
      studentPaymentOptions: 'methods',
    }),
    ...mapGetters('profile', ['userId']),
    ...mapGetters(['isNativePlatform']),
    isLesson() {
      return pathEq(['type'], slotTypes.lesson, this.referenceSlot)
    },
    isExam() {
      return pathEq(['type'], slotTypes.exam, this.referenceSlot)
    },
    readyButtonText() {
      if (this.isInvitation) {
        return this.$t('studentLesson.acceptButton')
      } else {
        return this.$t('paymentMethods.readyAction')
      }
    },
    supportedExternalWallets() {
      const wallets = []
      if (this.browserSupportsApplePay() && this.coachAllowsExternalWallets) {
        wallets.push(PAYMENT_METHOD_APPLE_PAY)
      }
      if (this.browserSupportsGooglePay() && this.coachAllowsExternalWallets) {
        wallets.push(PAYMENT_METHOD_GOOGLE_PAY)
      }
      return wallets
    },
    allPaymentOptionsWithCash() {
      return this.allPaymentOptions.concat({
        type: PAYMENT_METHOD_CASH,
        id: CASH_PAYMENT_METHOD_STUB_ID,
        payment_method: PAYMENT_METHOD_CASH,
        enabled: this.coachAllowsCash,
      })
    },
    availablePaymentOptions() {
      return this.allPaymentOptionsWithCash.filter(whereEq({ enabled: true }))
    },
    lessonPaymentTypes() {
      return pathOr([], ['payment_methods'], this.lessonsCoachInfo)
    },
    adminDisabledPaymentMethods() {
      return pathOr([], ['disabled_payment_methods'], this.lessonsCoachInfo)
    },
    coachPaymentMethods() {
      return this.lessonPaymentTypes
    },
    coachAllowsCash() {
      return (
        !this.cashMethodRestrictedByAdmin &&
        includes(PAYMENT_METHOD_CASH, this.lessonPaymentTypes)
      )
    },
    coachAllowsInAppWalletMethods() {
      return (
        !this.electronicMethodsRestrictedByAdmin &&
        this.lessonPaymentTypes.some(
          includes(__, [
            PAYMENT_METHOD_TWINT,
            PAYMENT_METHOD_VISA,
            PAYMENT_METHOD_MASTERCARD,
          ])
        )
      )
    },
    coachAllowsExternalWallets() {
      return (
        !this.electronicMethodsRestrictedByAdmin &&
        this.lessonPaymentTypes.some(
          includes(__, [PAYMENT_METHOD_APPLE_PAY, PAYMENT_METHOD_GOOGLE_PAY])
        )
      )
    },
    showCustomPaymentButton() {
      return [PAYMENT_METHOD_APPLE_PAY, PAYMENT_METHOD_GOOGLE_PAY].includes(
        this.selectedMethodType
      )
    },
    selectedMethodType() {
      if (this.payment_method_id === CASH_PAYMENT_METHOD_STUB_ID) {
        return PAYMENT_METHOD_CASH
      }
      if (this.payment_method_id === GOOGLE_PAYMENT_METHOD_STUB_ID) {
        return PAYMENT_METHOD_GOOGLE_PAY
      }
      if (this.payment_method_id === APPLE_PAYMENT_METHOD_STUB_ID) {
        return PAYMENT_METHOD_APPLE_PAY
      }
      if (this.payment_method_id === COINS_PAYMENT_METHOD_STUB_ID) {
        return PAYMENT_METHOD_COINS
      }
      const selectedOption = this.availablePaymentOptions.find(
        whereEq({ id: this.payment_method_id })
      )
      return pathOr('', ['type'], selectedOption)
    },
    selectedMethodPicture() {
      return pathOr('', [this.selectedMethodType], paymentMethodIcons)
    },
    selectionData() {
      let payment_method = this.payment_method_id
      if (payment_method === CASH_PAYMENT_METHOD_STUB_ID) {
        payment_method = ''
      } else if (payment_method === GOOGLE_PAYMENT_METHOD_STUB_ID) {
        payment_method = GOOGLE_PAYMENT_METHOD_STUB_ID
      } else if (payment_method === APPLE_PAYMENT_METHOD_STUB_ID) {
        payment_method = APPLE_PAYMENT_METHOD_STUB_ID
      }
      return {
        use_coins: this.use_coins,
        payment_method,
        payment_method_type: this.selectedMethodType,
      }
    },
    bookingFormIsComplete() {
      if (this.isInvitation) {
        const { car, pickup_place, id } = this.bookingData
        return !!id && !!car && !!pickup_place
      }
      const { car, pickup_place, slots_ids = [] } = this.bookingData
      return !!slots_ids.length && !!car && !!pickup_place
    },
    coachId() {
      return path(['coach', 'id'], this.referenceSlot)
    },
    hasCoins() {
      return this.coachLoyaltyBalance > 0
    },
    coins() {
      return this.canUseCoins ? this.coachLoyaltyBalance : 0
    },
    canUseCoins() {
      return this.hasCoins
    },
    canPayEntireSumWithCoins() {
      return this.hasCoins && this.lessonPrice < this.coachLoyaltyBalance
    },
  },
  watch: {
    showCustomPaymentButton: {
      handler() {
        this.checkConditionsForButtonAndInit()
      },
      immediate: true,
    },
    bookingData: {
      handler() {
        if (this.isInvitation) {
          this.setReady(false)
        } else {
          const ready = this.bookingFormIsComplete && this.signupFinished
          this.setReady(ready)
          if (ready) {
            // data changed, must reinit google/apple payment buttons
            this.checkConditionsForButtonAndInit()
          }
        }
      },
      immediate: true,
      deep: true,
    },
    ready: {
      handler(ready) {
        if (ready) {
          this.checkConditionsForButtonAndInit()
        }
      },
    },
    use_coins: {
      handler() {
        this.checkConditionsForButtonAndInit()
      },
    },
    payment_method_id: {
      handler() {
        this.checkConditionsForButtonAndInit()
      },
    },
  },
  mounted() {
    Promise.all([this.getMethods(), this.loadCoins()]).then(() => {
      const paymentMethodDetails = this.availablePaymentOptions.find(
        whereEq({
          payment_method: this.initialPaymentMethod,
        })
      )
      if (!this.initialPaymentMethod) {
        this.setLastPaymentMethod()
      } else if (paymentMethodDetails) {
        this.payment_method_id = this.initialPaymentMethod
        if (
          this.payment_method_id !== CASH_PAYMENT_METHOD_STUB_ID &&
          this.canUseCoins &&
          this.hasCoins
        ) {
          this.use_coins = true
        }
        if (this.canUseCoins && this.canPayEntireSumWithCoins) {
          this.payment_method_id = COINS_PAYMENT_METHOD_STUB_ID
        }
      }
    })
  },
  beforeDestroy() {
    this.cancelPrebookingForPaymentButton()
  },
  methods: {
    ...mapActions('student_wallet', [
      'getMethods',
      'getPaymentButtonSettings',
      'getLastPaymentMethod',
      'getTransactionStatus',
      'getPaymentButtonSettingsForLessonInvite',
      'getPaymentButtonSettingsForExamInvite',
      'cancelPrebooking',
      'getLoyaltyBalance',
    ]),
    applySelection({ payment_method_id, use_coins = false }) {
      this.use_coins = use_coins
      this.payment_method_id = payment_method_id
    },
    onBook: throttle(
      function () {
        this.$emit('book', this.selectionData)
        const car = this.referenceSlot.cars.find(
          ({ id }) => id === this.bookingData.car
        )
        const { latitude, longitude } = this.referenceSlot.pickup_places.find(
          ({ id }) => id === this.bookingData.pickup_place
        )
        this.$fa.logEvent({
          name: 'booked_lesson',
          params: {
            action_type: !!this.isInvitation,
            coach_id: this.lessonsCoachInfo.id,
            uid: this.userId,
            value: this.totalPrice,
            service_id: this.bookingData.slots_ids.join(),
            service_name: this.referenceSlot?.type || slotTypes.lesson,
            school_id: this.lessonsCoachInfo.school.id,
            school_name: this.lessonsCoachInfo.school.name,
            car_id: car.id,
            car_name: car.model.brand + ' ' + car.model.model,
            transmission: car.gearbox,
            latitude,
            longitude,
          },
        })
        Smartlook.trackCustomEvent(
          new SmartlookCustomEvent('booked_lesson', {
            action_type: !!this.isInvitation,
          })
        )
      },
      5000,
      { trailing: false }
    ),
    onBookLessonButtonClick() {
      if (
        !this.guestMode &&
        this.payment_method_id === CASH_PAYMENT_METHOD_STUB_ID
      ) {
        this.showCashConfirm = true
      } else if (this.guestMode) {
        this.showBookConfirm = true
      } else {
        this.onBook()
      }
    },
    prebookLesson() {
      this.showBookConfirm = false
      this.$emit('prebook', this.selectionData)
    },
    setLastPaymentMethod() {
      this.getLastPaymentMethod().then((methods) => {
        const lastMethod = methods[0] || {}
        const lastMethodType = pathOr('', [
          'student_payment_method',
          'payment_method',
        ])(lastMethod)
        const lastMethodId = pathOr('', ['student_payment_method', 'id'])(
          lastMethod
        )
        // first set what is available
        // cash method is never favored
        // cash option is always last in the list
        this.payment_method_id = path(['0', 'id'], this.availablePaymentOptions)
        if (
          [
            PAYMENT_METHOD_MASTERCARD,
            PAYMENT_METHOD_VISA,
            PAYMENT_METHOD_TWINT,
          ].includes(lastMethodType) &&
          this.availablePaymentOptions.some(whereEq({ id: lastMethodId }))
        ) {
          this.payment_method_id = lastMethodId
        } else if (
          this.supportedExternalWallets.includes(lastMethodType) &&
          this.availablePaymentOptions.some(whereEq({ type: lastMethodType }))
        ) {
          this.payment_method_id = APL_GGL_CASH_STUB_IDS[lastMethodType]
        }
        if (
          this.payment_method_id !== CASH_PAYMENT_METHOD_STUB_ID &&
          this.canUseCoins &&
          this.hasCoins
        ) {
          this.use_coins = true
        }
        if (this.canUseCoins && this.canPayEntireSumWithCoins) {
          this.payment_method_id = COINS_PAYMENT_METHOD_STUB_ID
          this.use_coins = true
        }
      })
    },
    loadPaymentDataAndInitButton() {
      const payload = {
        coach_id: pathOr('', ['coach', 'id'], this.referenceSlot),
        slot_id: pathOr('', ['slot_id'], this.referenceSlot),
        lesson_id: pathOr('', ['lesson_id'], this.referenceSlot),
        data: {
          ...this.bookingData,
          is_package: this.use_coins,
          payment_method: this.selectedMethodType,
        },
      }
      let buttonData
      if (this.isInvitation && this.isExam) {
        buttonData = this.getPaymentButtonSettingsForExamInvite(payload)
      } else if (this.isInvitation && this.isLesson) {
        buttonData = this.getPaymentButtonSettingsForLessonInvite(payload)
      } else {
        buttonData = this.getPaymentButtonSettings(payload)
      }
      buttonData.then(
        ({ init, payment, refno }) => {
          this.prebookedTransactionRefno = refno
          localStorage.setItem('refno', JSON.stringify(refno))
          init.useGooglePay =
            this.selectedMethodType === PAYMENT_METHOD_GOOGLE_PAY
          init.useApplePay =
            this.selectedMethodType === PAYMENT_METHOD_APPLE_PAY

          this.initPaymentButtonLibrary({
            initData: init,
            onAuthorized: (response) => {
              if (response.error) {
                this.$emit('transactionFailed', response)
              } else {
                this.pingTransactionStatusUntilProcessed(refno)
              }
            },
            onError: (e) => {
              this.$emit('paymentError', e)
            },
          })
          this.renderPaymentButton(this.$refs.paymentButton, payment)
        },
        (e) => {
          this.$emit('prebookingError', e)
        }
      )
    },
    checkConditionsForButtonAndInit: debounce(async function () {
      if (
        this.ready &&
        this.showCustomPaymentButton &&
        this.bookingFormIsComplete
      ) {
        await this.cancelPrebookingForPaymentButton()
        this.loadPaymentDataAndInitButton()
      } else {
        await this.cancelPrebookingForPaymentButton()
      }
    }, 500),
    openSelection() {
      if (!this.disabled) {
        this.showSelection = true
      }
    },
    onChangePaymentMethod() {
      this.showCashConfirm = false
      this.openSelection()
    },
    onCloseCashConfirmation() {
      this.showCashConfirm = false
      this.onBook()
    },
    initMethodCreation() {
      this.addMethodDialog = true
    },
    onNewMethodSelected() {
      this.$emit('newMethodCreation', this.selectionData)
    },
    onMethodCreationCancelled() {
      this.$emit('newMethodCreationCancelled')
      this.addMethodDialog = false
    },
    onReadyClick() {
      if (this.signupFinished || this.guestMode) {
        this.setReady(true)
      } else {
        this.$emit('signup')
      }
    },
    setReady(ready) {
      this.$emit('update:ready', ready)
    },
    async loadCoins() {
      this.coachLoyaltyBalance = await this.getLoyaltyBalance(this.coachId)
    },
  },
}
