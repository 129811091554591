import { groupBy, map, path, pipe, prop, sortBy, toPairs } from 'ramda'
import {
  format,
  getDate,
  getWeek,
  lastDayOfWeek,
  parseISO,
  startOfWeek,
} from 'date-fns'
import { groupEventsByDays } from '../utils/commonutils'
import i18nHelperMixin from './i18nHelperMixin'
// expects 'events' prop to be present
export default {
  mixins: [i18nHelperMixin],
  computed: {
    weekGroups() {
      return pipe(
        groupBy(({ start }) => getWeek(parseISO(start), { weekStartsOn: 1 })),
        toPairs,
        map(([_, lessons]) => this.getWeekContainer(lessons)),
        sortBy(path(['0', 'sortBy']))
      )(this.events || [])
    },
  },
  methods: {
    getWeekContainer(events) {
      const startOfFirstEvent = pipe(prop(0), prop('start'))(events)
      const dateObj = parseISO(startOfFirstEvent)
      const firstDayOfWeek = startOfWeek(dateObj, {
        weekStartsOn: 1,
      })
      const lastWeekDay = lastDayOfWeek(dateObj, {
        weekStartsOn: 1,
      })
      return [
        {
          monthStartWeek: format(firstDayOfWeek, 'MMMM', {
            locale: this.dateFNSLocale,
          }),
          monthEndWeek: format(lastWeekDay, 'MMMM', {
            locale: this.dateFNSLocale,
          }),
          firstDate: getDate(firstDayOfWeek),
          lastDate: getDate(lastWeekDay),
          sortBy: firstDayOfWeek,
        },
        groupEventsByDays(events),
      ]
    },
  },
}
