
import { pathOr } from 'ramda'
import i18nHelperMixin from '../../../../mixins/i18nHelperMixin'
import HeaderItem from '../../../shared/HeaderItem'

export default {
  name: 'ShortLessonDetails',
  components: { HeaderItem },
  mixins: [i18nHelperMixin],
  props: {
    lessonDetails: {
      type: [Object, Boolean],
      default() {
        return null
      },
    },
  },
  computed: {
    pricePerLesson() {
      return pathOr('', ['coach', 'price_per_lesson'], this.lessonDetails)
    },
    pricePerHour() {
      return ((this.pricePerLesson * 60) / this.lessonDuration).toFixed(2)
    },
    lessonDuration() {
      return pathOr('', ['coach', 'lessons_duration'], this.lessonDetails)
    },
  },
}
