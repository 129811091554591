
import { pathOr } from 'ramda'
import {
  paymentMethodIcons,
  paymentMethodLabels,
  PAYMENT_METHOD_TWINT,
} from '../../../../../utils/constants'
import FailableImage from '~/components/shared/FailableImage.vue'

export default {
  name: 'PaymentListItem',
  components: { FailableImage },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    details: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    type() {
      return this.details.type
    },
    methodType() {
      return paymentMethodLabels[this.type] || ''
    },
    detailText() {
      if (this.type === PAYMENT_METHOD_TWINT) {
        return ''
      }
      const { masked, alias } = this.details
      return masked || alias
    },
    picture() {
      return pathOr('', [this.type], paymentMethodIcons)
    },
  },
}
