
import ResponsiveScreenHelperMixin from '../../mixins/ResponsiveScreenHelperMixin'

export default {
  name: 'MenuOrBottomSheet',
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    forceMenu: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
      default: 'bottom',
    },
  },
}
