
export default {
  name: 'WeekContainer',
  props: {
    week: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    weekTitle() {
      const { monthStartWeek, monthEndWeek, firstDate, lastDate } = this.week
      if (!monthEndWeek || monthStartWeek === monthEndWeek) {
        return `${firstDate} -  ${lastDate} ${monthStartWeek} `
      } else {
        return `${firstDate} ${monthStartWeek} -  ${lastDate} ${monthEndWeek} `
      }
    },
  },
}
