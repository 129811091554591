// Student curriculum actions and state
import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { path, pathOr } from 'ramda'
import {
  groupSubjectTotalsForCharts,
  groupTopicsToCurriculum,
  openFileOnNativePlatform,
} from '~/utils/commonutils'
import { Curriculum } from '~/types/__generated__/CurriculumRoute'
import { BookingCalendar } from '~/types/__generated__/BookingCalendarRoute'

import GetStudentCurriculum = Curriculum.CurriculumStudentsTopicsList
import GetTopicLessons = Curriculum.CurriculumStudentsTopicsLessonsList
import GetTopicLessonDetails = BookingCalendar.BookingCalendarStudentsActiveEventsList
// import GetStudentsSummary = Curriculum.CurriculumStudentsSummaryList
// TODO use swagger model when it is fixed
type CurriculumSummaryItem = {
  subject_id: number
  ratings: { value: number; total: number }[]
}
export namespace GetStudentsSummary {
  export type ResponseBody = CurriculumSummaryItem[]
}
type CurriculumProgressForStudentType = Unpacked<
  ReturnType<typeof groupTopicsToCurriculum>
> & { subject_info?: any }
export const state = () => ({
  curriculum: [],
})

export const getters = getterTree(state, {
  userId(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/userId']
  },
})
export const mutations = mutationTree(state, {
  setCurriculum(_state, curriculum) {
    _state.curriculum = curriculum
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    loadCurriculumProgress({ dispatch, commit, getters }) {
      const studentId = getters.userId
      return dispatch('loadCurriculumProgressForStudent', studentId).then(
        (curriculum) => commit('setCurriculum', curriculum)
      )
    },

    loadCurriculumProgressForStudent(
      _,
      studentId
    ): Promise<CurriculumProgressForStudentType[][]> {
      const { student_curriculum } = this.app.$accessor
      // TODO: fix types after swagger fix
      // @ts-ignore
      return Promise.all([
        student_curriculum
          .getStudentSubjectsSummary(studentId)
          .then(groupSubjectTotalsForCharts),
        student_curriculum
          .getStudentCurriculum(studentId)
          .then(groupTopicsToCurriculum),
      ]).then(([summary, curriculum]) =>
        curriculum.map((category) =>
          category.map((subject) => ({
            ...subject,
            // @ts-ignore
            category_name: subject.topics[0].category.name,
            // @ts-ignore
            is_default_category: subject.topics[0].category.is_default,
            subject_info:
              // @ts-ignore
              summary[category[0].topics[0].category.id][subject.id as number],
          }))
        )
      )
    },
    getStudentSubjectsSummary(
      _,
      studentId
    ): Promise<GetStudentsSummary.ResponseBody> {
      return this.$axios
        .get(`/api/curriculum/students/${studentId}/summary/`)
        .then(pathOr([], ['data']))
    },
    getStudentCurriculum(
      _,
      studentId: number
    ): Promise<GetStudentCurriculum.ResponseBody> {
      return this.$axios
        .get(`/api/curriculum/students/${studentId}/topics/`)
        .then(pathOr([] as GetStudentCurriculum.ResponseBody, ['data']))
    },
    getTopicLessons(
      _,
      { studentId, topicId }
    ): Promise<GetTopicLessons.ResponseBody> {
      return this.$axios
        .get(`/api/curriculum/students/${studentId}/topics/${topicId}/lessons/`)
        .then(pathOr([], ['data']))
    },
    getLessonDetails(
      _,
      { studentId, lessonId }
    ): Promise<GetTopicLessonDetails.ResponseBody | undefined> {
      return this.$axios
        .get(`/api/booking-calendar/students/${studentId}/lessons/${lessonId}/`)
        .then(path(['data']))
    },
    deleteStudentCurriculumTopic(_, { topicId, studentId }) {
      return this.$axios.delete(
        `api/curriculum/students/${studentId}/topics/${topicId}`
      )
    },
    getCurriculumFile({ rootGetters }, { studentId, type }) {
      return this.$axios({
        url: `/api/reports/curriculum/students/${studentId}/?type=${type}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const filename = response.headers['content-disposition'].split('=')[1]
        const data = new Blob([response.data], { type })

        if (rootGetters.isNativePlatform) {
          return openFileOnNativePlatform(
            data,
            filename,
            response.headers['content-type']
          )
        }

        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(data)
        a.download = filename
        a.click()
      })
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
