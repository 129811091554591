
import {
  STUDENT_COURSE_STATUS_CANCELED,
  STUDENT_COURSE_STATUS_CONFIRMED,
  STUDENT_COURSE_STATUS_INVITED,
} from '../../../../utils/constants'

export default {
  name: 'StudentCourseStatusWidget',
  props: {
    status: {
      type: Number,
      default: 0,
    },
    isPast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      STUDENT_COURSE_STATUS_INVITED,
      STUDENT_COURSE_STATUS_CONFIRMED,
      STUDENT_COURSE_STATUS_CANCELED,
    }
  },
}
