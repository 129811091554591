
import DialogOrBottomSheet from '../../../../shared/DialogOrBottomSheet'
import ResponsiveScreenHelperMixin from '../../../../../mixins/ResponsiveScreenHelperMixin'
import CardWrapper from '../../../../shared/CardWrapper'
import SectionTitle from '../../../../shared/SectionTitle'
import {
  CASH_PAYMENT_METHOD_STUB_ID,
  COINS_PAYMENT_METHOD_STUB_ID,
  PAYMENT_METHOD_COINS,
} from '../../../../../utils/constants'
import PaymentListItem from './PaymentListItem'
import ToggleCoinsListItem from './ToggleCoinsListItem'

export default {
  name: 'PaymentSelectDialog',
  components: {
    ToggleCoinsListItem,
    SectionTitle,
    CardWrapper,
    PaymentListItem,
    DialogOrBottomSheet,
  },
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    allowCreate: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    coinsSelected: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    coins: {
      type: Number,
      default: 0,
    },
    lessonPrice: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      coinsToggle: false,
      currentSelection: '',
    }
  },
  computed: {
    hasCoins() {
      return +this.coins > 0
    },
    canPayEntireSumWithCoins() {
      return this.hasCoins && +this.lessonPrice <= +this.coins
    },
    showCoinsToggle() {
      return this.hasCoins && !this.canPayEntireSumWithCoins
    },
    allowCoinToggle() {
      return this.currentSelection !== CASH_PAYMENT_METHOD_STUB_ID
    },
    showCoinsOption() {
      return this.canPayEntireSumWithCoins
    },
    coinsOption() {
      return {
        type: PAYMENT_METHOD_COINS,
        id: COINS_PAYMENT_METHOD_STUB_ID,
        payment_method: PAYMENT_METHOD_COINS,
        alias: this.$t('paymentMethods.coinsSubtitleTotal', {
          coins: this.coins,
        }),
      }
    },
  },
  watch: {
    coinsSelected: {
      handler(selected) {
        this.coinsToggle = selected
      },
      immediate: true,
    },
    value: {
      handler(paymentMethodId) {
        this.currentSelection = paymentMethodId
      },
      immediate: true,
    },
  },
  methods: {
    onCreateNewMethod() {
      this.$emit('newMethodSelected')
      this.$emit('close')
    },
    changeSelection(payment_method_id) {
      if (payment_method_id === CASH_PAYMENT_METHOD_STUB_ID) {
        this.coinsToggle = false
      } else if (
        this.canPayEntireSumWithCoins &&
        payment_method_id !== COINS_PAYMENT_METHOD_STUB_ID
      ) {
        // when having many coins - coins pay no longer can be combined with other payments
        this.coinsToggle = false
      }
      this.currentSelection = payment_method_id
    },
    applySelection() {
      const payment_method_id = this.currentSelection
      this.$emit('change', {
        payment_method_id,
        use_coins:
          this.coinsToggle ||
          payment_method_id === COINS_PAYMENT_METHOD_STUB_ID,
      })
      this.$emit('close')
    },
  },
}
