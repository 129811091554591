import { init, vueRouterInstrumentation } from '@sentry/vue'
import * as Sentry from '@sentry/capacitor'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import Vue from 'vue'
import { BrowserTracing } from '@sentry/tracing'
import { PLATFORMS } from '~/utils/constants'

export default async function ({ app }) {
  if (process.env.NODE_ENV !== 'production') return
  const dsnMap = {
    [PLATFORMS.IOS]:
      'https://39161c9f2d274bd6b282b6e29c9cce46@o280857.ingest.sentry.io/4504791649943552',
    [PLATFORMS.ANDROID]:
      'https://63e299ff55bf45c8abd8ef6647571ca8@o280857.ingest.sentry.io/4504802084651008',
    [PLATFORMS.WEB]:
      'https://065b61d0dbc0474f8b299c970901e730@o280857.ingest.sentry.io/4504802080718848',
  }
  const platform = Capacitor.getPlatform()
  const isWeb = platform === PLATFORMS.WEB

  const { build, version } = isWeb
    ? { build: 'web', version: '3.5.1' }
    : await App.getInfo()

  const initSentryVue = (options) =>
    init({ ...options, Vue, attachProps: true })

  Sentry.init(
    {
      environment: process.env.NODE_ENV,
      dsn: dsnMap[platform],
      dist: build,
      release: `carzi-${platform}@${version}`,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(app.router),
          tracePropagationTargets: [
            'localhost',
            'https://www.app.carzi.ch',
            'capacitor://native.carzi.ch',
            /^\//,
          ],
        }),
      ],
      tracesSampleRate: 0.1,
    },
    initSentryVue
  )
}
