
import { __, includes, pathOr, whereEq, path, clone } from 'ramda'
import { debounce, throttle } from 'lodash'
import { mapActions, mapState, mapGetters } from 'vuex'
import { isNull } from 'ramda-adjunct'
import {
  APPLE_PAYMENT_METHOD_STUB_ID,
  CASH_PAYMENT_METHOD_STUB_ID,
  GOOGLE_PAYMENT_METHOD_STUB_ID,
  PAYMENT_METHOD_APPLE_PAY,
  PAYMENT_METHOD_GOOGLE_PAY,
  paymentMethodIcons,
  PAYMENT_METHOD_TWINT,
  PAYMENT_METHOD_VISA,
  PAYMENT_METHOD_MASTERCARD,
  APL_GGL_CASH_STUB_IDS,
  PAYMENT_METHOD_CASH,
} from '../../../../../utils/constants'
import ResponsiveScreenHelperMixin from '../../../../../mixins/ResponsiveScreenHelperMixin'
import DatatransPaymentButtonMixin from '../../../../../mixins/DatatransPaymentButtonMixin'
import MethodSelectionDialog from '../../../../shared/student_wallet/MethodSelectionDialog'
import ConfirmBuyCourseDialog from '../ConfirmBuyCourseDialog'
import ConfirmPrebookCourseDialog from '../ConfirmPrebookCourseDialog'
import PaymentMethodAdminControlMixin from '../../../../../mixins/PaymentMethodAdminControlMixin'
import CashConfirmationDialog from './CashConfirmationDialog'
import PaymentSelectDialog from './PaymentSelectDialog'
import FailableImage from '~/components/shared/FailableImage.vue'

export default {
  name: 'CoursePurchaseActionButton',
  components: {
    ConfirmPrebookCourseDialog,
    ConfirmBuyCourseDialog,
    MethodSelectionDialog,
    PaymentSelectDialog,
    FailableImage,
    CashConfirmationDialog,
  },
  mixins: [
    ResponsiveScreenHelperMixin,
    DatatransPaymentButtonMixin,
    PaymentMethodAdminControlMixin,
  ],
  props: {
    coachInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    guestMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ready: {
      type: Boolean,
      default: false,
    },
    actionTitle: {
      type: String,
      default: '',
    },
    courseId: {
      type: [String, Number],
      default: '',
    },
    initialPaymentMethod: {
      type: [Number, String],
      default: undefined,
    },
    signupFinished: {
      type: Boolean,
      default: true,
    },
    isInvitation: {
      type: Boolean,
      default: false,
    },
    coursePrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showSelection: false,
      showBuyConfirm: false,
      showCashConfirm: false,
      addMethodDialog: false,
      payment_method_id: '',
      prebookedTransactionRefno: null,
    }
  },
  computed: {
    ...mapState('student_wallet', {
      studentPaymentOptions: 'methods',
    }),
    ...mapGetters(['isNativePlatform']),
    isBuyDisabled() {
      return (
        this.disabled ||
        (!this.selectionData.payment_method &&
          !isNull(this.selectionData.payment_method))
      )
    },
    readyButtonText() {
      return this.isInvitation
        ? this.$t('studentCourse.acceptButton')
        : this.$t('paymentMethods.readyAction')
    },
    supportedExternalWallets() {
      const wallets = []
      if (this.browserSupportsApplePay() && this.coachAllowsExternalWallets) {
        wallets.push(PAYMENT_METHOD_APPLE_PAY)
      }
      if (this.browserSupportsGooglePay() && this.coachAllowsExternalWallets) {
        wallets.push(PAYMENT_METHOD_GOOGLE_PAY)
      }
      return wallets
    },
    availablePaymentOptions() {
      return this.allPaymentOptions.filter(whereEq({ enabled: true }))
    },
    allPaymentOptionsWithCash() {
      return this.allPaymentOptions.concat({
        type: PAYMENT_METHOD_CASH,
        id: CASH_PAYMENT_METHOD_STUB_ID,
        payment_method: PAYMENT_METHOD_CASH,
        enabled: this.coachAllowsCash,
      })
    },
    coachAllowsCash() {
      return (
        !this.cashMethodRestrictedByAdmin &&
        includes(PAYMENT_METHOD_CASH, this.lessonPaymentTypes)
      )
    },
    lessonPaymentTypes() {
      return pathOr(
        [],
        ['payment_methods'],
        this.openWithCourse?.coach || this.coachInfo
      )
    },
    adminDisabledPaymentMethods() {
      return pathOr([], ['disabled_payment_methods'], this.lessonsCoachInfo)
    },
    coachPaymentMethods() {
      return this.lessonPaymentTypes
    },
    coachAllowsInAppWalletMethods() {
      return (
        !this.electronicMethodsRestrictedByAdmin &&
        this.lessonPaymentTypes.some(
          includes(__, [
            PAYMENT_METHOD_TWINT,
            PAYMENT_METHOD_VISA,
            PAYMENT_METHOD_MASTERCARD,
          ])
        )
      )
    },
    coachAllowsExternalWallets() {
      return (
        !this.electronicMethodsRestrictedByAdmin &&
        this.lessonPaymentTypes.some(
          includes(__, [PAYMENT_METHOD_APPLE_PAY, PAYMENT_METHOD_GOOGLE_PAY])
        )
      )
    },
    showCustomPaymentButton() {
      return [PAYMENT_METHOD_APPLE_PAY, PAYMENT_METHOD_GOOGLE_PAY].includes(
        this.selectedMethodType
      )
    },
    selectedMethodType() {
      if (this.payment_method_id === GOOGLE_PAYMENT_METHOD_STUB_ID) {
        return PAYMENT_METHOD_GOOGLE_PAY
      }
      if (this.payment_method_id === APPLE_PAYMENT_METHOD_STUB_ID) {
        return PAYMENT_METHOD_APPLE_PAY
      }
      if (this.payment_method_id === CASH_PAYMENT_METHOD_STUB_ID) {
        return PAYMENT_METHOD_CASH
      }
      const selectedOption = this.availablePaymentOptions.find(
        whereEq({ id: this.payment_method_id })
      )
      return pathOr('', ['type'], selectedOption)
    },
    selectedMethodPicture() {
      return pathOr('', [this.selectedMethodType], paymentMethodIcons)
    },
    selectionData() {
      let payment_method = this.payment_method_id
      if (payment_method === CASH_PAYMENT_METHOD_STUB_ID) {
        payment_method = null
      } else if (payment_method === GOOGLE_PAYMENT_METHOD_STUB_ID) {
        payment_method = GOOGLE_PAYMENT_METHOD_STUB_ID
      } else if (payment_method === APPLE_PAYMENT_METHOD_STUB_ID) {
        payment_method = APPLE_PAYMENT_METHOD_STUB_ID
      }
      return {
        payment_method,
        payment_method_type: this.selectedMethodType,
      }
    },
  },
  watch: {
    showCustomPaymentButton: {
      handler() {
        this.checkConditionsForButtonAndInit()
      },
      immediate: true,
    },
    courseId: {
      handler() {
        this.checkReadyToCheckout()
      },
      immediate: true,
    },
    ready: {
      handler(ready) {
        if (ready) {
          this.checkConditionsForButtonAndInit()
        }
      },
    },
    signupFinished: {
      handler(finished) {
        if (finished) {
          this.checkReadyToCheckout()
        }
      },
    },
    payment_method_id: {
      handler() {
        this.checkConditionsForButtonAndInit()
      },
    },
  },
  mounted() {
    this.initialPrebookRefnoSetup()
    this.initialPaymentMethodSetup()
  },
  beforeDestroy() {
    this.cancelPrebookingForPaymentButton()
  },
  methods: {
    ...mapActions('student_wallet', [
      'getMethods',
      'getLastPaymentMethod',
      'getTransactionStatus',
      'cancelPrebooking',
      'getPaymentButtonSettingsForCourseInvite',
      'getPaymentButtonSettingsForCourse',
    ]),
    checkReadyToCheckout() {
      if (!this.isInvitation) {
        const ready = this.signupFinished
        this.setReady(ready)
        if (ready) {
          // data changed, must reinit google/apple payment buttons
          this.checkConditionsForButtonAndInit()
        }
      }
    },
    applySelection({ payment_method_id }) {
      this.payment_method_id = payment_method_id
    },
    emitBuy: throttle(
      function () {
        this.$emit('buy', this.selectionData)
        this.showCashConfirm = false
      },
      5000,
      { trailing: false }
    ),
    onBuy() {
      if (
        this.selectedMethodType === PAYMENT_METHOD_CASH &&
        !this.showCashConfirm
      ) {
        this.showCashConfirm = true
        return
      }
      this.emitBuy()
    },
    onCloseCashConfirmation() {
      this.onBuy()
    },
    onBuyCourseButtonClick() {
      if (this.guestMode) {
        this.showBuyConfirm = true
      } else {
        this.onBuy()
      }
    },
    setCourseForBuy() {
      this.showBuyConfirm = false
      this.$emit('prebook', clone(this.selectionData))
    },
    setLastPaymentMethod() {
      this.getLastPaymentMethod().then((methods) => {
        const lastMethod = methods[0] || {}
        const lastMethodType = pathOr('', [
          'student_payment_method',
          'payment_method',
        ])(lastMethod)
        const lastMethodId = pathOr('', ['student_payment_method', 'id'])(
          lastMethod
        )
        // first set what is available
        // cash method is never favored
        this.payment_method_id = path(['0', 'id'], this.availablePaymentOptions)
        if (
          [
            PAYMENT_METHOD_MASTERCARD,
            PAYMENT_METHOD_VISA,
            PAYMENT_METHOD_TWINT,
          ].includes(lastMethodType) &&
          this.availablePaymentOptions.some(whereEq({ id: lastMethodId }))
        ) {
          this.payment_method_id = lastMethodId
        } else if (
          this.supportedExternalWallets.includes(lastMethodType) &&
          this.availablePaymentOptions.some(whereEq({ type: lastMethodType }))
        ) {
          this.payment_method_id = APL_GGL_CASH_STUB_IDS[lastMethodType]
        }
      })
    },
    loadPaymentDataAndInitButton() {
      const payload = {
        course_id: this.courseId,
        data: {
          payment_method: this.selectedMethodType,
        },
      }
      const buttonData = this.isInvitation
        ? this.getPaymentButtonSettingsForCourseInvite(payload)
        : this.getPaymentButtonSettingsForCourse(payload)
      buttonData.then(
        ({ init, payment, refno }) => {
          this.prebookedTransactionRefno = refno
          localStorage.setItem('refno', JSON.stringify(refno))
          init.useGooglePay =
            this.selectedMethodType === PAYMENT_METHOD_GOOGLE_PAY
          init.useApplePay =
            this.selectedMethodType === PAYMENT_METHOD_APPLE_PAY

          this.initPaymentButtonLibrary({
            initData: init,
            onAuthorized: () => {
              this.pingTransactionStatusUntilProcessed(refno)
            },
            onError: (e) => {
              this.$emit('paymentError', e)
            },
          })
          this.renderPaymentButton(this.$refs.paymentButton, payment)
        },
        (e) => {
          this.$emit('prebookingError', e)
        }
      )
    },
    checkConditionsForButtonAndInit: debounce(async function () {
      if (this.ready && this.showCustomPaymentButton) {
        await this.cancelPrebookingForPaymentButton()
        this.loadPaymentDataAndInitButton()
      } else {
        await this.cancelPrebookingForPaymentButton()
      }
    }, 500),
    openSelection() {
      if (!this.disabled) {
        this.showSelection = true
      }
    },
    onChangePaymentMethod() {
      this.showCashConfirm = false
      this.openSelection()
    },
    initMethodCreation() {
      this.addMethodDialog = true
    },
    onNewMethodSelected() {
      this.$emit('newMethodCreation', this.selectionData)
    },
    onMethodCreationCancelled() {
      this.$emit('newMethodCreationCancelled')
      this.addMethodDialog = false
    },
    onReadyClick() {
      if (this.signupFinished || this.guestMode) {
        this.setReady(true)
      } else {
        this.$emit('signup')
      }
    },
    setReady(ready) {
      this.$emit('update:ready', ready)
    },
  },
}
