import {
  Smartlook,
  SmartlookCustomEvent,
} from '@awesome-cordova-plugins/smartlook'
import { prop } from 'ramda'
import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { defaultProfile } from './profile'
import { PhoneVerification } from '~/types/__generated__/PhoneVerificationRoute'
export const state = () => ({})
export const getters = getterTree(state, {})
export const mutations = mutationTree(state, {})
export const actions = actionTree(
  { state, getters, mutations },
  {
    logout({ commit }) {
      return this.$auth.logout().then(() => {
        commit('profile/setProfile' as never, defaultProfile as never, {
          root: true,
        })
      })
    },
    login(_, data) {
      return this.$auth.loginWith('local', { data })
    },
    requestCode(_, data) {
      return this.$axios.post(`api/phone_verification/`, data)
    },
    loginWithCode(_, data) {
      return this.$auth
        .loginWith('withCode', {
          data,
        })
        .then((response) =>
          prop('data')(
            response as Record<
              'data',
              PhoneVerification.PhoneVerificationCodeCreate.ResponseBody
            >
          )
        )
        .then((data) => {
          if (!data.is_created) {
            return
          }
          this.$fa.logEvent({
            name: 'sign_up',
            params: { user_id: this.app.$accessor.profile.userId },
          })
          Smartlook.trackCustomEvent(
            new SmartlookCustomEvent('sign_up', {
              user_id: this.app.$accessor.profile.userId,
            })
          )
        })
    },
    loginWithInvitationCode(_, data) {
      return this.$auth.loginWith('withInvitationCode', { data })
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
