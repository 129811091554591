import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import {
  path,
  pipe,
  filter,
  where,
  whereEq,
  any,
  tap,
  sortWith,
  descend,
  ascend,
  pathOr,
  none,
} from 'ramda'
import { parseISO, isPast, isFuture, getUnixTime } from 'date-fns'
import {
  Smartlook,
  SmartlookCustomEvent,
} from '@awesome-cordova-plugins/smartlook'
import { UnreviewedCoaches } from './../types/__generated__/data-contracts'

import {
  STUDENT_COURSE_STATUS_CANCELED,
  STUDENT_COURSE_STATUS_CONFIRMED,
  STUDENT_COURSE_STATUS_INVITED,
  LESSON_STATUS_COACH_APPROVED,
} from '~/utils/constants'
import { openFileOnNativePlatform } from '~/utils/commonutils'
import { BookingCalendar } from '~/types/__generated__/BookingCalendarRoute'
import { Loyalty } from '~/types/__generated__/LoyaltyRoute'
import { Reviews } from '~/types/__generated__/ReviewsRoute'
import GetInvitations = BookingCalendar.BookingCalendarStudentsInvitedEventsList
import GetActiveEvents = BookingCalendar.BookingCalendarStudentsActiveEventsList
import GetCanceledEvents = BookingCalendar.BookingCalendarStudentsCanceledEventsList
import GetLoyaltyBalances = Loyalty.LoyaltyStudentsPackagesList
import GetInvitedCourses = BookingCalendar.BookingCalendarStudentsCourseBookingsList
import GetCourseDetails = BookingCalendar.BookingCalendarStudentsCourseBookingsRead
import GetLessonCoachesForReview = Reviews.ReviewsStudentsEventsUnreviewedCoachesList
import GetCourseCoachesForReview = Reviews.ReviewsStudentsCoursesUnreviewedCoachesList
import ConfirmLesson = BookingCalendar.BookingCalendarLessonsAcceptCreate
import ConfirmExam = BookingCalendar.BookingCalendarExamsAcceptCreate
import ConfirmCourse = BookingCalendar.BookingCalendarStudentsCoursesAcceptCreate

export const state = () => ({
  drives: [],
  invites: [],
  cancelledDrives: [],
  loyaltyBalances: [],
  invitedCourses: [],
  confirmedCourses: [],
  canceledCourses: [],
  attendedCourses: [],
  coachesToRate: [] as UnreviewedCoaches[],
  studentCoaches: [] as UnreviewedCoaches[],
  dataFetching: undefined,
})

const getSlotTime = ({
  start,
  slot_start,
}: {
  start: string
  slot_start: string
}) => slot_start || start
const slotUnixStartTime = pipe(getSlotTime, parseISO, getUnixTime)
const drivesNotificationUnixStartTime = slotUnixStartTime
const coursesNotificationUnixStartTime = slotUnixStartTime

const CONTENT_DISPOSITION_HEADER = 'content-disposition'
export const getters = getterTree(state, {
  userId(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/userId']
  },
  upcomingInvitations({ invites }) {
    return pipe(
      filter(
        where({
          slot_start: pipe(parseISO, isFuture),
        })
      ),
      sortWith([ascend(drivesNotificationUnixStartTime)])
    )(invites)
  },
  expiredInvitations({ invites }) {
    return pipe(
      filter(
        where({
          slot_start: pipe(parseISO, isPast),
        })
      ),
      sortWith([descend(drivesNotificationUnixStartTime)])
    )(invites)
  },
  upcomingDrives({ drives }) {
    return pipe(
      filter(
        where({
          slot_start: pipe(parseISO, isFuture),
        })
      ),
      sortWith([ascend(drivesNotificationUnixStartTime)])
    )(drives)
  },
  hasDriveNotifications(_state, getters) {
    const needsConfirmation = whereEq({ status: LESSON_STATUS_COACH_APPROVED })
    return (
      any(needsConfirmation, getters.upcomingDrives) ||
      any(needsConfirmation, getters.upcomingInvitations)
    )
  },
  hasCourseNotifications(_state, getters) {
    return !!getters.incomingCoursesInvitations.length
  },
  hasIncomingInvitations(_state, getters) {
    return !!getters.combinedInvitations.length
  },
  pastDrives({ drives }) {
    return pipe(
      filter(
        where({
          slot_start: pipe(parseISO, isPast),
        })
      ),
      sortWith([descend(drivesNotificationUnixStartTime)])
    )(drives)
  },
  cancelledDrives({ cancelledDrives }) {
    return sortWith([ascend(drivesNotificationUnixStartTime)])(cancelledDrives)
  },
  canceledCourses({ canceledCourses }) {
    return sortWith([ascend(coursesNotificationUnixStartTime)])(canceledCourses)
  },
  incomingCoursesInvitations({ invitedCourses }) {
    return filter(pipe(getSlotTime, parseISO, isFuture))(invitedCourses)
  },
  expiredCoursesInvitations({ invitedCourses }) {
    return pipe(
      filter(pipe(getSlotTime, parseISO, isPast)),
      sortWith([descend(coursesNotificationUnixStartTime)])
    )(invitedCourses)
  },
  combinedInvitations(_, { incomingCoursesInvitations, upcomingInvitations }) {
    return pipe(sortWith([ascend(slotUnixStartTime)]))(
      incomingCoursesInvitations.concat(upcomingInvitations)
    )
  },
  pastCourses({ confirmedCourses }) {
    return pipe(
      filter(
        where({
          start: pipe(parseISO, isPast),
        })
      ),
      sortWith([descend(coursesNotificationUnixStartTime)])
    )(confirmedCourses)
  },
  allDrives(
    _state,
    {
      upcomingInvitations,
      expiredInvitations,
      upcomingDrives,
      pastDrives,
      cancelledDrives,
    }
  ) {
    return upcomingInvitations.concat(
      expiredInvitations,
      upcomingDrives,
      pastDrives,
      cancelledDrives
    )
  },
  upcomingCourses({ confirmedCourses }) {
    return pipe(
      filter(
        where({
          start: pipe(parseISO, isFuture),
        })
      ),
      sortWith([ascend(coursesNotificationUnixStartTime)])
    )(confirmedCourses)
  },
  studentCoachesWithRatingRequired({ studentCoaches, coachesToRate }) {
    return studentCoaches
      .map((coach) => ({
        ...coach,
        review_expected: any(whereEq({ id: coach.id }))(coachesToRate),
      }))
      .concat(
        coachesToRate
          .filter(({ id }) => none(whereEq({ id }))(studentCoaches))
          .map((coach) => ({
            ...coach,
            review_expected: true,
          }))
      )
  },
})
export const mutations = mutationTree(state, {
  setCoachesToRate(_state, coaches) {
    _state.coachesToRate = coaches
  },
  setStudentCoaches(state, coaches) {
    state.studentCoaches = coaches
  },
  setDrives(_state, drives) {
    _state.drives = drives
  },
  setInvites(_state, invites) {
    _state.invites = invites
  },
  setCancelledDrives(_state, drives) {
    _state.cancelledDrives = drives
  },
  setDataFetchingId(_state, intervalId) {
    _state.dataFetching = intervalId
  },
  setLoyaltyBalances(_state, balances) {
    _state.loyaltyBalances = balances
  },
  setInvitedCourses(_state, invites) {
    _state.invitedCourses = invites
  },
  setConfirmedCourses(_state, courses) {
    _state.confirmedCourses = courses
  },
  setCanceledCourses(_state, courses) {
    _state.canceledCourses = courses
  },
  setAttendedCourses(_state, courses) {
    _state.attendedCourses = courses
  },
})
export const actions = actionTree(
  { state, getters, mutations },
  {
    getInvites({ commit, getters }): Promise<GetInvitations.ResponseBody> {
      return this.$axios
        .get(`api/booking-calendar/students/${getters.userId}/invited-events/`)
        .then(pathOr([], ['data']))
        .then(tap((results) => commit('setInvites', results)))
    },
    getDrives({ commit, getters }): Promise<GetActiveEvents.ResponseBody> {
      return this.$axios
        .get(`api/booking-calendar/students/${getters.userId}/active-events/`)
        .then(pathOr([], ['data']))
        .then(tap((results) => commit('setDrives', results)))
    },
    getCancelledDrives({
      commit,
      getters,
    }): Promise<GetCanceledEvents.ResponseBody> {
      return this.$axios
        .get(`api/booking-calendar/students/${getters.userId}/canceled-events/`)
        .then(pathOr([], ['data']))
        .then(tap((results) => commit('setCancelledDrives', results)))
    },
    getLoyaltyBalances({
      commit,
      getters,
    }): Promise<GetLoyaltyBalances.ResponseBody['results']> {
      return this.$axios
        .get(`api/loyalty/students/${getters.userId}/packages/`)
        .then(pathOr([], ['data', 'results']))
        .then(tap((results) => commit('setLoyaltyBalances', results)))
    },
    getInvitedCourses({
      commit,
      getters,
    }): Promise<GetInvitedCourses.ResponseBody['results']> {
      return this.$axios
        .get(
          `api/booking-calendar/students/${getters.userId}/course-bookings/?status=${STUDENT_COURSE_STATUS_INVITED}&limit=1000`
        )
        .then(pathOr([], ['data', 'results']))
        .then(tap((results) => commit('setInvitedCourses', results)))
    },
    getConfirmedCourses({
      commit,
      getters,
    }): Promise<GetInvitedCourses.ResponseBody['results']> {
      return this.$axios
        .get(
          `api/booking-calendar/students/${getters.userId}/course-bookings/?status=${STUDENT_COURSE_STATUS_CONFIRMED}&limit=1000`
        )
        .then(pathOr([], ['data', 'results']))
        .then(tap((results) => commit('setConfirmedCourses', results)))
    },
    getCanceledCourses({
      commit,
      getters,
    }): Promise<GetInvitedCourses.ResponseBody['results']> {
      return this.$axios
        .get(
          `api/booking-calendar/students/${getters.userId}/course-bookings/?status=${STUDENT_COURSE_STATUS_CANCELED}&limit=1000`
        )
        .then(pathOr([], ['data', 'results']))
        .then(tap((results) => commit('setCanceledCourses', results)))
    },
    getAttendedCourses({
      commit,
      getters,
    }): Promise<GetInvitedCourses.ResponseBody['results']> {
      return this.$axios
        .get(
          `api/booking-calendar/students/${getters.userId}/course-bookings/?is_attended=true&limit=1000`
        )
        .then(pathOr([], ['data', 'results']))
        .then(tap((results) => commit('setAttendedCourses', results)))
    },
    getStudentCoaches({ commit, getters }) {
      return this.$axios
        .get(`api/users/students/${getters.userId}/coaches/`)
        .then(pathOr([], ['data']))
        .then((data) => commit('setStudentCoaches', data))
    },
    startDataFetching({ dispatch, commit, state }) {
      dispatch('stopDataFetching')
      if (!state.dataFetching) {
        dispatch('loadPageData')
      }
      const intervalId = setInterval(() => {
        dispatch('loadPageData')
      }, 30 * 1000)
      commit('setDataFetchingId', intervalId)
    },
    stopDataFetching({ commit, state }) {
      clearInterval(state.dataFetching)
      commit('setDataFetchingId', undefined)
    },
    cancelLesson({ dispatch }, { coach_id, id }) {
      return this.$axios
        .post(`api/booking-calendar/${coach_id}/lessons/cancel/${id}/`)
        .then(path(['data']))
        .then(tap(() => dispatch('loadPageData')))
    },
    cancelExam({ dispatch }, { coach_id, id }) {
      return this.$axios
        .post(`api/booking-calendar/${coach_id}/exams/cancel/${id}/`)
        .then(path(['data']))
        .then(tap(() => dispatch('loadPageData')))
    },
    confirmLesson(
      { dispatch },
      {
        coach_id,
        id,
        car,
        pickup_place,
        payment_method = undefined,
        is_package,
      }
    ): Promise<ConfirmLesson.ResponseBody | undefined> {
      const payload = { car, pickup_place, is_package, payment_method }
      if (payment_method) {
        payload.payment_method = payment_method
      }
      return this.$axios
        .post(`api/booking-calendar/${coach_id}/lessons/accept/${id}/`, payload)
        .then(path(['data']))
        .then((data: ConfirmLesson.ResponseBody | undefined) => {
          data?.ga_data?.events?.forEach(({ name, params }) => {
            this.$fa.logEvent({ name, params })
            Smartlook.trackCustomEvent(new SmartlookCustomEvent(name, params))
          })
          return data
        })
        .then(tap(() => dispatch('loadPageData')))
    },
    getCourseDetails(
      { getters },
      courseBookingId
    ): Promise<GetCourseDetails.ResponseBody | undefined> {
      return this.$axios
        .get(
          `/api/booking-calendar/students/${getters.userId}/course-bookings/${courseBookingId}/`
        )
        .then(path(['data']))
    },
    confirmExam(
      { dispatch },
      {
        coach_id,
        id,
        car,
        pickup_place,
        payment_method = undefined,
        is_package,
      }
    ): Promise<ConfirmExam.ResponseBody | undefined> {
      const payload = { car, pickup_place, is_package, payment_method }
      if (payment_method) {
        payload.payment_method = payment_method
      }
      return this.$axios
        .post(`api/booking-calendar/${coach_id}/exams/accept/${id}/`, payload)
        .then(path(['data']))
        .then((data: ConfirmExam.ResponseBody | undefined) => {
          data?.ga_data?.events?.forEach(({ name, params }) => {
            this.$fa.logEvent({ name, params })
            Smartlook.trackCustomEvent(new SmartlookCustomEvent(name, params))
          })
          return data
        })
        .then(
          tap(() => {
            dispatch('loadPageData')
          })
        )
    },
    loadPageData({ dispatch }) {
      return Promise.all([
        dispatch('getDrives'),
        dispatch('getInvites'),
        dispatch('getCancelledDrives'),
        dispatch('getLoyaltyBalances'),
        dispatch('getInvitedCourses'),
        dispatch('getConfirmedCourses'),
        dispatch('getCanceledCourses'),
        dispatch('getCoachesToRate'),
        dispatch('getStudentCoaches'),
      ])
    },
    cancelCourse({ getters, dispatch }, id) {
      return this.$axios
        .post(
          `api/booking-calendar/students/${getters.userId}/courses/${id}/cancel/`
        )
        .then(path(['data']))
        .then(tap(() => dispatch('loadPageData')))
    },
    confirmCourse(
      { getters, dispatch },
      { id, payment_method }
    ): Promise<ConfirmCourse.ResponseBody | undefined> {
      const payload = { payment_method }
      return this.$axios
        .post(
          `api/booking-calendar/students/${getters.userId}/courses/${id}/accept/`,
          payload
        )
        .then(path(['data']))
        .then((data: ConfirmCourse.ResponseBody | undefined) => {
          data?.ga_data?.events?.forEach(({ name, params }) => {
            this.$fa.logEvent({ name, params })
            Smartlook.trackCustomEvent(new SmartlookCustomEvent(name, params))
          })
          return data
        })
        .then(tap(() => dispatch('loadPageData')))
    },
    sendCoachRating({ getters, dispatch }, payload) {
      return this.$axios
        .post(`api/reviews/students/${getters.userId}/reviews/`, {
          ...payload,
          student: getters.userId,
        })
        .then(() => dispatch('getCoachesToRate'))
    },
    updateCoachRating({ getters, dispatch }, payload) {
      return this.$axios
        .patch(
          `api/reviews/students/${getters.userId}/reviews/${payload.id}/`,
          {
            ...payload,
            student: getters.userId,
          }
        )
        .then(() => dispatch('getCoachesToRate'))
    },
    getCoachesToRate({ commit, getters }) {
      this.$axios
        .get(`api/users/students/${getters.userId}/coaches/`)
        .then(pathOr([], ['data']))
        .then((data) =>
          commit(
            'setCoachesToRate',
            data.filter(({ can_be_rated }) => can_be_rated)
          )
        )
    },
    getLessonAndExamsUnreviewedCoaches({
      getters,
    }): Promise<GetLessonCoachesForReview.ResponseBody> {
      return this.$axios
        .get(
          `api/reviews/students/${getters.userId}/events/unreviewed-coaches/`
        )
        .then(pathOr([], ['data']))
    },
    getCoursesUnreviewedCoaches({
      getters,
    }): Promise<GetCourseCoachesForReview.ResponseBody> {
      return this.$axios
        .get(
          `api/reviews/students/${getters.userId}/courses/unreviewed-coaches/`
        )
        .then(pathOr([], ['data']))
    },
    getEventPaymentReceipt({ rootGetters }, { student_id, lesson_id }) {
      return this.$axios({
        url: `/api/payments/students/${student_id}/events/${lesson_id}/`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const filename = response.headers[CONTENT_DISPOSITION_HEADER]
          ? response.headers[CONTENT_DISPOSITION_HEADER].split('=')[1]
          : 'receipt.pdf'
        const type = 'application/pdf'
        const data = new Blob([response.data], { type })

        if (rootGetters.isNativePlatform) {
          return openFileOnNativePlatform(data, filename, type)
        }
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(data)
        a.download = filename.replace(/"/g, '')
        a.click()
      })
    },
    getEventIcs(_, { slotId, token }) {
      return this.$axios
        .get(`api/ics/slots/${slotId}/calendar.ics?token=${token}`)
        .then((response) => {
          const filename = `calendar-${slotId}.ics`
          const type = 'text/calendar'
          const data = new Blob([response.data], { type })
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(data)
          a.download = filename.replace(/"/g, '')
          a.click()
        })
    },
    getCoursePaymentReceipt({ rootGetters }, { student_id, course_id }) {
      return this.$axios({
        url: `/api/payments/students/${student_id}/courses/${course_id}/`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        // TODO extract to some common PopFileFromResponse(file, name) util
        const filename = response.headers[CONTENT_DISPOSITION_HEADER]
          ? response.headers[CONTENT_DISPOSITION_HEADER].split('=')[1]
          : 'receipt.pdf'
        const type = 'application/pdf'
        const data = new Blob([response.data], { type })

        if (rootGetters.isNativePlatform) {
          return openFileOnNativePlatform(data, filename, type)
        }
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(data)
        a.download = filename.replace(/"/g, '')
        a.click()
      })
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
