import {
  __,
  concat,
  path,
  pathOr,
  keys,
  uniqBy,
  prop,
  propOr,
  tap,
  pipe,
  sortWith,
  ascend,
  omit,
  pick,
  filter,
} from 'ramda'
import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { addDays, formatISO, getUnixTime, parseISO } from 'date-fns'
import {
  Smartlook,
  SmartlookCustomEvent,
} from '@awesome-cordova-plugins/smartlook'
import {
  paymentMethods,
  NO_BOOKING_DATA,
  STUDENT_ROLE,
  AT_GEARBOX,
  COACH_GENDER_ALL,
  RATING_SORT_PROP,
} from '~/utils/constants'
import { makeLessonFilter } from '~/utils/commonutils'
import { StudentProfileUserDetail } from '~/types/__generated__/data-contracts'
import { BookingCalendar } from '~/types/__generated__/BookingCalendarRoute'
import { Users } from '~/types/__generated__/UsersRoute'
import GetCalendarSlots = BookingCalendar.BookingCalendarSlotsList
import GetBookableSlots = BookingCalendar.BookingCalendarCoachesBookableSlotsList
import GetBookableCourse = BookingCalendar.BookingCalendarCoachesBookableCoursesList
import SearchCoach = Users.UsersCoachesList
import GetCoachDetails = Users.UsersCoachesRead
import GetLatestCoach = Users.UsersStudentsLatestCoachRead
import PostLessonBulkBooking = BookingCalendar.BookingCalendarSlotsBulkBooking
import BuyCourse = BookingCalendar.BookingCalendarCoursesBuyCreate
type StudentExploreFilterState = {
  user: string
  place: string
  date: string
  time: string
  lesson_languages: []
  payment_methods: []
  gearbox: string
  gender: string
  ordering: string
  average_rating: number
}
export const state = () => ({
  filterState: {
    user: '',
    place: '',
    date: '',
    time: '',
    lesson_languages: [],
    payment_methods: [],
    gearbox: '',
    gender: COACH_GENDER_ALL,
    ordering: `-${RATING_SORT_PROP}`,
    average_rating: 0,
    price_per_hour__gte: 0,
    price_per_hour__lte: 0,
  } as StudentExploreFilterState,
  lessonSearch: [],
  popularCoaches: {
    next: null,
    previous: null,
    count: 0,
    results: [],
  } as SearchCoach.ResponseBody,
  closePopularCoaches: {
    next: null,
    previous: null,
    count: 0,
    results: [],
  } as SearchCoach.ResponseBody,
  latestCoach: {},
  searchHistory: [],
  minPrice: 0,
  maxPrice: 0,
})
export type StudentExploreState = ReturnType<typeof state>
const lessonUnixStartTime = pipe(propOr('', 'start'), parseISO, getUnixTime)
export const getters = getterTree(state, {
  userId(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/userId']
  },
  popularCoaches(_state) {
    return pathOr([], ['popularCoaches', 'results'], _state)
  },
  closePopularCoaches(_state) {
    return pathOr([], ['closePopularCoaches', 'results'], _state)
  },
  moreCoachesUrl(_state) {
    return path(['popularCoaches', 'next'], _state)
  },
  moreCloseCoachesUrl(_state) {
    return path(['closePopularCoaches', 'next'], _state)
  },
  filtersState(_state) {
    return omit(['ordering'], _state.filterState)
  },
  sortsState(_state) {
    return pick(['ordering'], _state.filterState)
  },
  filterData(_state): GetCalendarSlots.RequestQuery {
    return makeLessonFilter(_state.filterState)
  },
  defaultFilter(_state, _getters, _rootState, rootGetters) {
    return {
      lesson_languages: [_getters.preferred_language],
      gearbox: rootGetters['profile/student_preferred_gearbox'] || AT_GEARBOX,
      payment_methods:
        rootGetters['profile/student_payment_methods'] ||
        keys(paymentMethods).map((methodId) => +methodId),
      average_rating: 0,
      gender: COACH_GENDER_ALL,
      price_per_hour__gte: 80,
      price_per_hour__lte: 160,
    }
  },
  preferred_language(_state, _getters, rootState, rootGetters) {
    return (
      rootGetters['profile/student_preferred_language'] ||
      rootState.lastUsedLanguage
    )
  },
  isReadyToProcessGuestBooking(_state, _getters, rootState, rootGetters) {
    return (
      rootGetters['profile/profileLoaded'] &&
      rootState.app_snackbars.isSnackbarLoaded &&
      rootGetters['profile/userRole'] === STUDENT_ROLE
    )
  },
  popularCoachesSearchPeriod() {
    return {
      start__lte: pipe((date) => addDays(date, 30), formatISO)(new Date()),
      start__gte: formatISO(new Date()),
    }
  },
})
export const mutations = mutationTree(state, {
  updateFilter(_state, filter) {
    _state.filterState = {
      ..._state.filterState,
      ...filter,
    }
  },
  setSearchResults(_state, results) {
    _state.lessonSearch = results
  },
  setPopularCoaches(_state, results) {
    _state.popularCoaches = results
  },
  setClosePopularCoaches(_state, results) {
    _state.closePopularCoaches = results
  },
  setLatestCoach(_state, result) {
    _state.latestCoach = result
  },
  addPopularCoaches(_state, nextPage) {
    const { next = null, previous = null, count = 0, results = [] } = nextPage
    _state.popularCoaches = {
      next,
      previous,
      count,
      results: pipe(
        pathOr([], ['popularCoaches', 'results']),
        concat(__, results)
      )(_state) as [],
    }
  },
  addClosePopularCoaches(_state, nextPage: SearchCoach.ResponseBody) {
    const { next = null, previous = null, count = 0, results = [] } = nextPage
    _state.closePopularCoaches = {
      next,
      previous,
      count,
      results: pipe(
        pathOr([], ['closePopularCoaches', 'results']),
        concat(__, results)
      )(_state),
    }
  },
  setPriceMinMax(_state, { min, max }) {
    _state.minPrice = min
    _state.maxPrice = max
  },
})
export const actions = actionTree(
  { state, getters, mutations },
  {
    getPriceRangeData({ commit }, id): Promise<any> {
      return this.$axios
        .get('api/users/coaches/prices/', {
          params: id ? { id } : {},
        })
        .then(pathOr([], ['data']))
        .then(filter(isNotNilOrEmpty))
        .then((data) => {
          const min = Math.min(...data)
          const max = Math.max(...data)
          commit('setPriceMinMax', { max, min })
          return data
        })
    },
    updateFilter({ commit, dispatch }, filter) {
      commit('updateFilter', filter)
      dispatch('searchLessons')
    },
    suggestCoach(_, search): Promise<SearchCoach.ResponseBody['results']> {
      return this.$axios
        .get('api/users/coaches/', {
          params: { search } as Users.UsersCoachesList.RequestQuery,
        })
        .then(pathOr([], ['data', 'results']))
    },
    searchLessons({ getters, commit }): Promise<GetCalendarSlots.ResponseBody> {
      return this.$axios
        .get('api/booking-calendar/slots/', {
          params: getters.filterData,
        })
        .then(pathOr([], ['data']))
        .then(tap((results) => commit('setSearchResults', results)))
    },
    searchSimilarLessons(
      _,
      { coach_id, filter }
    ): Promise<GetBookableSlots.ResponseBody> {
      return this.$axios
        .get(`api/booking-calendar/coaches/${coach_id}/bookable-slots/`, {
          params: filter,
        })
        .then(pathOr([], ['data']))
        .then(uniqBy(prop('id'))) // hotfix
        .then(sortWith([ascend(lessonUnixStartTime)]))
    },
    getBookableLessonsNextPage(_, url) {
      return this.$axios
        .get(`${url}`)
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    getBookableLessonsStartPage(_, { coach_id, filter }) {
      return this.$axios
        .get(
          `/api/booking-calendar/coaches/${coach_id}/bookable-lessons/?limit=30`,
          { params: filter }
        )
        .then(pathOr({ count: 0, results: [] }, ['data']))
    },
    localLessonSearch(_, { filter }): Promise<GetCalendarSlots.ResponseBody> {
      return this.$axios
        .get('api/booking-calendar/slots/', { params: filter })
        .then(pathOr([], ['data']))
    },
    getPopularCoaches({ commit, getters }): Promise<SearchCoach.ResponseBody> {
      return this.$axios
        .get('api/users/coaches/', {
          params: { ...getters.popularCoachesSearchPeriod, limit: 5 },
        })
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(tap((results) => commit('setPopularCoaches', results)))
    },
    getMorePopularCoaches({
      commit,
      getters,
    }): Promise<SearchCoach.ResponseBody> {
      return this.$axios
        .get(getters.moreCoachesUrl)
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(tap((results) => commit('addPopularCoaches', results)))
    },
    getClosePopularCoaches(
      { rootGetters, commit },
      coords = {}
    ): Promise<SearchCoach.ResponseBody> {
      return this.$axios
        .get('api/users/coaches/', {
          params: {
            ...coords,
            ...rootGetters['student_explore/popularCoachesSearchPeriod'],
            limit: 5,
          },
        })
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(tap((results) => commit('setClosePopularCoaches', results)))
    },
    getMoreClosePopularCoaches({
      commit,
      getters,
    }): Promise<SearchCoach.ResponseBody> {
      return this.$axios
        .get(getters.moreCloseCoachesUrl)
        .then(pathOr({ count: 0, results: [] }, ['data']))
        .then(tap((results) => commit('addClosePopularCoaches', results)))
    },
    getLatestCoach({
      commit,
      getters,
    }): Promise<GetLatestCoach.ResponseBody | object> {
      return this.$axios
        .get(`api/users/students/${getters.userId}/latest-coach/`)
        .then(pathOr({}, ['data']))
        .then(tap((result) => commit('setLatestCoach', result)))
    },
    getCoachCourses(
      _,
      coachId
    ): Promise<GetBookableCourse.ResponseBody['results']> {
      return this.$axios
        .get(
          `api/booking-calendar/coaches/${coachId}/bookable-courses/?limit=1000`
        )
        .then(pathOr([], ['data', 'results']))
    },
    getCoachDetailsById(
      _,
      id
    ): Promise<GetCoachDetails.ResponseBody | undefined> {
      return this.$axios.get(`api/users/coaches/${id}/`).then(path(['data']))
    },
    bookMultipleLessons(
      { dispatch },
      { coach_id, car, pickup_place, slots_ids, payment_method, is_package }
    ): Promise<PostLessonBulkBooking.ResponseBody | undefined> {
      const payload = {
        coach_id,
        car,
        pickup_place,
        slots_ids,
        is_package,
      } as PostLessonBulkBooking.RequestBody
      if (payment_method) {
        payload.payment_method = payment_method
      }
      return this.$axios
        .post(`api/booking-calendar/${coach_id}/slots/bulk-booking/`, payload)
        .then(path(['data']))
        .then((data: PostLessonBulkBooking.ResponseBody | undefined) => {
          data?.ga_data?.events?.forEach(({ name, params }) => {
            this.$fa.logEvent({ name, params })
            Smartlook.trackCustomEvent(new SmartlookCustomEvent(name, params))
          })
          return data
        })
        .then(tap(() => dispatch('reloadStudentPagesData')))
    },
    buyLoyaltyPackage({ dispatch }, { package_id, payment_method }) {
      return this.$axios
        .post(`api/loyalty/packages/${package_id}/buy/`, { payment_method })
        .then(path(['data']))
        .then(tap(() => dispatch('reloadStudentPagesData')))
    },
    buyCourse(
      { dispatch },
      { course_id, payment_method }
    ): Promise<BuyCourse.ResponseBody | undefined> {
      return this.$axios
        .post(`api/booking-calendar/courses/${course_id}/buy/`, {
          payment_method,
        })
        .then(path(['data']))
        .then((data: BuyCourse.ResponseBody | undefined) => {
          data?.ga_data?.events?.forEach(({ name, params }) => {
            this.$fa.logEvent({ name, params })
            Smartlook.trackCustomEvent(new SmartlookCustomEvent(name, params))
          })
          return data
        })
        .then(tap(() => dispatch('reloadStudentPagesData')))
    },
    storeUnfinishedBookingData(_, data) {
      return this.$axios.patch('api/users/me/', {
        student: { last_actions: data },
      })
    },
    getUnfinishedBookingData(): Promise<
      StudentProfileUserDetail['last_actions']
    > {
      // TODO negotiate a better type with BE (better than void)
      return this.$axios
        .get('api/users/me/')
        .then(path(['data', 'student', 'last_actions']))
    },
    clearUnfinishedBookingData() {
      return this.$axios.patch('api/users/me/', {
        student: { last_actions: {} },
      })
    },
    setupBookingOnLogin(_, data) {
      sessionStorage.setItem('delayedBookingData', JSON.stringify(data))
    },
    processDelayedBookingData({ dispatch }) {
      // uses session storage for guest booking case
      // uses remote storage for payment method creation case (available for logged in user)
      const bookingData = sessionStorage.getItem('delayedBookingData')
      if (bookingData) {
        sessionStorage.removeItem('delayedBookingData')
        const data = JSON.parse(bookingData)
        // send booking data to BE
        return dispatch('storeUnfinishedBookingData', data).then(() => data)
      } else {
        return dispatch('getUnfinishedBookingData').then((data) => {
          if (isNotNilOrEmpty(data)) {
            return data
          } else {
            throw NO_BOOKING_DATA
          }
        })
      }
    },
    reloadStudentPagesData({ dispatch }) {
      return Promise.all([
        dispatch('student_drives/loadPageData', null, { root: true }),
      ])
    },
    getReviews(_, { coachId, url }) {
      return this.$axios
        .get(url || `/api/reviews/coaches/${coachId}/reviews/?limit=5`)
        .then(path(['data']))
    },
    sendContactRequestToCoach(_, data) {
      return this.$axios
        .post('/api/notifications/contact/', data)
        .then(path(['data']))
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
