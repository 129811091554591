import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import {
  omit,
  path,
  tap,
  min,
  max,
  map,
  pathOr,
  memoizeWith,
  toString,
} from 'ramda'
import { formatISO, format } from 'date-fns'
import {
  Smartlook,
  SmartlookCustomEvent,
} from '@awesome-cordova-plugins/smartlook'
import {
  getLastUsedCalendarView,
  setLastUsedCalendarView,
} from '~/utils/commonutils'
import { calendarView, calendarZoomLevel } from '~/utils/constants'
import { BookingCalendar } from '~/types/__generated__/BookingCalendarRoute'
import { Users } from '~/types/__generated__/UsersRoute'
import GetSlotDetails = BookingCalendar.BookingCalendarSlotsRead
import SearchStudents = Users.UsersCoachesStudentsList
import SearchSlots = BookingCalendar.BookingCalendarSlotsList2
import PostBulkCreateSlots = BookingCalendar.BookingCalendarSlotsBulk
import MarkAsCashPaidExam = BookingCalendar.BookingCalendarExamsMarkAsPaidCreate
import MarkAsCashPaidLesson = BookingCalendar.BookingCalendarLessonsMarkAsPaidCreate
type CalendarEventDataType = Unpacked<SearchSlots.ResponseBody>
export const state = () => ({
  viewMode: getLastUsedCalendarView() || calendarView.timeGridDay,
  currentDayViewDate: formatISO(new Date()),
  filter: {},
  headerCollapsed: true,
  calendarSize: calendarZoomLevel.small,
})
const slotToEvent = (slot: CalendarEventDataType) => {
  return {
    ...slot,
    id: `${slot.id}`,
    className: 'lesson-slot',
    startEditable: true,
  }
}
export const getters = getterTree(state, {
  userId(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/userId']
  },
  deleteMode(state) {
    return state.viewMode === calendarView.scheduleDeletable
  },
})
export const mutations = mutationTree(state, {
  collapseHeader(_state) {
    _state.headerCollapsed = true
  },
  expandHeader(_state) {
    _state.headerCollapsed = false
  },
  setView(_state, viewMode) {
    _state.viewMode = viewMode
    setLastUsedCalendarView(viewMode)
  },
  setDayViewDate(_state, date) {
    _state.currentDayViewDate = date
  },
  increaseCalendarSize(_state) {
    _state.calendarSize = min(3, _state.calendarSize + 1)
  },
  decreaseCalendarSize(_state) {
    _state.calendarSize = max(1, _state.calendarSize - 1)
  },
})
export const actions = actionTree(
  { state, getters, mutations },
  {
    setToToday() {
      // Placeholder for in-component subscription
    },
    navNext() {
      // Placeholder for in-component subscription
    },
    navPrev() {
      // Placeholder for in-component subscription
    },
    refetchSlots() {
      // Placeholder for in-component subscription
    },
    // add
    setView({ commit }, view) {
      commit('setView', view)
    },
    updateDayViewDate({ commit }, e) {
      commit('setDayViewDate', e)
    },
    getCourseTemplatesList({ getters }) {
      return this.$axios
        .get(`api/booking-calendar/coaches/${getters.userId}/course-templates/`)
        .then(pathOr([], ['data']))
    },
    getCourseTemplate({ getters }, id) {
      return this.$axios
        .get(
          `api/booking-calendar/coaches/${getters.userId}/course-templates/${id}/`
        )
        .then(path(['data']))
    },
    addCourseTemplate({ getters }, data) {
      return this.$axios.post(
        `api/booking-calendar/coaches/${getters.userId}/course-templates/`,
        data
      )
    },
    updateCourseTemplate({ getters }, { id, data }) {
      return this.$axios.patch(
        `api/booking-calendar/coaches/${getters.userId}/course-templates/${id}/`,
        data
      )
    },
    deleteCourseTemplate({ getters }, id) {
      return this.$axios.delete(
        `api/booking-calendar/coaches/${getters.userId}/course-templates/${id}/`
      )
    },
    searchSlots: memoizeWith(
      (_, params) => {
        // throttles multiple requests on rerender, 1 second cache
        return toString(params) + format(new Date(), 'mmss')
      },
      function ({ getters }, params) {
        return this.$axios
          .get(`api/booking-calendar/${getters.userId}/slots/`, {
            params: { ...params, hide_deleted: true },
          })
          .then(pathOr([], ['data']))
          .then(map(slotToEvent))
      }
    ),
    addSlot({ dispatch, getters }, data) {
      const payload = {
        ...data,
        coach: getters.userId,
      }
      return this.$axios
        .post(`api/booking-calendar/${getters.userId}/slots/`, payload)
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    addBulkSlots(
      { dispatch, getters },
      data: PostBulkCreateSlots.RequestBody[]
    ) {
      const payload = data.map((slot) => {
        return { ...slot, coach: getters.userId }
      })
      return this.$axios
        .post(`api/booking-calendar/${getters.userId}/slots/bulk/`, payload)
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    markCourseAsPaid({ dispatch }, { id, course, coach }) {
      return this.$axios
        .post(
          `api/booking-calendar/coaches/${coach}/courses/${course}/students/${id}/mark-as-paid/`
        )
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    saveBulkSlotsTemplate({ getters }, data) {
      const payload = {
        ...data,
        coach_id: getters.userId,
      }
      return this.$axios
        .post(
          `api/booking-calendar/coaches/${getters.userId}/booking-template/`,
          payload
        )
        .then(path(['data']))
    },
    getBulkSlotsTemplate({ getters }) {
      return this.$axios
        .get(`api/booking-calendar/coaches/${getters.userId}/booking-template/`)
        .then(path(['data']))
    },
    addExam({ dispatch, getters }, data) {
      const payload = {
        ...data,
        coach: getters.userId,
      }
      return this.$axios
        .post(`api/booking-calendar/${getters.userId}/exams/`, payload)
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    getSlot(
      { getters },
      { slot_id, coach_id }
    ): Promise<GetSlotDetails.ResponseBody | undefined> {
      const coachId = coach_id || getters.userId
      return this.$axios
        .get(`api/booking-calendar/${coachId}/slots/${slot_id}/`)
        .then(path(['data']))
    },
    updateSlot({ dispatch, getters }, payload) {
      return this.$axios
        .patch(
          `api/booking-calendar/${getters.userId}/slots/${payload.id}/`,
          payload
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    updateSlotWithLesson({ dispatch, getters }, payload) {
      return this.$axios
        .patch(
          `api/booking-calendar/${getters.userId}/lessons/change/${payload.id}/`,
          omit(['id'], payload)
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    updateSlotWithExam({ dispatch, getters }, payload) {
      return this.$axios
        .patch(
          `api/booking-calendar/${getters.userId}/exams/change/${payload.id}/`,
          omit(['id'], payload)
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    cancelLesson({ dispatch, getters }, { id, data }) {
      return this.$axios
        .post(
          `api/booking-calendar/${getters.userId}/lessons/cancel/${id}/`,
          data
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    cancelExam({ dispatch, getters }, { id, data }) {
      return this.$axios
        .post(
          `api/booking-calendar/${getters.userId}/exams/cancel/${id}/`,
          data
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    confirmLesson({ dispatch, getters }, id) {
      return this.$axios
        .post(`api/booking-calendar/${getters.userId}/lessons/confirm/${id}/`)
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    confirmExam({ dispatch, getters }, id) {
      return this.$axios
        .post(`api/booking-calendar/${getters.userId}/exams/confirm/${id}/`)
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    finishLesson({ dispatch, getters }, id) {
      return this.$axios
        .post(`api/booking-calendar/${getters.userId}/lessons/complete/${id}/`)
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    finishExam({ dispatch, getters }, payload) {
      return this.$axios
        .post(
          `api/booking-calendar/${getters.userId}/exams/complete/${payload.id}/`,
          omit(['id'], payload)
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    markAsCashPaidLesson(
      { dispatch, getters },
      id
    ): Promise<MarkAsCashPaidLesson.ResponseBody | undefined> {
      return this.$axios
        .post(
          `api/booking-calendar/${getters.userId}/lessons/mark-as-paid/${id}/`
        )
        .then(path(['data']))
        .then((data: MarkAsCashPaidLesson.ResponseBody | undefined) => {
          data?.ga_data?.events?.forEach(({ name, params }) => {
            this.$fa.logEvent({ name, params })
            Smartlook.trackCustomEvent(new SmartlookCustomEvent(name, params))
          })
          return data
        })
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    markAsCashPaidExam(
      { dispatch, getters },
      id
    ): Promise<MarkAsCashPaidExam.ResponseBody | undefined> {
      return this.$axios
        .post(
          `api/booking-calendar/${getters.userId}/exams/mark-as-paid/${id}/`
        )
        .then(path(['data']))
        .then((data: MarkAsCashPaidExam.ResponseBody | undefined) => {
          data?.ga_data?.events?.forEach(({ name, params }) => {
            this.$fa.logEvent({ name, params })
            Smartlook.trackCustomEvent(new SmartlookCustomEvent(name, params))
          })
          return data
        })
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    markAsSkippedLesson({ dispatch, getters }, { id, data }) {
      return this.$axios
        .post(
          `api/booking-calendar/${getters.userId}/lessons/mark-as-skipped/${id}/`,
          data
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    markAsSkippedExam({ dispatch, getters }, { id, data }) {
      return this.$axios
        .post(
          `api/booking-calendar/${getters.userId}/exams/mark-as-skipped/${id}/`,
          data
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    reopenLessonSlot({ dispatch, getters }, { id, ...formData }) {
      return this.$axios
        .post(
          `api/booking-calendar/${getters.userId}/lessons/re-open/${id}/`,
          formData
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    removeSlot({ dispatch, getters }, { delete_mode, id }) {
      return this.$axios
        .delete(`api/booking-calendar/${getters.userId}/slots/${id}/`, {
          data: { delete_mode },
        })
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    collapseHeader({ commit }) {
      commit('collapseHeader')
    },
    expandHeader({ commit }) {
      commit('expandHeader')
    },
    searchStudents(
      { getters },
      params = { search: '' }
    ): Promise<SearchStudents.ResponseBody> {
      return this.$axios
        .get(`api/users/coaches/${getters.userId}/students/`, {
          params,
        })
        .then(pathOr([], ['data']))
    },
    reloadCoachPagesData({ dispatch }) {
      return Promise.all([
        dispatch('refetchSlots'),
        dispatch('coach_notifications/getNotifications', null, { root: true }),
      ])
    },
    increaseCalendarSize({ commit }) {
      commit('increaseCalendarSize')
    },
    decreaseCalendarSize({ commit }) {
      commit('decreaseCalendarSize')
    },
    addCourse({ getters, dispatch }, payload) {
      return this.$axios
        .post(
          `api/booking-calendar/coaches/${getters.userId}/courses/`,
          payload
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    updateCourse({ getters, dispatch }, { course_id, coach_id, data }) {
      const coachId = coach_id || getters.userId
      return this.$axios
        .patch(
          `api/booking-calendar/coaches/${coachId}/courses/${course_id}/`,
          data
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    cancelCourse(
      { dispatch, getters },
      { course_id, coach_id, cancellation_reason }
    ) {
      const coachId = coach_id || getters.userId
      return this.$axios
        .post(
          `api/booking-calendar/coaches/${coachId}/courses/${course_id}/cancel/`,
          { cancellation_reason }
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    finishCourse({ dispatch }, { course_id, coach_id }) {
      return this.$axios
        .post(
          `api/booking-calendar/coaches/${coach_id}/courses/${course_id}/complete/`
        )
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    deleteCourseSlot({ dispatch, getters }, { course_id, coach_id }) {
      const coachId = coach_id || getters.userId
      return this.$axios
        .delete(`api/booking-calendar/coaches/${coachId}/courses/${course_id}/`)
        .then(path(['data']))
        .then(
          tap(() => {
            dispatch('reloadCoachPagesData')
          })
        )
    },
    getCourseDetails({ getters }, { course_id, coach_id }) {
      const coachId = coach_id || getters.userId
      return this.$axios
        .get(
          `api/booking-calendar/coaches/${coachId}/courses/${course_id}/details/`
        )
        .then(path(['data']))
    },
    cancelCourseInvitation({ getters }, { course_id, student_id }) {
      return this.$axios
        .post(
          `api/booking-calendar/coaches/${getters.userId}/courses/${course_id}/students/${student_id}/cancel/`
        )
        .then(path(['data']))
    },
    setStudentCourseAttendance(
      { getters },
      { course_id, student_id, attended }
    ) {
      return this.$axios
        .patch(
          `api/booking-calendar/coaches/${getters.userId}/courses/${course_id}/students/${student_id}/`,
          { is_attended: attended }
        )
        .then(path(['data']))
    },
    inviteToCourse({ getters }, { course_id, phone }) {
      return this.$axios
        .post(
          `api/booking-calendar/coaches/${getters.userId}/courses/${course_id}/students/`,
          { phone }
        )
        .then(path(['data']))
    },
    deleteScheduleItems({ getters }, data) {
      return this.$axios.post(
        `/api/booking-calendar/coaches/${getters.userId}/delete-items/`,
        data
      )
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
