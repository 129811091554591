
import { pathOr, pipe, map, join, path } from 'ramda'
import { isPast, parseISO } from 'date-fns'
import {
  formatFullName,
  getSmallestImageURL,
} from '../../../../utils/commonutils'
import RatingReviewsSection from '../../../coach/coach_schedule/event_content/RatingReviewsSection'
import {
  COACH_GENDER_MALE,
  GENDER_LABELS,
  languages,
} from '../../../../utils/constants'
import i18nHelperMixin from '../../../../mixins/i18nHelperMixin'
import LessonStatusWidget from '../../student_drives/lesson_details_dialog/LessonStatusWidget'
import HeaderItem from '../../../shared/HeaderItem'
import PriceDetailsInfoMenu from '../../../shared/price_details/PriceDetailsInfoMenu'
import PolicyInfoMenuMixin from '../../../../mixins/PolicyInfoMenuMixin'
import LessonMedia from './LessonMedia'
import FailableImage from '~/components/shared/FailableImage.vue'

export default {
  name: 'LessonDetailsHeader',
  components: {
    PriceDetailsInfoMenu,
    HeaderItem,
    LessonMedia,
    LessonStatusWidget,
    RatingReviewsSection,
    FailableImage,
  },
  mixins: [i18nHelperMixin, PolicyInfoMenuMixin],
  props: {
    lessonDetails: {
      type: [Object, Boolean],
      default() {
        return null
      },
    },
    noLessons: {
      type: Boolean,
      default: false,
    },
    showCustomPickup: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      avatarModal: false,
    }
  },
  computed: {
    coach() {
      return this.lessonDetails?.coach
    },
    avatar() {
      return pipe(
        pathOr('', ['coach', 'avatar']),
        getSmallestImageURL
      )(this.lessonDetails)
    },
    lessonTitle() {
      return pathOr('', ['coach', 'school', 'name'], this.lessonDetails)
    },
    pricePerLesson() {
      return pathOr('', ['coach', 'price_per_lesson'], this.lessonDetails)
    },
    lessonDuration() {
      return pathOr('', ['coach', 'lessons_duration'], this.lessonDetails)
    },
    coachName() {
      return pipe(pathOr({}, ['coach']), formatFullName)(this.lessonDetails)
    },
    lessonLanguagesText() {
      return pipe(
        pathOr([], ['coach', 'available_languages']),
        map((locale) => this.$t(languages[locale])),
        join(', ')
      )(this.lessonDetails)
    },
    coachGender() {
      return pipe(pathOr(COACH_GENDER_MALE, ['coach', 'gender']), (gender) =>
        this.$t(GENDER_LABELS[gender])
      )(this.lessonDetails)
    },
    status() {
      return path(['status'], this.lessonDetails)
    },
    isPastLesson() {
      return isPast(parseISO(this.lessonDetails.slot_end))
    },
    allowedCustomPlaces() {
      return pathOr(
        false,
        ['coach', 'allow_custom_pickup_places'],
        this.lessonDetails
      )
    },
  },
  methods: {
    openPriceDetails() {
      this.showPriceDetails = true
    },
    handleAvatarClick() {
      this.avatarModal = true
    },
  },
}
