import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { prepareMedia } from '~/mixins/MediaFieldHelperMixin'
export const state = () => ({
  uploadingPicture: false,
  uploadingVideo: false,
})
export const mutations = mutationTree(state, {
  setPictureUploading(_state, loading) {
    _state.uploadingPicture = loading
  },
  setVideoUploading(_state, loading) {
    _state.uploadingVideo = loading
  },
  turnOffLoaders(_state) {
    _state.uploadingVideo = false
    _state.uploadingPicture = false
  },
})
export const getters = getterTree(state, {
  coach_avatar_media(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/coach_avatar_media']
  },
  additional_pictures(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/additional_pictures']
  },
  video(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/video']
  },
})
export const actions = actionTree(
  { state, getters, mutations },
  {
    uploadCoachMedia({ commit, dispatch }, coach) {
      return dispatch('profile/updateProfile', { coach }, { root: true }).then(
        () => commit('turnOffLoaders'),
        () => commit('turnOffLoaders')
      )
    },
    async uploadCoachVideo({ commit, dispatch }, file) {
      commit('setVideoUploading', true)
      const video = await dispatch('media/addVideoToBucket', file, {
        root: true,
      })
      return dispatch('uploadCoachMedia', prepareMedia({ video }, ['video']))
    },
    async uploadCoachPicture({ commit, dispatch, getters }, file) {
      commit('setPictureUploading', true)
      const additional_pictures = [
        ...getters.additional_pictures,
        await dispatch('media/addImageToBucket', file, { root: true }),
      ]
      const media = additional_pictures.map((p) => prepareMedia({ p }, ['p']).p)
      return dispatch('uploadCoachMedia', { additional_pictures: media })
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
