
import Snackbar from '../components/shared/Snackbar'
import ResponsiveScreenHelperMixin from '../mixins/ResponsiveScreenHelperMixin'
import NativeCapabilitiesControllerMixin from '../mixins/NativeCapabilitiesControllerMixin'
export default {
  components: { Snackbar },
  mixins: [NativeCapabilitiesControllerMixin, ResponsiveScreenHelperMixin],
  computed: {
    loginValue() {
      // fix bug if btn with such value doesn't exist, first btn in navigation will be selected
      if (this.$route.name === 'login') {
        return 'login'
      }
      return 'universalLogin'
    },
  },
  methods: {
    toGuestSearch() {
      this.$router.push({ name: 'index' })
    },
  },
}
