
import { sanitize } from 'dompurify'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import CharacterCount from '@tiptap/extension-character-count'
import Dropcursor from '@tiptap/extension-dropcursor'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import { mapActions } from 'vuex'
import { getSmallestImageURL } from '../../utils/commonutils'
import FileInputArea from './FileInputArea'
import TextPrompt from './TextPrompt'
export default {
  components: {
    TextPrompt,
    FileInputArea,
    EditorContent,
  },

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    forbidImages: {
      type: Boolean,
      default: false,
    },
    allowLinks: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      editor: null,
      linkPrompt: false,
      previousLink: '',
    }
  },
  computed: {
    sanitizedModelValue() {
      return sanitize(this.modelValue)
    },
    isItalicActive() {
      if (this.editor) {
        return this.editor.isActive('italic')
      }
      return false
    },
    isBoldActive() {
      if (this.editor) {
        return this.editor.isActive('bold')
      }
      return false
    },
    isUnderlineActive() {
      if (this.editor) {
        return this.editor.isActive('underline')
      }
      return false
    },
    isBulletListActive() {
      if (this.editor) {
        return this.editor.isActive('bulletList')
      }
      return false
    },
    isOrderedListActive() {
      if (this.editor) {
        return this.editor.isActive('orderedList')
      }
      return false
    },
    isLinkActive() {
      if (this.editor) {
        return this.editor.isActive('link')
      }
      return false
    },
    supportedImgFileTypes() {
      return ['image/png', 'image/jpg', 'image/jpeg'].join(',')
    },
  },
  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Underline,
        Image,
        Dropcursor,
        Link.configure({
          HTMLAttributes: {
            class: 'link-class',
            openOnClick: true,
            linkOnPaste: true,
          },
        }),
        CharacterCount.configure({ limit: this.limit }),
      ],
      content: this.modelValue,
      editorProps: {
        attributes: {
          class: 'rounded-b-lg pa-2',
        },
      },

      onUpdate: () => {
        // HTML
        this.$emit('update:modelValue', this.editor.getHTML())
      },
    })
  },
  beforeUnmount() {
    this.editor.destroy()
  },
  methods: {
    ...mapActions('media', ['addImageToPublicBucket']),
    sanitize,
    onImageAdd(file) {
      this.addImageToPublicBucket(file).then((image) => {
        if (image) {
          this.editor
            .chain()
            .focus()
            .setImage({ src: getSmallestImageURL(image) })
            .run()
        }
      })
    },
    doItalic() {
      this.editor.chain().focus().toggleItalic().run()
    },
    doBold() {
      this.editor.chain().focus().toggleBold().run()
    },
    doUnderline() {
      this.editor.chain().focus().toggleUnderline().run()
    },
    doBulletList() {
      this.editor.chain().focus().toggleBulletList().run()
    },
    doOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run()
    },
    undo() {
      this.editor.chain().focus().undo().run()
    },
    redo() {
      this.editor.chain().focus().redo().run()
    },
    promptLink() {
      this.previousLink = this.editor.getAttributes('link').href
      this.linkPrompt = true
    },
    makeLink(url) {
      // cancelled
      if (!url) {
        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url, target: '_blank' })
        .run()
    },
    removeLink() {
      this.editor.chain().focus().unsetLink().run()
    },
  },
}
