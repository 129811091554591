
import {
  TOPIC_GRADE_ADD,
  TOPIC_GRADE_GOOD,
  TOPIC_GRADE_INSUFFICIENT,
  TOPIC_GRADE_DISCUSSED,
  TOPIC_GRADE_READY,
  TOPIC_GRADE_REMOVE,
} from '../../utils/constants'
import FailableImage from './FailableImage.vue'
import SvgIcon from './SvgIcon'

export default {
  name: 'TopicRating',
  components: { SvgIcon, FailableImage },
  props: {
    rating: {
      type: [Number, String, null],
      default: null,
    },
    size: {
      type: Number,
      default: 48,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isRemoveTopicIcon() {
      return this.rating === TOPIC_GRADE_REMOVE
    },
    showImage() {
      return [
        TOPIC_GRADE_DISCUSSED,
        TOPIC_GRADE_INSUFFICIENT,
        TOPIC_GRADE_GOOD,
        TOPIC_GRADE_READY,
      ].includes(this.rating)
    },
    imageName() {
      if (this.rating === TOPIC_GRADE_DISCUSSED) {
        return 'not-covered'
      } else if (this.rating === TOPIC_GRADE_INSUFFICIENT) {
        return 'insufficient'
      } else if (this.rating === TOPIC_GRADE_GOOD) {
        return 'good'
      } else {
        return 'ready'
      }
    },
    iconName() {
      if (this.rating === TOPIC_GRADE_REMOVE) {
        return 'mdi-delete-forever-outline'
      } else if (this.rating === TOPIC_GRADE_ADD) {
        return 'mdi-plus'
      } else {
        // null
        return 'mdi-close'
      }
    },
    iconColor() {
      if (this.isRemoveTopicIcon) {
        return 'red darken-1'
      } else {
        return 'black'
      }
    },
    iconSize() {
      if (this.isRemoveTopicIcon) {
        return this.size
      } else {
        return (this.size * 5) / 12
      }
    },
    colorClasses() {
      if (this.rating === TOPIC_GRADE_DISCUSSED) {
        return 'grey lighten-5'
      } else if (this.isRemoveTopicIcon) {
        return ''
      } else {
        return 'grey lighten-4'
      }
    },
    wrapperSizeStyles() {
      // chart requires hardcoded size, compensate borders
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        flexBasis: `${this.size}px`,
      }
    },
  },
}
