
import { mapActions, mapGetters } from 'vuex'
import { clone, pick } from 'ramda'
import WeekTimeSelection from './WeekTimeSelection'
import ConfirmDeleteFormNotificationDialog from './ConfirmDeleteFormNotificationDialog'
import SectionTitle from '~/components/shared/SectionTitle'
import ResponsiveScreenHelperMixin from '~/mixins/ResponsiveScreenHelperMixin'
import FormHelperMixin from '~/mixins/FormHelperMixin'
import goNextFieldOnEnterMixin from '~/mixins/goNextFieldOnEnterMixin'
import { formatFullName } from '~/utils/commonutils'

export default {
  name: 'ContactDialog',
  components: {
    WeekTimeSelection,
    SectionTitle,
    ConfirmDeleteFormNotificationDialog,
  },
  mixins: [
    ResponsiveScreenHelperMixin,
    FormHelperMixin,
    goNextFieldOnEnterMixin,
  ],
  props: {
    coach: {
      type: Object,
      default() {
        return {}
      },
    },
    contactForm: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phone: '',
        time: [
          [false, false, false, false, false, false, false],
          [false, false, false, false, false, false, false],
          [false, false, false, false, false, false, false],
        ],
        message: '',
      },
      formRules: {
        name: [
          this.requiredCheck(this.$t('contactDialog.nameLabel')),
          this.checkPropHasErrorSet(['name']),
        ],
        email: [this.checkPropHasErrorSet(['email'])],
        phone: [
          this.requiredCheck(this.$t('contactDialog.phoneLabel')),
          this.checkPropHasErrorSet(['phone']),
        ],
        message: [this.checkPropHasErrorSet(['message'])],
      },
      confirmDeleteDialog: false,
    }
  },
  computed: {
    ...mapGetters(['lastUsedLanguageGetter']),
    coachName() {
      return formatFullName(this.coach)
    },
  },
  mounted() {
    if (this.contactForm) {
      const time = clone(this.formData.time)
      this.contactForm.time.forEach(([period, day]) => {
        time[period][day] = true
      })
      this.formData = { ...this.contactForm, time }
    }
    this.resetValidation()
    this.$refs.firstField?.focus()
  },
  methods: {
    ...mapActions('student_explore', ['sendContactRequestToCoach']),
    ...mapActions('coach_notifications', ['deleteContactNotification']),
    ...mapActions('app_snackbars', ['showSuccessNotification']),
    onClose() {
      this.$emit('close')
    },
    async onDelete() {
      try {
        await this.deleteContactNotification(this.contactForm.id)
        this.onClose()
      } catch (e) {
        this.showRequestErrorMessage(e)
      }
    },
    async onSend() {
      this.$fa.logEvent({ name: 'contact_form_click_button' })
      try {
        if (this.$refs.form.validate()) {
          await this.sendContactRequestToCoach({
            ...pick(['name', 'email', 'phone', 'message'], this.formData),
            time: this.convertToTimeDayPairs(this.formData.time),
            language: this.lastUsedLanguageGetter(this),
            coach: this.coach?.id,
            school: this.coach?.school?.id,
          })
          this.$fa.logEvent({ name: 'contact_form_completed' })
          this.showSuccessNotification(
            'contactDialog.contactRequestSuccessNotification'
          )
          this.$emit('close')
        }
      } catch (e) {
        if (this.responseErrorIsClientValidation(e)) {
          this.formErrors = this.getResponseValidationErrorData(e)
          this.$refs.form.validate()
        }
        this.showRequestErrorMessage(e)
      }
    },
    convertToTimeDayPairs(time) {
      return time.reduce((acc, dayRow, dayRowIndex) => {
        return acc.concat(
          dayRow.reduce((checkedDays, dayValue, dayIndex) => {
            if (dayValue) {
              checkedDays.push([dayRowIndex, dayIndex])
            }
            return checkedDays
          }, [])
        )
      }, [])
    },
  },
}
