
import { parseISO, format } from 'date-fns'
import { pathOr } from 'ramda'
import FixLargeListRenderingMixin from '../../../../../mixins/FixLargeListRenderingMixin'
export default {
  name: 'LessonItem',
  mixins: [FixLargeListRenderingMixin],
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    errorState: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    time() {
      const { start, end } = this.details
      if (!start) {
        return ''
      }
      return `${format(parseISO(start), 'HH:mm')} - ${format(
        parseISO(end),
        'HH:mm'
      )}`
    },
    pricePerLesson() {
      return pathOr('', ['price'], this.details)
    },
  },
  methods: {
    handleClick() {
      this.$emit('select')
    },
  },
}
