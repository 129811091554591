
import CarItem from '../../../coach/coach_profile/car_details/CarItem'
export default {
  name: 'CarSingleSelect',
  components: { CarItem },
  props: {
    cars: {
      type: Array,
      default() {
        return []
      },
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
  methods: {
    onCarSelect({ id }) {
      this.$emit('input', id)
    },
  },
}
