import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { path, tap } from 'ramda'
import { CancellationPolicy } from '~/types/__generated__/CancellationPolicyRoute'
import GetCancellationPolicy = CancellationPolicy.CancellationPolicyCoachesCancellationPoliciesList
import UpdateCancellationPolicy = CancellationPolicy.CancellationPolicyCoachesCancellationPoliciesPartialUpdate
export const state = () => ({
  cancellation_policy: { fee: 0, time: null },
})

export const getters = getterTree(state, {
  coach_cancellation_policy({ cancellation_policy }) {
    return cancellation_policy
  },
  userId(_state, _getters, _rootState, rootGetters) {
    return rootGetters['profile/userId']
  },
})
export const mutations = mutationTree(state, {
  setCancellationPolicy(_state, data) {
    _state.cancellation_policy = data
  },
})
export const actions = actionTree(
  { state, getters, mutations },
  {
    getCancellationPolicy({
      commit,
      getters,
    }): Promise<Unpacked<GetCancellationPolicy.ResponseBody> | undefined> {
      return this.$axios
        .get(
          `/api/cancellation-policy/coaches/${getters.userId}/cancellation-policies/`
        )
        .then(path(['data', '0']))
        .then(tap((data) => commit('setCancellationPolicy', data)))
    },
    updateCancellationPolicy(
      { commit, getters },
      { id, fee, time }
    ): Promise<UpdateCancellationPolicy.ResponseBody | undefined> {
      return this.$axios
        .patch(
          `/api/cancellation-policy/coaches/${getters.userId}/cancellation-policies/${id}/`,
          { fee, time }
        )
        .then(path(['data']))
        .then(tap((data) => commit('setCancellationPolicy', data)))
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
