
import CardWrapper from '../../../../shared/CardWrapper'
import CarDetailsMixin from '../../../../../mixins/CarDetailsMixin'
import { getLargeImageURL } from '../../../../../utils/commonutils'
import FailableImage from '~/components/shared/FailableImage.vue'

export default {
  name: 'CarItem',
  components: { CardWrapper, FailableImage },
  mixins: [CarDetailsMixin],
  props: {
    car: {
      type: Object,
      default() {
        return {}
      },
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogImageSrc: '',
    }
  },
  methods: {
    closeDialog() {
      this.dialogImageSrc = ''
    },
    showPictureFullScreen() {
      this.dialogImageSrc = getLargeImageURL(this.car.picture)
    },
  },
}
