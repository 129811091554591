
import { isNotNilOrEmpty } from 'ramda-adjunct'
import SectionTitle from '../SectionTitle'
import { EXAM_STATUS } from '../../../utils/constants'
import FailableImage from '../FailableImage.vue'

export default {
  name: 'RateExamSection',
  components: { SectionTitle, FailableImage },
  props: {
    examRating: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      EXAM_STATUS,
    }
  },
  computed: {
    showSelection() {
      return isNotNilOrEmpty(this.examRating) || !this.disabled
    },
  },
  methods: {
    onRatingChange(e) {
      if (!this.disabled) {
        this.$emit('update:examRating', e)
      }
    },
  },
}
