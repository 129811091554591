import { pathOr } from 'ramda'
import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { Locations } from '~/types/__generated__/LocationsRoute'
import GetCoachAreas = Locations.LocationsPostalCodesList2
import GetMapAreas = Locations.LocationsCoordinatesList
export const state = () => ({})
export const getters = getterTree(state, {})
export const mutations = mutationTree(state, {})
export const actions = actionTree(
  { state, getters, mutations },
  {
    getCoachAreasOfOperation(
      _,
      { coach_id }
    ): Promise<GetCoachAreas.ResponseBody> {
      return this.$axios
        .get(`/api/locations/${coach_id}/postal-codes/`)
        .then(pathOr([], ['data']))
    },
    getAdditionalAreas(_state, polygon: google.maps.LatLng[]): Promise<GetMapAreas.ResponseBody> {
      const url = polygon.reduce(
        (res, { lng, lat }) => res + `polygon=${lat()},${lng()}&`,
        '/api/locations/coordinates/?'
      )
      return this.$axios.get(url).then(pathOr([],['data']))
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
