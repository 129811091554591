
import { prop, without, find, whereEq, includes } from 'ramda'
import WeekContainer from '../../../../shared/WeekContainer'
import DayContainer from '../../../../shared/DayContainer'
import EventsGroupByWeekMixin from '../../../../../mixins/EventsGroupByWeekMixin'
import LessonItem from './LessonItem'

export default {
  name: 'LessonSelection',
  components: { WeekContainer, LessonItem, DayContainer },
  mixins: [EventsGroupByWeekMixin],
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    importantLesson: {
      type: Number,
      default: 0,
    },
    value: {
      type: Array,
      default() {
        return []
      },
    },
    lessons: {
      type: Array,
      default() {
        return []
      },
    },
    commonCars: {
      type: Array,
      default() {
        return []
      },
    },
    commonPlaces: {
      type: Array,
      default() {
        return []
      },
    },
    allowedCustomPlaces: {
      type: Boolean,
      default: false,
    },
    referenceItem: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      errorSlots: {},
    }
  },
  computed: {
    events() {
      return this.lessons
    },
  },
  methods: {
    onSelectionChange(id) {
      if (this.value.includes(id)) {
        this.$emit('input', without([id], this.value))
      } else if (this.checkSlotCompatible(id)) {
        this.$emit('input', this.value.concat(id))
      } else {
        this.$set(this.errorSlots, id, true)
      }
    },
    clearError(id) {
      this.$set(this.errorSlots, id, false)
    },
    checkSlotCompatible(id) {
      if (!this.value.length) {
        return true
      }
      const lesson = find(whereEq({ id }), this.lessons)
      if (!this.allowedCustomPlaces) {
        const hasSamePlace = lesson.pickup_places.some(({ id }) =>
          includes(id, this.commonPlaces.map(prop('id')))
        )
        if (!hasSamePlace) {
          return false
        }
      }

      const hasSameCar = lesson.cars.some(({ id }) =>
        includes(id, this.commonCars.map(prop('id')))
      )
      if (!hasSameCar) {
        return false
      }

      return true
    },
    getItemDetails(item) {
      return item
    },
  },
}
