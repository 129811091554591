import { isEmpty } from 'ramda'
import { studentProfileComplete } from '../utils/commonutils'
import { COACH_ROLE, STUDENT_ROLE } from '../utils/constants'
// logged in users are automatically redirected to home page
// from guest routes by nuxt-auth module
const GUEST_ROUTES = [
  'index',
  'login',
  'universalLogin',
  'invitation-invitationId',
  'fahrschule',
  'redirectStudentProfile',
]

const COACH_PROTECTED_ROUTES = [
  'coachProfile',
  'coachSchedule',
  'coachNotifications',
  'coachStudents',
]
const STUDENT_PROTECTED_ROUTES = [
  'studentProfile',
  'studentDrives',
  'studentExplore',
  'studentProgress',
]
export default async function (app) {
  // make sure that logged in user uses only relevant routes
  const { store, route, redirect, from } = app
  if (
    !GUEST_ROUTES.includes(route.name) &&
    !store.getters['profile/profileLoaded'] &&
    !route.path.includes('fahrschule')
  ) {
    await store.dispatch('profile/getProfile')
  }
  const role = store.getters['profile/userRole']
  if (![COACH_ROLE, STUDENT_ROLE].includes(role) && from.query.referred_by) {
    app.$cookies.set('referred_by', from.query.referred_by)
  }
  // Data fetching
  if (role === STUDENT_ROLE && STUDENT_PROTECTED_ROUTES.includes(route.name)) {
    store.dispatch('student_drives/startDataFetching')
  } else {
    store.dispatch('student_drives/stopDataFetching')
  }
  if (role === COACH_ROLE && COACH_PROTECTED_ROUTES.includes(route.name)) {
    store.dispatch('coach_notifications/startDataFetching')
  } else {
    store.dispatch('coach_notifications/stopDataFetching')
  }
  // Redirects

  if (role === STUDENT_ROLE && COACH_PROTECTED_ROUTES.includes(route.name)) {
    const data = await store.dispatch('student_drives/loadPageData')
    const hasData = data.every((item) => item?.length)
    return redirect({
      name: studentProfileComplete(store.$auth.user)
        ? 'studentDrives'
        : hasData
        ? 'studentProfile'
        : 'studentExplore',
      query: from.query,
    })
  } else if (
    role === COACH_ROLE &&
    STUDENT_PROTECTED_ROUTES.includes(route.name)
  ) {
    if (route.name === 'studentProfile') {
      return redirect({ name: 'coachProfile' })
    }
    return redirect({ name: 'coachProfile' })
  }
  const [_, driveSchool, alias] = route.path.split('/')
  if (driveSchool === 'fahrschule') {
    if (role === COACH_ROLE || !alias) {
      return redirect({ name: '/', query: route.query })
    }
    const [coach] = await store.dispatch('profile/getCoachByAlias', alias)
    if (role !== STUDENT_ROLE) {
      app.$cookies.set('referred_by', coach?.id)
    }
    const query = coach ? { ...route.query, coach_id: coach.id } : route.query
    return redirect({
      name: role === STUDENT_ROLE ? 'studentExplore' : '/',
      query,
    })
  }

  if (
    role === STUDENT_ROLE &&
    GUEST_ROUTES.includes(from.name) &&
    ['studentProfile', 'studentDrives'].includes(route.name) &&
    !isEmpty(from.query) &&
    !from.params.invitationId
  ) {
    return redirect({ name: 'studentExplore', query: from.query })
  }
}
