
import WeekContainer from '../../../../shared/WeekContainer'
import DayContainer from '../../../../shared/DayContainer'
import EventsGroupByWeekMixin from '../../../../../mixins/EventsGroupByWeekMixin'
import CourseOfferItem from './CourseOfferItem'
export default {
  name: 'CourseOffersList',
  components: { CourseOfferItem, DayContainer, WeekContainer },
  mixins: [EventsGroupByWeekMixin],
  props: {
    events: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
