
import { pathOr, pipe, map, join, path, pathEq } from 'ramda'
import { format, isPast, parseISO } from 'date-fns'
import {
  formatFullName,
  formatLessonDuration,
  getSmallestImageURL,
} from '../../../../utils/commonutils'
import {
  COACH_GENDER_MALE,
  GENDER_LABELS,
  languages,
  LESSON_STATUS_COACH_APPROVED,
  LESSON_STATUS_COACH_CANCELLED,
  LESSON_STATUS_DID_NOT_TAKE_PLACE,
  LESSON_STATUS_STUDENT_APPROVED,
  slotTypes,
} from '../../../../utils/constants'
import i18nHelperMixin from '../../../../mixins/i18nHelperMixin'
import LessonMedia from '../../student_explore/booking/LessonMedia'
import HeaderItem from '../../../shared/HeaderItem'
import UnpaidStatusWidget from '../../../shared/UnpaidStatusWidget'
import PlaceSingleSelect from '../../student_explore/booking/PlaceSingleSelect'
import PriceDetailsInfoMenu from '../../../shared/price_details/PriceDetailsInfoMenu'
import PolicyInfoMenuMixin from '../../../../mixins/PolicyInfoMenuMixin'
import LessonStatusWidget from './LessonStatusWidget'
import FailableImage from '~/components/shared/FailableImage.vue'

export default {
  name: 'LessonDetailsHeader',
  components: {
    PriceDetailsInfoMenu,
    PlaceSingleSelect,
    UnpaidStatusWidget,
    LessonMedia,
    LessonStatusWidget,
    HeaderItem,
    FailableImage,
  },
  mixins: [i18nHelperMixin, PolicyInfoMenuMixin],
  props: {
    lessonDetails: {
      type: [Object, Boolean],
      default() {
        return null
      },
    },
    confirmedLessonView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatar() {
      return pipe(
        pathOr('', ['coach', 'avatar']),
        getSmallestImageURL
      )(this.lessonDetails)
    },
    lessonTitle() {
      return pathOr('', ['coach', 'school', 'name'], this.lessonDetails)
    },
    isExam() {
      return pathEq(['type'], slotTypes.exam, this.lessonDetails)
    },
    pricePerLesson() {
      return pathOr('', ['price'], this.lessonDetails)
    },
    lessonDuration() {
      return formatLessonDuration(this.dateFNSLocale, {
        start: this.lessonDetails.slot_start,
        end: this.lessonDetails.slot_end,
      })
    },
    coachName() {
      return pipe(pathOr({}, ['coach']), formatFullName)(this.lessonDetails)
    },
    car() {
      return pathOr({}, ['car'], this.lessonDetails)
    },
    carBrand() {
      return pathOr('', ['model', 'brand'], this.car)
    },
    carModel() {
      return pathOr('', ['model', 'model'], this.car)
    },
    carName() {
      return `${this.carBrand} ${this.carModel}`
    },
    carImageURL() {
      return getSmallestImageURL(this.car.picture)
    },
    coachGender() {
      return pipe(pathOr(COACH_GENDER_MALE, ['coach', 'gender']), (gender) =>
        this.$t(GENDER_LABELS[gender])
      )(this.lessonDetails)
    },
    lessonLanguagesText() {
      return pipe(
        pathOr([], ['coach', 'available_languages']),
        map((locale) => this.$t(languages[locale])),
        join(', ')
      )(this.lessonDetails)
    },
    status() {
      return path(['status'], this.lessonDetails)
    },

    isPastLesson() {
      return isPast(parseISO(this.lessonDetails.slot_end))
    },
    date() {
      return format(parseISO(this.lessonDetails.slot_start), 'dd.MM.yyyy')
    },
    time() {
      return `${format(
        parseISO(this.lessonDetails.slot_start),
        'HH:mm'
      )} - ${format(parseISO(this.lessonDetails.slot_end), 'HH:mm', {
        locale: this.dateFNSLocale,
      })}`
    },
    dateTime() {
      return format(
        parseISO(this.lessonDetails.slot_start),
        'dd.MM.yyyy, hh:mm a'
      )
    },
    isCashPaymentExpected() {
      return path(['is_cash_payment_expected'], this.lessonDetails)
    },
    cancelReason() {
      return path(['cancellation_reason'], this.lessonDetails)
    },
    showCancelReason() {
      return (
        [
          LESSON_STATUS_COACH_CANCELLED,
          LESSON_STATUS_DID_NOT_TAKE_PLACE,
        ].includes(this.status) && !!this.cancelReason
      )
    },
    cancellationPolicyPath() {
      if ([LESSON_STATUS_COACH_APPROVED].includes(this.status)) {
        return ['coach', 'cancellation_policy']
      }
      return ['cancellation_policy']
    },
    showCoachMedia() {
      return [
        LESSON_STATUS_COACH_APPROVED,
        LESSON_STATUS_STUDENT_APPROVED,
      ].includes(this.status)
    },
    lessonPlace() {
      return path(['pickup_place'], this.lessonDetails)
    },
  },
}
