import {
  path,
  pathOr,
  pathEq,
  set,
  lensPath,
  values,
  cond,
  hasPath,
  when,
  head,
  pipe,
  T,
} from 'ramda'
import { isNotNilOrEmpty, isPlainObj, isArray, isInteger } from 'ramda-adjunct'
import { mapActions } from 'vuex'
import { htmlToText } from '~/utils/commonutils'
export default {
  data() {
    return {
      formErrors: {},
    }
  },
  methods: {
    ...mapActions('app_snackbars', ['showSnackbar', 'showError']),
    showRequestErrorMessage(e, additionalPaths) {
      const message = this.getErrorMessage(e, additionalPaths)
      if (message) {
        this.showError(message)
      } else if (
        this.isErrorResponse(e) &&
        !this.responseErrorIsClientValidation(e)
      ) {
        // 401-599 response cases with no message found
        this.showError(this.$t('errorsMessages.genericRequestError'))
      }
    },
    fieldErrorReset(path) {
      this.formErrors = set(lensPath(path), [], this.formErrors)
    },
    resetValidation() {
      this.formErrors = {}
      this.$refs.form && this.$refs.form.resetValidation()
    },
    fieldError(way) {
      const error = path([...way])(this.formErrors)
      if (isPlainObj(error)) {
        return pathOr('', ['0', '0'])(values(error))
      }
      return pathOr('', [...way, '0'])(this.formErrors)
    },
    checkPropHasErrorSet(path) {
      return () => {
        const firstError = this.fieldError(path)
        return !firstError || firstError
      }
    },
    responseErrorIsClientValidation(e) {
      return pathEq(['response', 'status'], 400)(e)
    },
    isErrorResponse(e) {
      return path(['response', 'status'], e) >= 400
    },
    getErrorMessage(e, additionalPaths = []) {
      return pipe(
        cond([
          [
            hasPath(['response', 'data', 'message']),
            pathOr('', ['response', 'data', 'message']),
          ],
          [
            hasPath(['response', 'data', 'detail']),
            pathOr('', ['response', 'data', 'detail']),
          ],
          [
            hasPath(['response', 'data', 'details']),
            pathOr('', ['response', 'data', 'details']),
          ],
          [
            hasPath(['response', 'data', 'non_field_errors']),
            pathOr('', ['response', 'data', 'non_field_errors']),
          ],
          [
            hasPath(['response', 'data', 'slots_ids']),
            pathOr('', ['response', 'data', 'slots_ids']),
          ],
          [
            hasPath(['response', 'data', 'code']),
            pathOr('', ['response', 'data', 'code']),
          ],
          [
            // Datatrans payment button
            hasPath(['error', 'message']),
            pathOr('', ['error', 'message']),
          ],
          ...additionalPaths.map((path) => [hasPath(path), pathOr('', path)]),
          [(T, () => '')],
        ]),
        when(isArray, head)
      )(e)
    },
    getResponseValidationErrorData(e) {
      return pathOr({}, ['response', 'data'])(e)
    },
    regexValidCheck(message, regex = /^[\w\d-]+$/) {
      return (v) => !v || regex.test(v) || message
    },
    requiredCheck(label = this.$t('errorsMessages.defaultLabel')) {
      return (v) =>
        isNotNilOrEmpty(v) ||
        this.$t('errorsMessages.required', {
          label,
        })
    },
    requiredHTMLTextCheck(label = this.$t('errorsMessages.defaultLabel')) {
      return (v) =>
        isNotNilOrEmpty(htmlToText(v)) ||
        this.$t('errorsMessages.required', {
          label,
        })
    },
    minLengthCheck(length) {
      return (v) =>
        (v && v.length >= length) ||
        this.$t('errorsMessages.minLengthCharacters', {
          length,
        })
    },
    minNumCheck(length) {
      return (v) =>
        ((v || v === 0) && +v >= length) ||
        this.$t('errorsMessages.minNumCheck', { length })
    },
    maxNumCheck(length) {
      return (v) =>
        ((v || v === 0) && +v <= length) ||
        this.$t('errorsMessages.maxNumCheck', { length })
    },
    numStepCheck(step, quantifier = '') {
      return (v) =>
        +v % step === 0 ||
        this.$t('errorsMessages.minStepNum', {
          step,
          quantifier,
        })
    },
    integerCheck() {
      return (v) => isInteger(+v) || this.$t('errorsMessages.integerCheck')
    },
    rappenStepCheck(step, quantifier = '') {
      return (v) =>
        (+v * 100) % (step * 100) === 0 ||
        Math.round((+v * 100) % (step * 100)) === step * 100 || // hello IEEE 754
        this.$t('errorsMessages.minStepNum', {
          step,
          quantifier,
          calculation: ((+v * 100) % step) * 100,
        })
    },
  },
}
