
import format from 'date-fns/esm/format/index'
import RatingCalculationMixin from '../../mixins/RatingCalculationMixin'
import RateCoachMenu from '../student/student_drives/RateCoachMenu'

export default {
  name: 'CoachReview',
  components: { RateCoachMenu },
  mixins: [RatingCalculationMixin],
  props: {
    review: {
      type: Object,
      default: () => ({}),
    },
    coachId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      edit: false,
    }
  },
  computed: {
    value() {
      return this.review.value
    },
    date() {
      return format(new Date(this.review.created_at), 'dd MMMM yyyy')
    },
    userName() {
      const { is_owner = false, first_name } = this.review
      return is_owner
        ? this.$t('coachRating.yourNameInReview', { name: first_name })
        : first_name
    },
  },
}
