
export default {
  name: 'WidgetTagBar',
  props: {
    wide: {
      type: Boolean,
      default: true,
    },
    colorTags: {
      type: Array,
      default: () => [],
    },
    roundedBorder: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    car: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: true,
    },
  },
}
