
import CardWrapper from '../CardWrapper'
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'

export default {
  name: 'PackageItem',
  components: { CardWrapper },
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    packageItem: {
      type: Object,
      default: () => ({}),
    },
  },
}
