
import { mapGetters } from 'vuex'
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'
import { getLargeImageURL } from '../../../utils/commonutils'
import FailableImage from '../FailableImage.vue'

export default {
  name: 'StudentPermitImageDialog',
  components: { FailableImage },
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    studentPermit: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isZoomState: false,
    }
  },
  computed: {
    ...mapGetters(['isNativePlatform']),
    studentPermitImage() {
      return getLargeImageURL(this.studentPermit)
    },
    isZoomAvailable() {
      return (
        this.isZoomState &&
        (this.isNativePlatform || this.$device.isMobileOrTablet)
      )
    },
  },
}
