
import { find, pathOr, whereEq } from 'ramda'
export default {
  name: 'BookingSummarySection',
  props: {
    cars: {
      type: Array,
      default() {
        return []
      },
    },
    places: {
      type: Array,
      default() {
        return []
      },
    },
    car: {
      type: [Number, String],
      default: '',
    },
    place: {
      type: [Number, String],
      default: '',
    },
    lessonsQuantity: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: [Number, String],
      default: '',
    },
  },
  computed: {
    carInfo() {
      if (!this.car) {
        return this.$t('studentLessonBookingSummary.noCar')
      }
      const carDetails = find(whereEq({ id: this.car }))(this.cars)
      if (carDetails) {
        const brand = pathOr('', ['model', 'brand'], carDetails)
        const model = pathOr('', ['model', 'model'], carDetails)
        return `${brand} ${model}`
      } else {
        return this.$t('studentLessonBookingSummary.noCar')
      }
    },
    placeInfo() {
      if (!this.place) {
        return this.$t('studentLessonBookingSummary.noPlace')
      }
      const placeDetails = find(whereEq({ id: this.place }))(this.places)
      if (placeDetails) {
        return pathOr(
          placeDetails.name,
          ['meta', 'formatted_address'],
          placeDetails
        )
      } else {
        return this.$t('studentLessonBookingSummary.noPlace')
      }
    },
    lessonInfo() {
      return `${this.$tc(
        'studentLessonBookingSummary.lessonAmount',
        this.lessonsQuantity,
        { count: this.lessonsQuantity }
      )}, ${this.totalPrice}`
    },
  },
}
