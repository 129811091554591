import { path, tap } from 'ramda'
import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { Payments } from '~/types/__generated__/PaymentsRoute'
import GetBankDetails = Payments.PaymentsBankDetailsRead
import GetBankDetailsChangeLink = Payments.PaymentsBankDetailsChangeLinkRead
export const state = () => ({
  iban: '',
  holder_name: '',
  bank_name: '',
})

export const getters = getterTree(state, {})
export const mutations = mutationTree(state, {
  setDetails(_state, { iban, holder_name, bank_name }) {
    _state.iban = iban
    _state.holder_name = holder_name
    _state.bank_name = bank_name
  },
})
export const actions = actionTree(
  { state, getters, mutations },
  {
    getDetails({
      rootGetters,
      commit,
    }): Promise<GetBankDetails.ResponseBody | undefined> {
      return this.$axios
        .get(`/api/payments/${rootGetters['profile/userId']}/bank-details/`)
        .then(path(['data']))
        .then(
          tap((details) => {
            commit('setDetails', details)
          })
        )
    },
    getChangeLink({
      rootGetters, commit
    }): Promise<GetBankDetailsChangeLink.ResponseBody | undefined> {
      return this.$axios
        .get(
          `/api/payments/${rootGetters['profile/userId']}/bank-details-change-link/`,
           {
          params: {
            native_app: rootGetters.isNativePlatform,
          },
        }
        )
        .then(path(['data']))
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
