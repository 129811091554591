export default {
  watch: {
     dialog: {
      handler(open) {
        if (open) {
          this.resetForm()
        }
      },
    },
  }
}
