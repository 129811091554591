import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update'
import { mapGetters } from 'vuex'
import { SplashScreen } from '@capacitor/splash-screen'
import { Contacts } from '@capacitor-community/contacts'
import { StatusBar } from '@capacitor/status-bar'
import { Keyboard } from '@capacitor/keyboard'
import { App } from '@capacitor/app'
import { PLATFORMS } from '../utils/constants'

export default {
  data() {
    return {
      isKeyboardShown: false,
    }
  },
  async mounted() {
    if (this.isNativePlatform) {
      await this.checkUpdates()
      await this.hideSplashScreen()

      Keyboard.addListener('keyboardDidHide', () => {
        StatusBar.show()
        this.$nextTick(() => {
          this.isKeyboardShown = false
        })
      })
      Keyboard.addListener('keyboardDidShow', () => {
        StatusBar.hide()
        this.$nextTick(() => {
          this.isKeyboardShown = true
        })
      })

      App.addListener('appUrlOpen', (event) => {
        const slug = event.url.split('.ch').pop().replace(/\/&/, '/?') // TODO implement spliting via BROWSER_BASE_URL
        if (slug) {
          this.$router.push({
            path: slug,
          })
        }
      })
      App.addListener('appStateChange', ({ isActive }) => {
        if (!isActive) {
          return
        }

        this.checkUpdates()
      })
    }
  },
  beforeDestroy() {
    if (this.isNativePlatform) {
      App.removeAllListeners()
    }
  },
  methods: {
    async checkUpdates() {
      try {
        const { updateAvailability } = await AppUpdate.getAppUpdateInfo()
        if (
          [
            AppUpdateAvailability.UPDATE_AVAILABLE,
            AppUpdateAvailability.UPDATE_IN_PROGRESS,
          ].includes(updateAvailability)
        ) {
          AppUpdate.openAppStore()
        }
      } catch (error) {
        console.error('Error checking for updates:', error)
      }
    },
    async showSplashScreen() {
      /** Show the splash for an indefinite amount of time: */
      await SplashScreen.show({ autoHide: false })
    },
    async hideSplashScreen() {
      /** Hide the splash (you should do this on app launch) */
      await SplashScreen.hide()
    },
    getDeviceContacts() {
      return Contacts.getPermissions()
        .then(Contacts.getContacts)
        .then(({ contacts }) => contacts)
    },
  },
  computed: {
    ...mapGetters(['isCurrentPlatform', 'isNativePlatform']),
    isiOSCurrentPlatform() {
      return this.isCurrentPlatform(PLATFORMS.IOS)
    },
    isAndroidCurrentPlatform() {
      return this.isCurrentPlatform(PLATFORMS.ANDROID)
    },
    isStatusBarPlaceholderShown() {
      return this.isCurrentPlatform(PLATFORMS.IOS) && !this.isKeyboardShown
    },
  },
}
