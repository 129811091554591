
import { mapActions, mapGetters } from 'vuex'
import { pick, toPairs, pipe } from 'ramda'
import DialogOrBottomSheet from '../DialogOrBottomSheet'
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'
import DatatransLightboxMixin from '../../../mixins/DatatransLightboxMixin'
import {
  paymentMethods,
  externalPaymentMethods,
} from '../../../utils/constants'
export default {
  name: 'MethodSelectionDialog',
  components: { DialogOrBottomSheet },
  mixins: [ResponsiveScreenHelperMixin, DatatransLightboxMixin],
  props: {
    returnToBooking: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      return pipe(pick(['1', '2', '3']), toPairs)(paymentMethods)
    },
    ...mapGetters(['isNativePlatform']),
  },
  methods: {
    ...mapActions('student_wallet', [
      'getMethods',
      'addMethodPlaceholder',
      'removeMethod',
    ]),
    onMethodSelect(method) {
      this.initMethodCreation(externalPaymentMethods[method])
      this.$emit('newMethodSelected')
    },
    initMethodCreation(payment_method) {
      this.addMethodPlaceholder({
        data: { payment_method },
        return_to_booking: this.returnToBooking,
      }).then((data) => {
        if (!this.isNativePlatform) {
          this.openLightbox({
            transactionId: data.transactionId,
            onClose: () => {
              this.$emit('close')
            },
          })
        } else {
          const a = document.createElement('a')
          a.href = `${this.$config.datatransBaseURL}/v1/start/${data.transactionId}`
          a.click()
        }
      })
    },
  },
}
