
import { mapActions, mapGetters, mapState } from 'vuex'
import { PushNotifications } from '@capacitor/push-notifications'
import Snackbar from '../components/shared/Snackbar'
import ProcessDelayedBookingMixin from '../mixins/ProcessDelayedBookingMixin'
import ResponsiveScreenHelperMixin from '../mixins/ResponsiveScreenHelperMixin'
import NativeCapabilitiesControllerMixin from '../mixins/NativeCapabilitiesControllerMixin'
import EditStudentDetails from '../components/student/student_profile/EditStudentDetails'
import BookCoachLessonsDialog from '../components/student/student_explore/booking/BookCoachLessonsDialog'
import StudentHasNoPaymentOptionsDialog from '../components/student/student_explore/booking/StudentHasNoPaymentOptionsDialog'
import MethodSelectionDialog from '../components/shared/student_wallet/MethodSelectionDialog'
import CourseDetailsDialog from '../components/student/student_drives/course_details_dialog/CourseDetailsDialog'
import LessonDetailsDialog from '../components/student/student_drives/lesson_details_dialog/LessonDetailsDialog'
import { studentProfileComplete } from '../utils/commonutils'
import FailableImage from '~/components/shared/FailableImage.vue'

export default {
  components: {
    CourseDetailsDialog,
    MethodSelectionDialog,
    StudentHasNoPaymentOptionsDialog,
    BookCoachLessonsDialog,
    EditStudentDetails,
    Snackbar,
    LessonDetailsDialog,
    FailableImage,
  },
  mixins: [
    NativeCapabilitiesControllerMixin,
    ProcessDelayedBookingMixin,
    ResponsiveScreenHelperMixin,
  ],
  data() {
    return {
      finishSignup: false,
      delayedBookingData: {
        referenceSlot: {},
        formState: {},
        relatedFilter: {},
        chosenPaymentMethod: '',
        selectedPackage: {},
      },
      showBookingDetails: false,
      courseDetailsData: null,
      showCourseDetails: false,
      showNoMethodsDialog: false,
      addMethodDialog: false,
      lessonDetails: null,
      showLessonDetails: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('profile', ['profile_avatar', 'userId']),
    ...mapGetters('student_drives', [
      'hasDriveNotifications',
      'hasCourseNotifications',
    ]),
    isProfileComplete() {
      return studentProfileComplete(this.user)
    },
  },
  created() {
    this.$nuxt.$on('signup-finished', ($event) => {
      this.finishSignup = !$event
    })
  },
  async mounted() {
    this.unsubscribe = this.$store.subscribeAction((action) => {
      if (action.type === 'student_wallet/notifyReadyToFinishTheBooking') {
        this.finishBooking()
      }
    })
    const refno = JSON.parse(localStorage.getItem('refno'))
    if (refno) {
      await this.cancelPrebooking(refno)
      localStorage.removeItem('refno')
    }
    const storedData = sessionStorage.getItem('storedLessonDetails')
    if (storedData) {
      this.lessonDetails = JSON.parse(storedData).lessonDetails
      sessionStorage.removeItem('storedLessonDetails')
      this.showLessonDetails = true
    }
    if (!this.isNativePlatform) {
      return
    }

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()
      }
    })
    PushNotifications.addListener('registration', (token) => {
      this.sendPushNotificationsToken(token.value)
    })

    PushNotifications.addListener('registrationError', (error) => {
      console.log('Error on registration: ' + JSON.stringify(error))
    })

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        if (this.isAndroidCurrentPlatform) {
          alert(notification.body)
        }
      }
    )
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        const data = notification.notification.data
        if (data.event) {
          data.event === 'course_cancel'
            ? this.$router.push({
                name: 'studentDrives',
              })
            : this.$router.push({
                name: 'studentDrives',
                query:
                  data.event === 'lesson'
                    ? { drive: data.slot_id }
                    : { course: data.course_booking_id },
              })
        }
      }
    )
  },
  beforeDestroy() {
    this.unsubscribe()
    if (this.isNativePlatform) {
      PushNotifications.removeAllListeners()
    }
  },
  methods: {
    ...mapActions('push-notifications', ['sendPushNotificationsToken']),
    ...mapActions('student_wallet', ['cancelPrebooking']),
    toExplore() {
      this.$router.push({ name: 'studentExplore' })
    },
    toDrives() {
      this.$router.push({ name: 'studentDrives' })
    },
    toProfile() {
      this.$router.push({ name: 'studentProfile' })
    },
    toProgress() {
      this.$router.push({ name: 'studentProgress' })
    },
    closeCourseDetails() {
      this.showCourseDetails = false
      this.courseDetailsData = null
    },
  },
}
