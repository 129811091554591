/* global google */
/* eslint no-undef: "error" */
import { Geolocation } from '@capacitor/geolocation'
import { trim, where, pipe, pathOr, find, includes, flatten, map } from 'ramda'

export default {
  mounted() {
    this.componentMounted = true
  },
  data() {
    return {
      componentMounted: false,
    }
  },
  watch: {
    mapsScriptLoaded: {
      handler() {
        this.checkConditionsAndInit()
      },
      immediate: true,
    },
    componentMounted: {
      handler() {
        this.checkConditionsAndInit()
      },
      immediate: true,
    },
  },
  methods: {
    checkConditionsAndInit() {
      if (this.mapsScriptLoaded && this.componentMounted) {
        this.initPlacesSearch(this.$refs.attribution)
      }
    },
    initPlacesSearch(attributionDiv) {
      this.placesService = new google.maps.places.PlacesService(attributionDiv)
      this.geoCoderService = new google.maps.Geocoder()
      this.autocompleteService = new google.maps.places.AutocompleteService()
    },
    suggestPlaces(text) {
      const query = trim(text || '')
      if (!query) {
        return Promise.resolve([])
      }
      return new Promise((resolve) => {
        const request = {
          // TODO locationBias
          // https://developers.google.com/maps/documentation/javascript/places#place_searches
          query,
          types: ['postal_code'],
          region: 'CH',
        }
        this.placesService.textSearch(request, function (results, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(results)
          } else {
            resolve([])
          }
        })
      })
    },
    suggestPlaces2(address) {
      return new Promise((resolve) => {
        this.geoCoderService.geocode(
          {
            address,
            region: 'CH',
            bounds: new google.maps.LatLngBounds(
              new google.maps.LatLng(45.824942, 5.984234),
              new google.maps.LatLng(47.794678, 10.518841)
              // added bounds of Switzerland to prioritize swiss addresses, https://m-p.atlassian.net/browse/CAR-2095
            ),
          },
          (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(results)
            } else {
              resolve([])
            }
          }
        )
      })
    },
    suggestPlaces3(input, locationLatLng) {
      // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service?hl=en#QueryAutocompletionRequest
      const options = {
        input,
      }
      if (locationLatLng) {
        options.location = locationLatLng
        options.radius = 10000
      }
      return new Promise((resolve) => {
        this.autocompleteService.getQueryPredictions(
          options,
          (results, status) => {
            if (status === 'OK') {
              resolve(
                results.map((r) => {
                  r.formatted_address = r.description
                  return r
                })
              )
            } else {
              resolve([])
            }
          }
        )
      })
    },
    async getPostalCodeFromPlace(place) {
      const [attempt1, attempt2, attempt3] = await Promise.all([
        this.getPostalCodeFromCoords(place.geometry.location).catch(() => ''),
        this.getPostalCodeFromPlaceId(place.place_id).catch(() => ''),
        this.getLocationPostalCode(place.place_id).catch(() => ''),
      ])
      return attempt1 || attempt2 || attempt3
    },
    getLocationPostalCode(placeId) {
      return new Promise((resolve, reject) => {
        this.placesService.getDetails({ placeId }, function (place, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const postalCode = pipe(
              pathOr([], ['address_components']),
              find(
                where({
                  types: includes('postal_code'),
                })
              ),
              pathOr('', ['short_name'])
            )(place)
            resolve(postalCode)
          } else {
            reject(new Error(status))
          }
        })
      })
    },
    getPostalCodeFromCoords(latLng) {
      return new Promise((resolve, reject) => {
        this.geoCoderService.geocode(
          { location: latLng },
          (results, status) => {
            if (status === 'OK') {
              const postalCode = pipe(
                map(pathOr([], ['address_components'])),
                flatten,
                find(
                  where({
                    types: includes('postal_code'),
                  })
                ),
                pathOr('', ['short_name'])
              )(results)
              resolve(postalCode)
            } else {
              reject(new Error(status))
            }
          }
        )
      })
    },
    getPostalCodeFromPlaceId(placeId) {
      return new Promise((resolve, reject) => {
        this.geoCoderService.geocode({ placeId }, (results, status) => {
          if (status === 'OK') {
            const postalCode = pipe(
              map(pathOr([], ['address_components'])),
              flatten,
              find(
                where({
                  types: includes('postal_code'),
                })
              ),
              pathOr('', ['short_name'])
            )(results)
            resolve(postalCode)
          } else {
            reject(new Error(status))
          }
        })
      })
    },
    async suggestNearbyPlaces() {
      const request = {
        location: { lat: 0, lng: 0 },
        type: 'post_office',
        rankBy: google.maps.places.RankBy.DISTANCE,
      }

      if (this.isNativePlatform) {
        try {
          const { coords } = await Geolocation.getCurrentPosition()
          request.location.lat = coords.latitude
          request.location.lng = coords.longitude
        } catch (e) {
          return Promise.reject(
            new Error(this.$t('search.userLocationNotAvailable'))
          )
        }
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          request.location.lat = position.coords.latitude
          request.location.lng = position.coords.longitude
        })
        // ToDo: probably we have to add errorCallback handler here ?
      } else {
        return Promise.reject(
          new Error(this.$t('search.userLocationNotAvailable'))
        )
      }
      return new Promise((resolve, reject) => {
        this.placesService.nearbySearch(request, function (results, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(results)
          } else {
            reject(new Error(status))
          }
        })
      })
    },
    getGPlaceDetails(placeId) {
      return new Promise((resolve, reject) => {
        this.placesService.getDetails({ placeId }, function (place, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(place)
          } else {
            reject(new Error(status))
          }
        })
      })
    },
    requestCurrentLocation() {
      return new Promise(function (resolve, reject) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            ({ coords }) => {
              resolve(new google.maps.LatLng(coords.latitude, coords.longitude))
            },
            () => {
              reject(new Error('REQUEST_REJECTED'))
            }
          )
        } else {
          reject(new Error('NO_GEOLOCATION'))
        }
      })
    },
  },
}
