import { mapActions, mapGetters, mapState } from 'vuex'
import { pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { studentProfileComplete } from '../utils/commonutils'
import {
  ELECTRONIC_PAYMENT_METHODS,
  PAYMENT_METHOD_CASH,
} from '../utils/constants'
export default {
  computed: {
    ...mapGetters('student_explore', ['isReadyToProcessGuestBooking']),
    ...mapState('auth', {
      currentUser: 'user',
    }),
    ...mapState('profile', ['profile']),
    ...mapState('student_wallet', {
      studentPaymentOptions: 'methods',
    }),
  },
  watch: {
    isReadyToProcessGuestBooking: {
      handler(value) {
        if (value) {
          this.finishSignupAndProceedWithBooking()
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('student_explore', [
      'clearUnfinishedBookingData',
      'getUnfinishedBookingData',
      'processDelayedBookingData',
      'setupBookingOnLogin',
    ]),
    ...mapActions('app_snackbars', ['showErrorNotification']),
    ...mapActions('student_wallet', ['getMethods']),
    finishSignupAndProceedWithBooking() {
      if (!studentProfileComplete(this.profile)) {
        this.finishSignup = true
      } else {
        this.finishBooking()
      }
    },
    onSignupFinished() {
      this.finishBooking()
    },
    onSignupCanceled() {
      this.onBookingCanceled()
    },
    async finishBooking() {
      if (!this.studentPaymentOptions.length) {
        await this.getMethods()
      }
      this.processDelayedBookingData()
        .then((bookingData) => {
          if (bookingData.type === 'course') {
            if (!this.studentPaymentOptions.length) {
              this.showNoMethodsDialog = true
              this.setupBookingOnLogin(bookingData)
            } else {
              this.courseDetailsData = {
                course: bookingData.courseDetails,
                ...bookingData.courseDetails,
              }
              this.showCourseDetails = true
              this.finishSignup = !studentProfileComplete(this.profile)
              this.clearUnfinishedBookingData()
            }
          } else {
            const {
              referenceSlot,
              formData,
              payment_method,
              relatedFilter,
              selectedPackage,
            } = bookingData

            // if coach supports any electronic methods
            // and if student wallet is empty
            // TODO and if student does not have apple/google available
            // show payment method creation early
            const coachMethods = pathOr(
              [],
              ['coach', 'payment_methods'],
              referenceSlot
            )
            const coachAllowsCash = coachMethods.includes(PAYMENT_METHOD_CASH)
            const coachAllowsElectronicMethods = coachMethods.some((method) =>
              ELECTRONIC_PAYMENT_METHODS.includes(method)
            )

            if (
              !coachAllowsCash &&
              coachAllowsElectronicMethods &&
              !this.studentPaymentOptions.length
            ) {
              this.showNoMethodsDialog = true
            } else {
              this.delayedBookingData.referenceSlot = referenceSlot
              this.delayedBookingData.formState = formData
              this.delayedBookingData.chosenPaymentMethod = payment_method
              this.delayedBookingData.relatedFilter = relatedFilter
              this.delayedBookingData.selectedPackage = selectedPackage
              this.showBookingDetails = true
              this.finishSignup = !studentProfileComplete(this.profile)
              this.clearUnfinishedBookingData()
            }
          }
        })
        .catch(() => {
          this.finishSignup = !studentProfileComplete(this.profile)
        })
    },
    onMethodCreateStart() {
      this.addMethodDialog = true
    },
    async onBookingCanceled() {
      this.addMethodDialog = false
      const bookingInfo = await this.getUnfinishedBookingData()
      if (
        isNotNilOrEmpty(bookingInfo) ||
        isNotNilOrEmpty(this.delayedBookingData.referenceSlot)
      ) {
        this.showErrorNotification(
          'studentLessonBooking.bookingFlowInterrupted'
        )
        this.clearUnfinishedBookingData()
      }
    },
    onBookingSuccess() {
      // stub
    },
    onBookingClosed() {
      this.showBookingDetails = false
    },
  },
}
