export default {
  theme: {
    name: 'DT2015', // Leave this as a default
    configuration: {
      brandColor: '#000000', // Base color
      logoBorderColor: '#A1A1A1', // Border color around your logo
      brandButton: '#A1A1A1', // Background color for the pay button
      payButtonTextColor: 'white', // Color for the text on pay button
      // The correct value for the parameter logoSrc is the name you specified
      // for your uploaded svg file in our merchant administration portal.
      logoSrc: 'carzi-logo.svg',
      // logoType: 'circle', // Format of your logo on payment pages
      // initialView: 'list', // Listing method of payment methods

      // Only required if no logo is used:
      // brandTitle: 'false', // Hides the title from header
      textColor: 'black', // Color for the title [white|black]
    },
  },
}
