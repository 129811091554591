
import DialogWrapper from '../../../../shared/DialogWrapper'

export default {
  name: 'CashConfirmationDialog',
  components: { DialogWrapper },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    lessonPrice: {
      type: String,
      default: '',
    },
    isBulkBooking: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    confirmationTitle() {
      if (this.isBulkBooking) {
        return 'studentLessonBooking.confirmationCashBulkLessonsTitle'
      }
      return 'studentLessonBooking.confirmationCashOneLessonTitle'
    },
    cashAmount() {
      return `CHF ${+this.lessonPrice}`
    },
  },
  methods: {
    onChange() {
      this.$emit('change')
    },
    onContinue() {
      this.$emit('continue')
    },
  },
}
