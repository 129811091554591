
import { STUDENT_COURSE_STATUS_CONFIRMED } from '../../../../utils/constants'

export default {
  name: 'StudentCourseAttendanceStatusWidget',
  props: {
    status: {
      type: Number,
      default: 0,
    },
    attended: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isConfirmedBooking() {
      return this.status === STUDENT_COURSE_STATUS_CONFIRMED
    },
  },
}
