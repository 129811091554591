
import { any, whereEq } from 'ramda'
import SectionTitle from '../../../shared/SectionTitle'
import AddPlace from '../../../shared/places/AddPlace'
import PlaceSingleSelect from './PlaceSingleSelect'
export default {
  name: 'LessonPlaceSelection',
  components: { AddPlace, SectionTitle, PlaceSingleSelect },
  props: {
    selectedPlace: {
      type: [Number, String],
      default: '',
    },
    places: {
      type: Array,
      default() {
        return []
      },
    },
    studentPlaces: {
      type: Array,
      default() {
        return []
      },
    },
    allowedCustomPlaces: {
      type: Boolean,
      default: false,
    },
    customPlacesOnly: {
      type: Boolean,
      default: false,
    },
    areasOfOperation: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      createDialog: false,
      showCustomPlaces: false,
    }
  },
  computed: {
    placesList() {
      return this.showCustomPlaces || this.customPlacesOnly
        ? this.studentPlaces
        : this.places
    },
  },
  watch: {
    selectedPlace: {
      handler(placeId) {
        if (any(whereEq({ id: placeId }), this.studentPlaces)) {
          this.switchToCustomPlaces()
        } else {
          this.switchToCoachPlaces()
        }
      },
      immediate: true,
    },
  },
  methods: {
    switchToCustomPlaces() {
      this.showCustomPlaces = true
    },
    switchToCoachPlaces() {
      this.showCustomPlaces = false
    },
    onPlaceChange(e) {
      this.$emit('update:selectedPlace', e)
    },
    onCustomPlaceCreated(place) {
      this.onPlaceChange(place.id)
    },
    onOutOfAreaSelected(place) {
      this.$emit('out-of-area', place)
    },
  },
}
