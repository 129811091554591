
import { hasPath, pathOr } from 'ramda'
import { gmapsLocationURL } from '../../../utils/commonutils'

export default {
  name: 'LocationLink',
  props: {
    meta: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    formatted_address() {
      return pathOr('', ['formatted_address'], this.meta)
    },
    selectedPlaceGMapsLink() {
      if (hasPath(['geometry', 'location', 'lat'])(this.meta)) {
        return gmapsLocationURL(this.meta)
      }
      return ''
    },
  },
}
