import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
import { path, pathOr, pipe, whereEq, filter, tap, clone } from 'ramda'
import {
  Smartlook,
  SmartlookUserIdentifier,
} from '@awesome-cordova-plugins/smartlook'
import {
  COACH_GENDER_MALE,
  COACH_ROLE,
  DEFAULT_LANGUAGE,
  STUDENT_ROLE,
} from '~/utils/constants'
import {
  getSmallestImageURL,
  openFileOnNativePlatform,
} from '~/utils/commonutils'
import {
  UserCoachDetail,
  UserStudentDetail,
} from '~/types/__generated__/data-contracts'
import { Users } from '~/types/__generated__/UsersRoute'
import { Cars } from '~/types/__generated__/CarsRoute'
import { Locations } from '~/types/__generated__/LocationsRoute'
import SearchCoach = Users.UsersCoachesList
import GetCarDetails = Cars.CarsRead
import GetPlaceDetails = Locations.LocationsPickupPlacesRead
import GetPostalCodes = Locations.LocationsPostalCodesList
import GetCarBrands = Cars.CarsBrandsList
import GetCarTags = Cars.CarsColorTagsList
import GetAvailableCarTags = Cars.CarsAvailableColorTagsList
import GetBrandModels = Cars.CarsBrandsModelsList
import GetCountries = Locations.LocationsCountriesList

export const defaultProfile = {
  id: '',
  role: 0,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  language: '',
  registration_completed: false,
  ics_url: '',
  coach: {
    alias: '',
    avatar: '',
    pickup_places: [],
    cars: [],
    areas_of_operation: [],
    available_languages: [],
    price_per_lesson: 0,
    gender: COACH_GENDER_MALE,
    school: { name: '' },
    allow_custom_pickup_places: false,
    payment_methods: [],
    disabled_payment_methods: [],
    lessons_duration: '',
    review_count: null,
    average_rating: 0,
    admin_fee: 0,
    about: '',
    reminder_offset: 0,
    buffer_time: 0,
  },
  student: {
    avatar: '',
    pickup_places: [],
    zip_code: '',
    city: '',
    street: '',
    birthday: '',
    payment_methods: [],
    preferred_transmission: '',
    preferred_language: '',
  },
}

export const state = () => ({
  profile: clone(defaultProfile),
})
export const getters = getterTree(state, {
  icsToken({ profile }) {
    return profile?.ics_url?.split('=')[1]
  },
  review_count({ profile }) {
    return profile?.coach?.review_count
  },
  average_rating({ profile }) {
    return profile?.coach?.average_rating
  },
  alias({ profile }) {
    return profile?.coach?.alias
  },
  userId(_state, _getters, rootState) {
    return path(['auth', 'user', 'id'])(rootState)
  },
  userRole(_state, _getters, rootState) {
    return path(['auth', 'user', 'role'])(rootState)
  },
  profileLoaded(_state) {
    return !!_state.profile.id
  },
  pickup_places({ profile }) {
    return pathOr([], ['coach', 'pickup_places'])(profile)
  },
  cars({ profile }) {
    return pathOr([], ['coach', 'cars'])(profile)
  },
  active_cars(_state, getters) {
    return filter(whereEq({ is_active: true }))(getters.cars)
  },
  areas_of_operation({ profile }) {
    return pathOr([], ['coach', 'areas_of_operation'])(profile)
  },
  available_languages({ profile }) {
    return pathOr([], ['coach', 'available_languages'])(profile)
  },
  avatar({ profile }) {
    return pipe(path(['coach', 'avatar']), getSmallestImageURL)(profile)
  },
  coach_avatar_media({ profile }) {
    return path(['coach', 'avatar'], profile)
  },
  video({ profile }) {
    return path(['coach', 'video'])(profile)
  },
  additional_pictures({ profile }) {
    return path(['coach', 'additional_pictures'])(profile)
  },
  school_name({ profile }) {
    return path(['coach', 'school', 'name'])(profile)
  },
  allow_custom_pickup_places({ profile }) {
    return path(['coach', 'allow_custom_pickup_places'])(profile)
  },
  lessons_duration({ profile }) {
    return path(['coach', 'lessons_duration'])(profile)
  },
  payment_methods({ profile }) {
    return pathOr([], ['coach', 'payment_methods'])(profile)
  },
  disabled_payment_methods({ profile }) {
    return pathOr([], ['coach', 'disabled_payment_methods'])(profile)
  },
  price_per_lesson({ profile }) {
    return path(['coach', 'price_per_lesson'])(profile)
  },
  admin_fee({ profile }) {
    return path(['coach', 'admin_fee'])(profile)
  },
  coach_country({ profile }) {
    return path(['coach', 'country'])(profile)
  },
  student_avatar({ profile }) {
    return pipe(path(['student', 'avatar']), getSmallestImageURL)(profile)
  },
  student_language({ profile }) {
    return pathOr(DEFAULT_LANGUAGE, ['language'])(profile)
  },
  student_preferred_language({ profile }) {
    return path(['student', 'preferred_language'])(profile)
  },
  student_payment_methods({ profile }) {
    return pathOr([], ['student', 'payment_methods'])(profile)
  },
  student_zip_code({ profile }) {
    return path(['student', 'zip_code'])(profile)
  },
  student_city({ profile }) {
    return pathOr('', ['student', 'city'])(profile)
  },
  student_street({ profile }) {
    return pathOr('', ['student', 'street'])(profile)
  },
  student_birthday({ profile }) {
    return profile?.student?.birthday?.split('-').reverse().join('.')
  },
  student_preferred_gearbox({ profile }) {
    return pathOr('', ['student', 'preferred_transmission'])(profile)
  },
  student_places({ profile }) {
    return pathOr([], ['student', 'pickup_places'])(profile)
  },
  student_learner_permit({ profile }) {
    return path(['student', 'learners_permit'])(profile)
  },
  profile_places({ profile }, getters) {
    if (profile.role === STUDENT_ROLE) {
      return getters.student_places
    } else {
      return getters.pickup_places
    }
  },
  profile_active_places(_, getters) {
    return filter(whereEq({ is_active: true }))(getters.profile_places)
  },
  profile_avatar({ profile }, getters) {
    if (profile.role === STUDENT_ROLE) {
      return getters.student_avatar
    } else {
      return getters.avatar
    }
  },
  isCoach({ profile }) {
    return profile.role === COACH_ROLE
  },
  application_language({ profile }) {
    return profile.language || 'en'
  },
  coach_gender({ profile }) {
    return path(['coach', 'gender'], profile)
  },
  about({ profile }) {
    return profile?.coach?.about
  },
})
export const mutations = mutationTree(state, {
  setProfile(_state, data) {
    _state.profile = data
  },
  setProfileLanguage(_state, language) {
    _state.profile.language = language
  },
})
export const actions = actionTree(
  { state, getters, mutations },
  {
    getPaymentReport({ getters, rootGetters }, [start__gte, start__lte]) {
      return this.$axios({
        url: `/api/reports/payments/coaches/${getters.userId}/`,
        method: 'GET',
        responseType: 'blob',
        params: { start__gte, start__lte },
      }).then((response) => {
        const filename = response.headers['content-disposition'].split('=')[1]
        const type = response.headers['content-type']
        const data = new Blob([response.data], { type })

        if (rootGetters.isNativePlatform) {
          return openFileOnNativePlatform(data, filename, type)
        }
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(data)
        a.download = filename
        a.click()
      })
    },
    saveCoachLink({ dispatch }, alias) {
      dispatch('updateProfile', { coach: { alias } })
    },
    getCoachByAlias(_, alias): Promise<SearchCoach.ResponseBody['results']> {
      return this.$axios
        .get('/api/users/coaches/', { params: { alias } })
        .then(pathOr([], ['data', 'results']))
    },
    updateAppLanguage({ dispatch, getters }) {
      dispatch('applyAppLanguage', getters.application_language, { root: true })
    },
    getProfile({
      commit,
      dispatch,
      getters,
      state,
    }): Promise<UserCoachDetail | UserStudentDetail | undefined | void> {
      return this.$axios
        .get(`/api/users/me/`)
        .then(path(['data']))
        .then(
          tap(
            (
              profile: UserCoachDetail | UserStudentDetail | undefined | any
            ) => {
              if (profile?.id !== +state.profile.id) {
                this.$fa.setUserId({ userId: String(getters.userId) })
                this.$fa.logEvent({
                  name: 'user_id',
                  params: { uid: String(getters.userId) },
                })
                Smartlook.setUserIdentifier(
                  new SmartlookUserIdentifier(String(getters.userId))
                )
              }
              commit('setProfile', profile)
              dispatch('updateAppLanguage')
            }
          )
        )
    },
    updateProfile({ commit }, data) {
      return this.$axios
        .patch(`/api/users/me/`, { ...data, registration_completed: true })
        .then(path(['data']))
        .then(
          (
            profile: (UserCoachDetail & UserStudentDetail) | undefined | any
          ) => {
            if (!profile?.student?.registration_completed) {
              this.$fa.logEvent({
                name: 'registration_completed',
                params: {},
              })
            }
            commit('setProfile', profile)
          }
        )
    },

    addCar({ dispatch, getters }, data) {
      const { userId } = getters
      return this.$axios
        .post(`/api/cars/`, { ...data, coach: userId })
        .then(() => dispatch('getProfile'))
    },
    getCarDetails(_, data): Promise<GetCarDetails.RequestBody | undefined> {
      return this.$axios.get(`/api/cars/${data.id}/`).then(path(['data']))
    },
    updateCar({ dispatch }, data) {
      return this.$axios
        .patch(`/api/cars/${data.id}/`, data)
        .then(() => dispatch('getProfile'))
    },
    deleteCar({ dispatch }, id) {
      return this.$axios
        .delete(`/api/cars/${id}`)
        .then(() => dispatch('getProfile'))
    },
    addPlace({ dispatch, getters }, data) {
      const user = Object.hasOwn(data, 'user') ? data.user : getters.userId
      return this.$axios
        .post(`/api/locations/pickup-places/`, { ...data, user })
        .then(tap(() => dispatch('getProfile')))
        .then(path(['data']))
    },
    getPlacesInArea(
      _state,
      { polygon, params }: { polygon: google.maps.LatLng[]; params: any }
    ): Promise<GetPlaceDetails.ResponseBody[]> {
      const url = polygon.reduce(
        (res, { lng, lat }) => res + `polygon=${lat()},${lng()}&`,
        '/api/locations/pickup-places-in-area/?'
      )
      return this.$axios.get(url, { params }).then(pathOr([], ['data']))
    },
    getPlaceDetails(
      _,
      data
    ): Promise<GetPlaceDetails.ResponseBody | undefined> {
      return this.$axios
        .get(`/api/locations/pickup-places/${data.id}/`)
        .then(path(['data']))
    },
    updatePlace({ dispatch }, data) {
      return this.$axios
        .patch(`/api/locations/pickup-places/${data.id}/`, { ...data })
        .then(() => dispatch('getProfile'))
    },
    deletePlace({ dispatch }, id) {
      return this.$axios
        .delete(`/api/locations/pickup-places/${id}`)
        .then(() => dispatch('getProfile'))
    },
    savePassword({ dispatch }, { password }) {
      return this.$axios
        .patch(`/api/users/me/`, { password })
        .then(() => dispatch('getProfile'))
    },
    changeApplicationLanguage({ dispatch }, language) {
      const payload = { language }
      return this.$axios
        .patch(`/api/users/me/`, { ...payload })
        .then(() => dispatch('getProfile'))
    },
    addApplicationFeedback(_, text) {
      return this.$axios.post(`/api/feedbacks/`, { text })
    },
    getAllPostalCodes(): Promise<GetPostalCodes.ResponseBody> {
      return this.$axios
        .get('/api/locations/postal-codes/')
        .then(pathOr([], ['data']))
    },
    getAllCarsBrands(_, search): Promise<GetCarBrands.ResponseBody> {
      return this.$axios
        .get('/api/cars/brands/', { params: { search } })
        .then(pathOr([], ['data']))
    },
    getAllCarsColorTags(): Promise<GetCarTags.ResponseBody> {
      return this.$axios.get('/api/cars/color-tags/').then(pathOr([], ['data']))
    },
    getAvailableCarsColorTags(): Promise<GetAvailableCarTags.ResponseBody> {
      return this.$axios
        .get('/api/cars/available-color-tags/')
        .then(pathOr([], ['data']))
    },
    getAllCarModels(_, brand): Promise<GetBrandModels.ResponseBody> {
      return this.$axios
        .get(`/api/cars/brands/${brand}/models/`)
        .then(pathOr([], ['data']))
    },
    getAllCountries(): Promise<GetCountries.ResponseBody> {
      return this.$axios
        .get('/api/locations/countries/')
        .then(pathOr([], ['data']))
    },
    deactivateAccount() {
      return this.$axios.post(`/api/users/deactivate-account/`)
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
