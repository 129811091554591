
import MenuOrBottomSheet from '../MenuOrBottomSheet'
import ResponsiveScreenHelperMixin from '../../../mixins/ResponsiveScreenHelperMixin'
import CardWrapper from '../CardWrapper'
export default {
  name: 'PriceDetailsInfoMenu',
  components: { MenuOrBottomSheet, CardWrapper },
  mixins: [ResponsiveScreenHelperMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    policy: {
      type: Object,
      default() {
        return {
          fee: 0,
          time: 0,
        }
      },
    },
    adminFee: {
      type: String,
      default: '',
    },
  },
}
