// TODO rename without hyphens
import {
  getterTree,
  actionTree,
  mutationTree,
  getAccessorType,
} from 'typed-vuex'
export const state = () => ({
  push_notifications_token: '',
})
export const getters = getterTree(state, {})
export const mutations = mutationTree(state, {
  setPushNotificationsToken(_state, token) {
    _state.push_notifications_token = token
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    sendPushNotificationsToken({ commit }, token) {
      commit('setPushNotificationsToken', token)
      return this.$axios.post(`/api/notifications/fcmtoken/`, { token })
    },
    deletePushNotificationsToken({ commit, state }) {
      return this.$axios
        .delete('/api/notifications/fcmtoken/', {
          data: {
            token: state.push_notifications_token,
          },
        })
        .then(() => commit('setPushNotificationsToken', ''))
    },
  }
)
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
