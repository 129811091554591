import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { Capacitor } from '@capacitor/core'

export default function (_, inject) {
  inject('fa', FirebaseAnalytics)
  if (Capacitor.isNativePlatform()) {
    return
  }
  FirebaseAnalytics.initializeFirebase({
    apiKey: process.env.GA_API_KEY,
    authDomain: process.env.GA_AUTH_DOMAIN,
    projectId: process.env.GA_PROJECT_ID,
    storageBucket: process.env.GA_STORAGE_BUCKET,
    messagingSenderId: process.env.GA_MESSAGING_SENDER_ID,
    appId: process.env.GA_APP_ID,
    measurementId: process.env.GA_MEASUREMENT_ID,
  }).then(() => console.log('analytics enabled'))
}
