
import { path, pathOr, pipe, pathEq } from 'ramda'
import { format, isPast, parseISO } from 'date-fns'
import HeaderItem from '../../../shared/HeaderItem'
import SectionTitle from '../../../shared/SectionTitle'
import PlaceSingleSelect from '../../student_explore/booking/PlaceSingleSelect'
import {
  formatFullName,
  getSmallestImageURL,
} from '../../../../utils/commonutils'
import i18nHelperMixin from '../../../../mixins/i18nHelperMixin'
import PriceDetailsInfoMenu from '../../../shared/price_details/PriceDetailsInfoMenu'
import PolicyInfoMenuMixin from '../../../../mixins/PolicyInfoMenuMixin'
import {
  STUDENT_COURSE_STATUS_CANCELED,
  STUDENT_COURSE_STATUS_INVITED,
  COACH_COURSE_STATUS_COMPLETED,
  COACH_GENDER_MALE,
  GENDER_LABELS,
} from '../../../../utils/constants'
import StudentCourseStatusWidget from './StudentCourseStatusWidget'
import StudentCourseAttendanceStatusWidget from './StudentCourseAttendanceStatusWidget'
import FailableImage from '~/components/shared/FailableImage.vue'
export default {
  name: 'CourseDetailsHeader',
  components: {
    StudentCourseAttendanceStatusWidget,
    PriceDetailsInfoMenu,
    StudentCourseStatusWidget,
    PlaceSingleSelect,
    SectionTitle,
    HeaderItem,
    FailableImage,
  },
  mixins: [i18nHelperMixin, PolicyInfoMenuMixin],
  props: {
    courseDetails: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    avatar() {
      return pipe(
        pathOr('', ['coach', 'avatar']),
        getSmallestImageURL
      )(this.courseDetails)
    },
    schoolName() {
      return pathOr('', ['coach', 'school', 'name'], this.courseDetails)
    },
    courseTitle() {
      return pathOr('', ['title'], this.courseDetails)
    },
    coursePrice() {
      return pathOr('', ['price'], this.courseDetails)
    },
    courseCapacity() {
      return `${pathOr('', ['students'], this.courseDetails)}/${pathOr(
        '',
        ['capacity'],
        this.courseDetails
      )}`
    },
    coachName() {
      return pipe(pathOr({}, ['coach']), formatFullName)(this.courseDetails)
    },
    coachGender() {
      return pipe(pathOr(COACH_GENDER_MALE, ['coach', 'gender']), (gender) =>
        this.$t(GENDER_LABELS[gender])
      )(this.courseDetails)
    },
    courseLocation() {
      return pathOr(
        '',
        ['place', 'meta', 'formatted_address'],
        this.courseDetails
      )
    },
    placeComment() {
      return pathOr('', ['place_comment'], this.courseDetails)
    },
    courseLocationText() {
      return `${this.courseLocation}
      ${this.placeComment}`
    },
    courseLanguage() {
      return pathOr('', ['language'])(this.courseDetails)
    },
    status() {
      return path(['status'], this.courseDetails)
    },
    attended() {
      return path(['is_attended'], this.courseDetails)
    },
    date() {
      return format(parseISO(this.courseDetails.start), 'EEEE, MMM d', {
        locale: this.dateFNSLocale,
      })
    },
    time() {
      return `${format(parseISO(this.courseDetails.start), 'HH:mm')} - ${format(
        parseISO(this.courseDetails.end),
        'HH:mm'
      )}`
    },
    courseStarted() {
      return isPast(parseISO(this.courseDetails.start))
    },
    showAttendance() {
      return pathEq(
        ['course_status'],
        COACH_COURSE_STATUS_COMPLETED,
        this.courseDetails
      )
    },
    cancelReason() {
      return path(['cancellation_reason'], this.courseDetails)
    },
    showCancelReason() {
      return (
        this.status === STUDENT_COURSE_STATUS_CANCELED && !!this.cancelReason
      )
    },
    cancellationPolicyPath() {
      if (!this.status || this.status === STUDENT_COURSE_STATUS_INVITED) {
        // no policy in booking details at the moment
        return ['coach', 'cancellation_policy']
      }
      return ['cancellation_policy']
    },
  },
}
