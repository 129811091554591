
export default {
  name: 'MenuRowItem',
  props: {
    dialogData: {
      type: Object,
      default: () => ({}),
    },
  },
}
