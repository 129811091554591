export default {
  methods: {
    goNextFieldOnEnter() {
      const keyboardfocusableElements = [
        ...document.querySelectorAll(
          'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
        ),
      ]
      const activeEl = document.activeElement
      const activeElIndex = keyboardfocusableElements.indexOf(activeEl)
      keyboardfocusableElements[
        activeElIndex < keyboardfocusableElements.length - 1
          ? activeElIndex + 1
          : activeElIndex
      ].focus()
    },
  },
}
